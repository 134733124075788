<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="893.45772"
    height="516.37247"
    viewBox="0 0 893.45772 516.37247"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M902.803,246.36949c5.76,40.74321-21.77963,33.06849-41.86533,29.91828-4.78636-.75217-13.55574,5.68322-16.581,7.40188-1.69754-6.03426-1.79809-4.52086-6.2765-9.56471,2.41539,6.63758-5.01359,9.59476-2.30807,13.4754-6.72314-.47824-9.69728-14.79193-3.66419-46.00394,2.65473-13.73412,9.57717-34.7831,37.91783-30.13195C900.731,216.506,900.99939,233.61151,902.803,246.36949Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#2f2e41"
    />
    <circle cx="709.23205" cy="59.37473" r="25.40083" fill="#a0616a" />
    <path
      d="M827.973,241.867v0l12.1674,1.85945c5.36613-3.361,7.40975-8.34778,7.6648-14.20992l-1.22359,15.19426,5.8155.88874c3.79573-2.53245,5.4919-5.27489,4.47122-8.28911l-.71363,8.86335c15.50093,5.58514,30.52651,9.70395,43.212,6.6037l0,0a32.94357,32.94357,0,0,0-27.5888-37.54219l-6.26266-.95707A32.94358,32.94358,0,0,0,827.973,241.867Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#2f2e41"
    />
    <path
      d="M787.0513,409.05794H204.1157a13.62481,13.62481,0,0,1-13.609-13.609V274.726a13.62481,13.62481,0,0,1,13.609-13.609H787.0513a13.62481,13.62481,0,0,1,13.609,13.609V395.44891A13.62481,13.62481,0,0,1,787.0513,409.05794Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#e6e6e6"
    />
    <path
      d="M747.70682,399.87846H213.29737a13.6248,13.6248,0,0,1-13.609-13.609V283.91278a13.62481,13.62481,0,0,1,13.609-13.609H777.87182a13.6248,13.6248,0,0,1,13.609,13.609v72.19165A43.82337,43.82337,0,0,1,747.70682,399.87846Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#fff"
    />
    <path
      d="M677.22905,316.70678H514.47583a3.50978,3.50978,0,1,1,0-7.01956H677.22905a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      :fill="color"
    />
    <path
      d="M718.42332,337.53958H473.28156a3.50978,3.50978,0,1,1,0-7.01956H718.42332a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#e6e6e6"
    />
    <path
      d="M718.42332,360.495H473.28156a3.50978,3.50978,0,1,1,0-7.01956H718.42332a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#e6e6e6"
    />
    <polygon
      points="359.383 89.694 357.383 89.694 357.383 12.651 502.04 12.651 502.04 14.651 359.383 14.651 359.383 89.694"
      fill="#3f3d56"
    />
    <path d="M716.14183,191.556h-53a13,13,0,0,0,0,26h53a13,13,0,0,0,0-26Z" transform="translate(-152.92923 -191.55603)" :fill="color" />
    <circle cx="357.22267" cy="90.83442" r="11.9459" fill="#e6e6e6" />
    <polygon points="789.981 495.189 778.943 495.189 773.69 452.616 789.981 452.616 789.981 495.189" fill="#a0616a" />
    <path
      d="M948.73582,705.86955h-7.91553l-1.41289-7.4732-3.61861,7.4732H914.7949a4.71923,4.71923,0,0,1-2.68162-8.60272l16.76509-11.57858V678.1331l17.634,1.05251Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#2f2e41"
    />
    <polygon points="713.981 495.189 702.943 495.189 697.69 452.616 713.981 452.616 713.981 495.189" fill="#a0616a" />
    <path
      d="M872.73582,705.86955h-7.91553l-1.41289-7.4732-3.61861,7.4732H838.7949a4.71923,4.71923,0,0,1-2.68162-8.60272l16.76509-11.57858V678.1331l17.634,1.05251Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#2f2e41"
    />
    <path
      d="M971.39814,439.3549a9.15745,9.15745,0,0,0-3.547-13.58649l-26.90842-78.75287-16.03153,8.41456,30.38472,75.25939a9.20709,9.20709,0,0,0,16.10227,8.66541Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#a0616a"
    />
    <path
      d="M803.10165,474.51167a9.15743,9.15743,0,0,0,5.56014-12.89414L835.88518,382.973l-17.81591-3.22618-22.33368,78.02828a9.20709,9.20709,0,0,0,7.36606,16.73659Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#a0616a"
    />
    <path
      d="M846.03553,427.556s70.99265-18.12524,68-12l0,0S935.95492,408.772,939,454l-.96447,90.556,3.96135,70.69567s-2.41459,5.75458.29426,5.25144.44489,7.93969.44489,7.93969-2.28091,8.98673.49153,8.772S944,651,944,651H926L891.51117,496.00159,876.03553,552.556s.85172,6.06093-.59191,6.25245-.43058,4.54834-.43058,4.54834l-.542,5.72533L867,648l-17,1s-.1335-1.73161,0-6c.09917-3.17084,1.075-8.23674-1.49738-12.41223-2.85736-4.63812-4.51532-10.00336-1.18829-15.9129,2.30292-4.09046-.68879-9.72379-1.04485-15.04781-2.24643-33.59-4.66234-78.4169-4.59194-115.053.00281-1.462-2.28151-5.117.02047-4.34554s.03071-3.29732.05459-4.91546c.04429-3.00022,3.14076-8.23124.19408-8.77214s.09286-2.87575.14788-4.2792C842.6754,447.44691,843.903,435.24843,846.03553,427.556Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#2f2e41"
    />
    <path
      d="M878.03553,279.556l-29,3-2.80986,13.52074-27.52486,16.81234,9.52486,25.75459s-7.73747,26.07972,14.13126,36.53986l7.6786,22.37247-2.70534,15.89387-.75091,4.41164c1.91926,3.56241,2.25617,6.18091-1.12072,6.58426l-1.08948,6.40057c-4.84058,2.80185-4.676,7.7819-2.33356,13.70966l97,10c3.99042-13.029-17.54315-56.04882-29-60l1.49175-64.27179L914,288l-33,3Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#3f3d56"
    />
    <path
      d="M906.45,289.898l0,0a11.03829,11.03829,0,0,1,16.64552,4.68106l17.25723,41.18419c3.26239,1.18,8.626,11.22027,6.68274,13.79284.04053,2.71985-.90246.74433,1.733,4.51819,3.00141.21295,3.78431,1.47583,1.71344,4.08917l-.44641,3.39264,1.22156.23029c3.27136.11706,3.44019,1.33575,1.39917,3.339h0a3.50278,3.50278,0,0,1,1.52971,4.07376L952,376l-17,8-27.96447-52.444L902.521,302.56117A11.0383,11.0383,0,0,1,906.45,289.898Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#3f3d56"
    />
    <path
      d="M828.948,314.02116l0,0a10.12855,10.12855,0,0,0-16.7143,8.59078c1.346,14.5499,2.77827,30.03178,2.77827,30.03178l-2.34646,40.32149,21.37008,4.59084.825-5.782,2.175-1.218-1.524-3.34465h0a2.34078,2.34078,0,0,0,2.46055-3.0327l-1.3955-4.43188h0a1.3052,1.3052,0,0,0,2.06227-1.42225l-1.29818-3.933,5.67125-39.74785L830.7732,316.10058A10.12891,10.12891,0,0,0,828.948,314.02116Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#3f3d56"
    />
    <polygon
      points="431.383 198.306 429.383 198.306 429.383 275.349 574.04 275.349 574.04 273.349 431.383 273.349 431.383 198.306"
      fill="#3f3d56"
    />
    <path
      d="M801.14183,466.556a13.01246,13.01246,0,0,0-13-13h-53a13,13,0,0,0,0,26h53A13.01245,13.01245,0,0,0,801.14183,466.556Z"
      transform="translate(-152.92923 -191.55603)"
      :fill="color"
    />
    <circle cx="431.22267" cy="193.83442" r="11.9459" fill="#e6e6e6" />
    <polygon
      points="216.83 199.306 218.83 199.306 218.83 276.349 74.173 276.349 74.173 274.349 216.83 274.349 216.83 199.306"
      fill="#3f3d56"
    />
    <path d="M165.92923,480.556h53a13,13,0,1,0,0-26h-53a13,13,0,0,0,0,26Z" transform="translate(-152.92923 -191.55603)" :fill="color" />
    <circle cx="219.22267" cy="193.16558" r="11.9459" fill="#e6e6e6" />
    <path
      d="M1011.89309,706.90342H978.70657l-.14258-.25879c-.42431-.76953-.834-1.585-1.21679-2.42285-3.41846-7.31836-4.86329-15.68848-6.13819-23.07324l-.96-5.56641a3.43688,3.43688,0,0,1,5.41016-3.3623q7.56517,5.50488,15.13623,10.999c1.91113,1.39063,4.09375,3,6.18408,4.73926.20166-.97949.4126-1.96192.62353-2.93067a3.43916,3.43916,0,0,1,6.28077-1.08593l3.88281,6.23828c2.832,4.55566,5.33154,9.04492,4.82226,13.88672a.756.756,0,0,1-.01318.17578,10.94684,10.94684,0,0,1-.56348,2.33105Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#f0f0f0"
    />
    <path
      d="M1045.20249,707.62119l-315.3575.30731a1.19069,1.19069,0,0,1,0-2.38135l315.3575-.30731a1.19069,1.19069,0,0,1,0,2.38135Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#cacaca"
    />
    <circle cx="104.1063" cy="143.34646" r="36" fill="#f2f2f2" />
    <path
      d="M393.43613,316.51816H369.68291a3.50978,3.50978,0,1,1,0-7.01956h23.75322a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      :fill="color"
    />
    <path
      d="M434.6304,337.351H328.48864a3.50978,3.50978,0,1,1,0-7.01956H434.6304a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#e6e6e6"
    />
    <path
      d="M434.6304,360.30638H328.48864a3.50978,3.50978,0,1,1,0-7.01956H434.6304a3.50978,3.50978,0,0,1,0,7.01956Z"
      transform="translate(-152.92923 -191.55603)"
      fill="#e6e6e6"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useThemes } from '@console/theme/useThemes';

export default defineComponent({
  name: 'LoginImg',

  setup() {
    const { currentThemeVariation } = useThemes();

    const color = computed(() => (currentThemeVariation.value === 'light' ? 'var(--v-primary)' : 'var(--v-secondary)'));

    return {
      color,
    };
  },
});
</script>
