import type { ConsoleApp } from '@console/types';
import { copyObject } from '@console/utils';
import { useSettingsManager } from './useSettingsManager';

export type RecentlyVisitedItem = {
  product: ConsoleApp;
  text: string;
  link: string;
  meta?: Record<string, unknown>;
  date?: number;
};

const MAX_ITEMS = 20;
const recentlyVisitedItems = useSettingsManager<RecentlyVisitedItem[]>('recently-visited', []);

function addRecentlyVisited(item: RecentlyVisitedItem) {
  if (!item.link) {
    return;
  }

  tidyItems();
  const idx = recentlyVisitedItems.value.findIndex(e => e.text === item.text);
  let tmp: RecentlyVisitedItem[] = [];
  let itemToAdd: RecentlyVisitedItem;

  // get existing record
  if (idx !== -1) {
    recentlyVisitedItems.value.splice(idx, 1);
    itemToAdd = item;
  } else {
    itemToAdd = copyObject(item);
  }

  if (!itemToAdd.date) {
    itemToAdd.date = Date.now();
  }

  tmp = [itemToAdd, ...recentlyVisitedItems.value];

  // keep only top N items
  recentlyVisitedItems.value = tmp.slice(0, MAX_ITEMS);
}

function clearRecentlyVisited() {
  recentlyVisitedItems.value = [];
}

/**
 * Removes items without link from the storage
 */
function tidyItems() {
  recentlyVisitedItems.value = recentlyVisitedItems.value.filter((e: RecentlyVisitedItem) => !!e.link);
}

function getRecentlyVisitedItems(product: ConsoleApp, limit = 5) {
  tidyItems();
  const items: RecentlyVisitedItem[] = recentlyVisitedItems.value.filter(e => e.product === product);
  const deduped: typeof items = [];
  const cache = new Map<string, number>();

  items.forEach(e => {
    if (cache.has(e.text)) {
      const existingIndex = deduped.findIndex(d => d.text == e.text);

      if (e.date > deduped[existingIndex].date) {
        deduped.splice(existingIndex, 1);
        deduped.splice(existingIndex, 0, e);
      }
    } else {
      cache.set(e.text, e.date ?? Date.now());
      deduped.push(e);
    }
  });

  return deduped.slice(0, limit);
}

export function useRecentlyVisited() {
  return {
    recentlyVisitedItems,

    addRecentlyVisited,
    clearRecentlyVisited,
    getRecentlyVisitedItems,
  };
}
