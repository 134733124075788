import { RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';
import { useSettingsManager } from '@console/composables/useSettingsManager';

export const parseProps = (props: string[]) => (route: RouteLocationNormalized) => {
  return Object.keys(route.params).reduce((acc, key) => {
    if (props.includes(key)) {
      acc[key] = Number(route.params[key]);
    } else {
      acc[key] = route.params[key];
    }
    return acc;
  }, {});
};

export const getLandingRoute = (
  storageKey: string,
  aboutPageRoute: RouteLocationNamedRaw,
  listingPageRoute: Partial<RouteLocationNormalized>
) => {
  const hasVisited = useSettingsManager<boolean>(storageKey, false);

  if (hasVisited.value) {
    return listingPageRoute;
  } else {
    hasVisited.value = true;
    return aboutPageRoute;
  }
};
