import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { throttle } from '@console/utils';

const defaults: { width: number; cacheKey: string; right?: boolean } = {
  width: 300,
  cacheKey: '',
  right: false,
};

/**
 * Allow resizing of <v-navigation-drawer />
 */
export function useMenuResize(options: typeof defaults) {
  let isMouseDown = false;
  const opts = { ...defaults, ...options };
  const navWidth = ref(0);
  const mousePos = reactive({ x: 0, y: 0 });
  const sliderPos = reactive({ x: 0, y: 0 });
  const onMouseDown = (event: MouseEvent) => {
    sliderPos.x = navWidth.value;
    mousePos.x = event.clientX;
    mousePos.y = event.clientY;
    isMouseDown = true;
    event.preventDefault();
    return false;
  };
  const onMouseMove = throttle((event: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }
    const deltaX = event.clientX - mousePos.x;

    // when nav is shown to the right, mouse direction needs to be reversed
    if (opts.right) {
      navWidth.value = sliderPos.x - deltaX;
    } else {
      navWidth.value = sliderPos.x + deltaX;
    }
  }, 50);
  const onMouseUp = (event: MouseEvent) => {
    isMouseDown = false;

    sliderPos.x = navWidth.value;
    event.preventDefault();
    return false;
  };

  watch(navWidth, value => localStorage.setItem(opts.cacheKey, value.toString()));

  onMounted(() => {
    const savedValue = localStorage.getItem(opts.cacheKey);
    if (savedValue?.match(/^\d+$/)) {
      navWidth.value = parseInt(savedValue, 10);
    } else {
      navWidth.value = opts.width; // some default value
    }

    // mouse button released
    document.addEventListener('mouseup', onMouseUp);

    // need to attach to the entire document
    // in order to take full width and height
    // this ensures the element keeps up with the mouse
    document.addEventListener('mousemove', onMouseMove);
  });

  onUnmounted(() => {
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mousemove', onMouseMove);
  });

  return {
    navWidth,
    onMouseDown,
    onMouseUp,
  };
}
