import { SourceSubscriptionStatusSimplified, SourceType } from '@console/types';

export const FIELD_CLASSIFICATION = {
  non_personal: 'Non personal',
  personal: 'Personal',
  personal_operational: 'Personal operational',
};

export const SOURCE_TYPES = [
  { value: SourceType.Database, text: 'Databases' },
  { value: SourceType.UserBehaviour, text: 'User behaviour' },
  { value: SourceType.Reservoir, text: 'Reservoir-out' },
  { value: SourceType.Service, text: 'Services' },
];

export const AVAILABLE_SOURCE_STATUSES = [
  { value: SourceSubscriptionStatusSimplified.Subscribed, text: 'Subscribed' },
  { value: SourceSubscriptionStatusSimplified.Unsubscribed, text: 'Unsubscribed' },
  { value: SourceSubscriptionStatusSimplified.Pending, text: 'Pending' },
];

export const FIELD_TYPES = [
  { value: 'boolean', text: 'True/False (boolean)' },
  { value: 'polygon', text: 'polygon' },
  { value: 'decimal', text: 'Number (decimal)' },
  { value: 'smallint', text: 'Number (smallint)' },
  { value: 'varchar', text: 'Text (varchar)' },
  { value: 'integer', text: 'Number (integer)' },
  { value: 'int', text: 'Number (int)' },
  { value: 'object', text: 'object' },
  { value: 'enum', text: 'Fixed list (enum)' },
  { value: 'timestamp', text: 'DateTime (timestamp)' },
  { value: 'time', text: 'time' },
  { value: 'varbinary', text: 'Binary Text (varbinary)' },
  { value: 'array', text: 'List of objects (array)' },
  { value: 'bigint', text: 'Number (bigint)' },
  { value: 'tinyint', text: 'Number (tinyint)' },
  { value: 'json', text: 'Text (json)' },
  { value: 'float', text: 'Number (float)' },
  { value: 'tinytext', text: 'Text (tinytext)' },
  { value: 'longtext', text: 'Text (longtext)' },
  { value: 'string', text: 'Text (string)' },
  { value: 'datetime', text: 'DateTime (datetime)' },
  { value: 'longblob', text: 'Binary Object (longblob)' },
  { value: 'mediumint', text: 'Number (mediumint)' },
  { value: 'blob', text: 'blob' },
  { value: 'double', text: 'Number (double)' },
  { value: 'bit', text: 'bit' },
  { value: 'text', text: 'text' },
  { value: 'mediumtext', text: 'mediumtext' },
  { value: 'point', text: 'point' },
  { value: 'binary', text: 'binary' },
  { value: 'date', text: 'date' },
  { value: 'NULL', text: 'NULL' },
  { value: 'set', text: 'set' },
  { value: 'char', text: 'char' },
  { value: 'timestamp with time zone', text: 'DateTime (timestamp with time zone)' },
  { value: 'uuid', text: 'Text (uuid)' },
  { value: 'character varying', text: 'Text (varchar)' },
];

export const NINJA_PROPERTY_FORMATS = [
  { value: 'integer', text: 'Number (integer)' },
  { value: 'string', text: 'Text (string)' },
  { value: 'array', text: 'List of objects (array)' },
  { value: 'date', text: 'Date' },
  { value: 'other', text: 'Other' },
];

export const RESERVOIR_CLASSES = [
  {
    text: 'Eventsflow',
    value: 'eventsflow',
  },
  {
    text: 'Firehose',
    value: 'firehose',
  },
  {
    text: 'Rzeka',
    value: 'rzeka',
  },
];
