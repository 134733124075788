import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    name: 'configs.home',
    path: '/home',
    meta: {
      title: 'Home',
      ninja: {
        name: 'home_configs',
      },
    },
  },
  {
    name: 'surveys.getting-started',
    path: '/getting-started',
    meta: {
      title: 'Getting Started',
      ninja: {
        name: 'getting_started',
      },
    },
  },
] as ConsoleRouteDefinition[];
