import { createApp } from 'vue';
import { SENTRY_DSN } from '@console/constants/config';
import * as Sentry from '@sentry/vue';

export function initSentry(app: ReturnType<typeof createApp>) {
  if (SENTRY_DSN) {
    Sentry.init({
      app,
      environment: 'production',
      dsn: SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100%
      // while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
