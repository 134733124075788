import { getColorForRole } from '@console/composables/useUser/userProfile.ts';
import { CofferAPI } from '@console/core/http';
import { GeneralErrorResponse, UserInfo, UserRoleData } from '@console/types';
import { emptyDataTableResponse, formatResponse } from '@console/ui/DataTable';
import { handleAsync } from '@console/utils';
import { impersonatedUser, isLogged, isTokenValid, token, uniqueUserRoles, userInfo } from './data';

export async function loadUserInfo() {
  const [err, data] = await handleAsync(CofferAPI.GET<UserInfo>('/user/info'));

  if (err) {
    console.warn(err);
    return;
  }

  userInfo.value = data as UserInfo;
}

// Set token and load user info with it
export async function login(withToken: string) {
  token.value = withToken;

  await loadUserInfo();
  isLogged.value = true;
}

export function logout() {
  token.value = '';
  userInfo.value = null;
  isLogged.value = false;
}

export async function checkLogin() {
  if (!isTokenValid.value) {
    return false;
  }

  if (!userInfo.value?.email) {
    await login(token.value);
  }

  return true;
}

export function impersonateUser(email = '') {
  impersonatedUser.value = email;
}

export async function loadUserRoles() {
  const [err, resp] = await handleAsync<GeneralErrorResponse, { roles: UserRoleData[] }>(CofferAPI.GET('/profile'));

  if (err) {
    return emptyDataTableResponse<UserRoleData>();
  }

  // removes duplicated roles (eg Data Project Owner of 2 Data Projects)
  uniqueUserRoles.value = Object.values(
    resp.roles.reduce((acc, role) => {
      const key = role.policy.key;

      if (!acc[key]) {
        acc[key] = {
          key,
          name: role.policy.name,
          color: getColorForRole(key),
        };
      }
      return acc;
    }, {})
  );

  return formatResponse(resp.roles);
}
