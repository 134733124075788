import type { DefineComponent } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

export enum Channel {
  Android = 'a',
  iOS = 'i',
  WebDesktop = 'd',
  WebMobile = 'm',
  Web = 'w',
  Any = '*',
  None = '',
}

export type ChannelName = 'a' | 'i' | 'd' | 'm';

export type ChannelOption = {
  text: string;
  icon: string;
  key: Channel;
};

export type Pagination = {
  current_page: number;
  limit: number;
  total_results: number;
};

export type Context = {
  brand: string;
  country: string;
  channel: Channel;
};

export type BrandCountry = {
  product_id?: number;
  brand: string;
  country: string;
  key: string;
  text: string;
};

export type ImageGroup = {
  id: number;
  url: string;
  title?: string;
};

export type LeftNavigationItem = {
  enabled: () => boolean;
  title: string;
  to: RouteLocationRaw;
  external?: boolean;

  /**
   * MDI icon string
   */
  icon?: string;

  /**
   * Other custom image, eg. SVG component
   */
  image?: string | DefineComponent;

  click?: () => void;
  action?: string;
  children?: LeftNavigationItem[];
  count?: number;
};

export type ChangelogItem = {
  field: string;
  old_value: string | number;
  new_value: string | number;
};

export type ViolationError =
  | string
  | {
      text: string;
      context?: Record<string, any>;
    };

export type GeneralErrorResponse = {
  errors: string[];
};

export type ValidationErrorResponse = {
  validation_errors: Record<string, string[]>;
};

export type ViolationErrorResponse = {
  errors: ViolationError[];
};

export type ViolationResponse = {
  violations: Record<string, string[]>;
};

export type MessageResponse = {
  message: string;
};
