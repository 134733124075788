import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'metrics.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'metrics.hasVisitedRoot',
        { name: 'metrics.about' },
        { name: 'metrics.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'metrics.about',
    path: '/about',
    meta: {
      title: 'Metrics',
      ninja: {
        name: 'home_metrics',
      },
    },
  },
  {
    name: 'metrics.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Available metrics',
      ninja: {
        name: 'metrics_library',
      },
    },
  },
  {
    name: 'metrics.report',
    path: '/report/:cuid',
    props: parseProps(['cuid']),
    redirect: { name: 'metrics.report.health' },
    meta: {
      title: 'Metrics report',
    },
    children: [
      {
        name: 'metrics.report.health',
        path: 'health',
        props: parseProps(['cuid']),
        meta: {
          title: 'Metrics report',
          ninja: {
            name: 'metrics_report_health',
          },
        },
      },
      {
        name: 'metrics.report.primary',
        path: 'primary',
        props: parseProps(['cuid']),
        meta: {
          title: 'Metrics report',
          ninja: {
            name: 'metrics_report_primary',
          },
        },
      },
    ],
  },
  {
    name: 'metric.edit',
    path: '/metric/:id/edit',
    props: parseProps(['id']),
    meta: {
      title: 'Metric edit',
      ninja: {
        name: 'create_metric',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },

  {
    name: 'metric.report',
    path: '/metric/:id/report',
    props: parseProps(['id']),
    redirect: { name: 'metric.report.definition' },
    meta: {
      title: 'Metric report',
    },
    children: [
      {
        name: 'metric.report.changelog',
        path: 'changelog',
        props: parseProps(['id']),
        meta: {
          title: 'Metric changelog',
          ninja: {
            name: 'metric_tab_changelog',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'metric.report.segments',
        path: 'segments',
        props: parseProps(['id']),
        meta: {
          title: 'Metric segments',
          ninja: {
            name: 'metric_tab_segments',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'metric.report.related-experiments',
        path: 'related-experiments',
        props: parseProps(['id']),
        meta: {
          title: 'Metric related experimetns',
          ninja: {
            name: 'metric_tab_related_experiments',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'metric.report.definition',
        path: 'definition',
        props: parseProps(['id']),
        meta: {
          title: 'Metric definition',
          ninja: {
            name: 'metric_tab_definition',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
    ],
  },

  {
    name: 'flows.list',
    path: '/flows-list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Manage user flows',
      ninja: {
        name: 'metrics_user_flows',
      },
    },
  },
  {
    name: 'flow.edit',
    path: '/flow/:id',
    props: parseProps(['id']),
    meta: {
      title: 'Flow details',
    },
  },

  {
    name: 'segments.list',
    path: '/segments-list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Manage metric segments',
      ninja: {
        name: 'metrics_segments',
      },
    },
  },
  {
    name: 'segment.edit',
    path: '/segment/:id/edit',
    props: parseProps(['id']),
    meta: {
      title: 'Segment details',
      ninja: {
        name: 'create_segment',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },
  {
    name: 'segment.report',
    path: '/segment/:id',
    props: parseProps(['id']),
    redirect: { name: 'segment.report.definition' },
    meta: {
      title: 'Segment details',
    },
    children: [
      {
        name: 'segment.report.definition',
        path: 'definition',
        props: parseProps(['id']),
        meta: {
          title: 'Segment definition',
        },
      },
      {
        name: 'segment.report.metrics',
        path: 'metrics',
        props: parseProps(['id']),
        meta: {
          title: 'Attached metrics',
        },
      },
    ],
  },
] as ConsoleRouteDefinition[];
