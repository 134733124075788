export enum UserRole {
  DPO = 'dpo',
  Ambassador = 'ambassador',
  MetricsOwner = 'metrics_owner',
  NewsEditor = 'news_editor',
  ProjectOwner = 'project_owner',
  ResourceManager = 'resource_manager',
  ReservoirUser = 'reservoir_user',
}

export enum DataProjectRequestType {
  Membership = 'membership',
  MembershipExtension = 'membership_extension',
}

export type BusinessProduct = {
  id: number;
  name: string;
  display_name: string;
  data_key: string;
  definition: Array<{
    brand: string;
    country: string;
  }>;
  product_definition: { [brand: string]: string[] };
  authinfo: {
    can_save_metric: boolean;
    can_reset_metric: boolean;
    can_snooze_metric: boolean;
    can_save_health_metric: boolean;

    can_save_metric_segment: boolean;
    can_reset_metric_segment: boolean;

    can_delete_flow: boolean;
    can_save_flow: boolean;

    [permission: string]: boolean;
  };
};

export type BusinessUnit = {
  description: string;
  key: string;
  name: string;
  owners: string[];
  user_can_edit: boolean;
  authinfo: {
    [permission: string]: boolean;
  };
};

export type CustomerUnit = {
  business_unit_key: string;
  description: string;
  id: number;
  name: string;
  user_can_edit: boolean;
  products: BusinessProduct[];
  authinfo: {
    can_assign_analyst?: boolean;
    can_save_survey?: boolean;
    [permission: string]: boolean;
  };
};

export type OpslevelTeam = {
  name: string;
  opslevel_url: string;
  opslevel_id?: string;
  members?: {
    email: string;
    opslevel_id: string;
  }[];
};

export type DataProject = {
  authinfo: {
    can_edit: boolean;
    can_create_resource: boolean;
    can_request_access_extension: boolean;
    [permission: string]: boolean;
  };
  business_unit_name: string;
  created_at: string;
  customer_unit_id: number;
  customer_unit_name: string;
  deleted_at: string | null;
  deleted_by: string | null;
  description: string;
  id: number;
  name: string;
  owners: string[];
  data_owners: string[];
  labels: { bu: string; cu: string; dp: string };

  opslevel_team_id?: string;
  opslevel_team?: OpslevelTeam;
};

export type DataProjectAssignmentsDefinition = {
  [UserRole.ProjectOwner]: string[];
  [UserRole.ResourceManager]: string[];
  [UserRole.ReservoirUser]: string[];
};

export type DataProjectRequestAccess = {
  assignments: Partial<DataProjectAssignmentsDefinition>;
  reasons: {
    [UserRole.ProjectOwner]?: string;
    [UserRole.ResourceManager]?: string;
    [UserRole.ReservoirUser]?: string;
  };
};

export type DataProjectRequestDetails = {
  id: number;
  created_at: string;
  requestor: string;
  requested_for: string;
  reason: string;
  approval_actor: string | null;
  approval_message: string | null;
  state: string;
  request_type: DataProjectRequestType;
  requested_role: UserRole;
};

export type DataProjectPolicyItem = { assignment_id: number; policy: string; scope: string; expires_at?: string };

export type DataProjectAssignments = {
  identity: string;
  policies: DataProjectPolicyItem[];
};
