import { RouteRecordRaw } from 'vue-router';
import { PUBLIC_PATH } from '@console/constants/config';
import { getRouter } from './getRouter';
import isUserAuthenticatedGuard from './guards/isUserAuthenticatedGuard';
import trackPageGuard from './guards/trackPageGuard';
import Page404 from './pages/404.vue';
import { LoginPage } from './pages/LoginPage';
import { SsoPage } from './pages/SsoPage';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      anonymous: true,
      title: 'Login',
    },
  },
  {
    path: '/sso',
    name: 'sso',
    component: SsoPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: Page404,
    meta: {
      anonymous: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = getRouter(routes, PUBLIC_PATH);

router.beforeEach(isUserAuthenticatedGuard);
router.beforeEach(trackPageGuard);

router.onError((error, to) => {
  const dynamicImportErrorMessages = [
    'Failed to fetch dynamically imported module',
    'Importing a module script failed',
    'error loading dynamically imported module',
  ];

  const isDynamicImportError = dynamicImportErrorMessages.some(message => error.message.includes(message));

  if (isDynamicImportError) {
    console.error('Reloading the page due to:', error);

    if (to?.fullPath) {
      // try to reload the target page if full path is available
      const targetUrl = `${window.origin}/${PUBLIC_PATH}${to.fullPath}`;
      window.location.href = targetUrl;
    } else {
      // otherwise reload the previous page
      window.location.reload();
    }
  }
});

export default router;
