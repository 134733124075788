<template>
  <v-list-item
    :density="subroute ? 'comfortable' : 'default'"
    :to="link.external ? '' : link.to"
    :href="link.external ? link.to : ''"
    :target="link.external ? '_blank' : ''"
    :exact="link.exact || subroute"
    class="nav-item"
    active-class="nav-item--active"
    v-bind="$attrs"
    @click="link.click && link.click()"
  >
    <!-- <v-list-item-action class="justify-center" :class="{ 'mr-4': !isMini }" v-if="link.icon || link.image || subroute">
      <v-icon class="on-surface-variant--text" v-if="link.icon">{{ link.icon }}</v-icon>
      <component :is="link.image" v-if="link.image" />
    </v-list-item-action> -->

    <template #prepend>
      <v-icon class="text-on-neutral" v-if="link.icon">{{ link.icon }}</v-icon>
      <component :is="link.image" v-if="link.image" />
    </template>

    <v-list-item-title class="text-subtitle-3">
      {{ link.title }}
      <v-icon :size="12" style="margin-bottom: 2px" v-if="link.external">mdi-open-in-new</v-icon>
    </v-list-item-title>

    <template #append>
      <span class="text-overline text-uppercase" v-if="link.action">{{ link.action }}</span>
      <span class="text-caption-2" v-if="hasCount">{{ link.count }}</span>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { computed } from 'vue';

export default {
  name: 'NavItem',
  props: {
    link: Object,
    subroute: Boolean,
    isMini: Boolean,
  },
  setup(props) {
    const hasCount = computed(() => {
      return typeof props.link.count === 'number';
    });

    return {
      hasCount,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@console/theme/styles/variables';

.nav-item {
  border: none;

  &:not(.v-list-item--active):not(.v-list-item--disabled) {
    background: var(--v-background);
    color: var(--v-on-background);
  }

  &--active {
    color: var(--v-on-secondary-container);
    background: var(--v-secondary-container);

    &::before {
      border-radius: $border-radius-root !important;
    }
  }
}
</style>
