import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    name: 'midway.index',
    path: '/home',
    meta: {
      title: 'Midway',
      ninja: {
        name: 'midway',
      },
    },
    children: [
      {
        path: '/home',
        name: 'midway.home',
        props: true,
      },
      {
        path: '/my-accounts',
        name: 'midway.my_accounts',
      },
      {
        path: '/my-requests',
        name: 'midway.my_requests',
      },
      {
        path: '/review-requests',
        name: 'midway.review_requests',
      },
      {
        path: '/schemas',
        name: 'midway.schemas',
      },
      {
        path: '/service-accounts',
        name: 'midway.service_accounts',
      },
    ],
  },
  {
    path: '/create-account',
    name: 'midway.create_account',
    meta: {
      authRequired: true,
      onlyRedirect: true,
    },
  },
  {
    path: '/select-cluster',
    name: 'midway.cluster_selection',
    meta: {
      authRequired: true,
      onlyRedirect: true,
    },
  },
  {
    path: '/error',
    name: 'midway.error',
    props: true,
    meta: {
      authRequired: true,
      onlyRedirect: true,
    },
  },
] as ConsoleRouteDefinition[];
