<template>
  <v-list-item
    :href="item.link"
    :target="isExternalLink ? '_blank' : '_self'"
    :data-testid="`menu-item-${item.id}`"
    :active="active"
    v-bind="$attrs"
  >
    <template #prepend v-if="item.icon">
      <v-icon size="small">{{ item.icon }}</v-icon>
    </template>
    <v-list-item-title class="text-body-2" data-testid="text">{{ item.text }}</v-list-item-title>

    <template #append>
      <v-icon size="small" data-testid="external-icon" v-if="isExternalLink">mdi-open-in-new</v-icon>

      <v-icon size="small" data-testid="children-icon" v-else-if="item.children?.length">mdi-arrow-right</v-icon>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';
import { ConsoleProduct } from '@console/types';

export default defineComponent({
  name: 'ConsoleMenuItem',
  components: {},
  props: {
    item: {
      type: Object as PropType<ConsoleProduct>,
      required: true,
    },
    active: { type: Boolean, default: false },
  },

  setup(props) {
    const isExternalLink = computed(() => {
      return props.item.link?.match(/^https?:\/\//);
    });

    return {
      isExternalLink,
    };
  },
});
</script>
