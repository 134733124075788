<template>
  <Modal :width="600" persistent hide-close-icon>
    <template #header>
      <div>Terms and Conditions</div>
    </template>
    <template #body>
      <div>
        <p>
          Most of the data collected by OLX belong to our end users. Those trust OLX to do the right thing with their data, especially when
          it's about their privacy.
        </p>
        <p>
          Console allows you to access this data to build the best OLX product for our end users. However, accessing and managing data
          requires you to proceed with extra care.
        </p>
        <p>
          We have implemented <a target="_blank" :href="TERMS_AND_CONDITIONS_DOCS_URI">terms and conditions (“T&Cs”)</a> that you hereby
          agree to as a condition to access or manage the data in the Console.
        </p>
      </div>
      <v-checkbox label="I have read and agreed to the Terms and Conditions" v-model="accept"></v-checkbox>
    </template>
    <template #footer>
      <ModalActionPrimary :disabled="!accept" @click="modalData.onAccept">Accept</ModalActionPrimary>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useModal } from '@console/composables';
import { TERMS_AND_CONDITIONS_DOCS_URI } from '@console/constants';
import { Modal, ModalActionPrimary } from '../Modal';

type ModalData = {
  onAccept: () => void;
};

const accept = ref(false);
const { modalData } = useModal<ModalData>();
</script>
