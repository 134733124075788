import type { ColorTones, ThemeName } from '../../types';
import { ThemeColorPalette, ThemeNeutralPalette } from '../../types';
import { mergePalettes } from '../mergePalettes';
import { shadesPalette } from '../shades';
import * as corePalette from './core';

export const name: ThemeName = 'blue';

export const primary: ColorTones = {
  p0: shadesPalette.black,

  p99: '#FDFCFF',
  p95: '#EBF1FF',
  p90: '#D4E3FF',
  p80: '#A5C8FF',
  p70: '#71ADFF',
  p60: '#4192F1',
  p50: '#1778D5',
  p40: '#005FAF',
  p30: '#004785',
  p20: '#00315E',
  p10: '#001C3A',

  p5: '#001128',
  p25: '#003C72',
  p35: '#00539A',
  p98: '#F9F9FF',
  p100: shadesPalette.white,
};

export const secondary: ColorTones = {
  p0: shadesPalette.black,

  p99: '#FDFCFF',
  p95: '#EBF1FF',
  p90: '#D8E3F8',
  p80: '#BCC7DC',
  p70: '#A1ACC0',
  p60: '#8791A5',
  p50: '#6D788A',
  p40: '#545F71',
  p30: '#3D4758',
  p20: '#273141',
  p10: '#111C2B',

  p5: '#061120',
  p25: '#323C4C',
  p35: '#495364',
  p98: '#F9F9FF',
  p100: shadesPalette.white,
};

export const tertiary: ColorTones = {
  p0: shadesPalette.black,

  p99: '#F6FFF1',
  p95: '#C7FFC4',
  p90: '#95F99A',
  p80: '#79DC81',
  p70: '#5EBF68',
  p60: '#42A450',
  p50: '#238939',
  p40: '#006E26',
  p30: '#00531A',
  p20: '#003910',
  p10: '#002106',

  p5: '#001503',
  p25: '#004615',
  p35: '#006020',
  p98: '#EBFFE6',
  p100: shadesPalette.white,
};

export const neutral: ColorTones = {
  p0: shadesPalette.black,

  p99: '#F5F5F5',
  p95: '#EFF1F1',
  p90: '#E1E3E3',
  p80: '#C4C7C7',
  p70: '#A9ACAC',
  p60: '#8E9192',
  p50: '#747878',
  p40: '#5C5F5F',
  p30: '#444748',
  p20: '#2E3132',
  p10: '#191C1D',

  p5: '#101114',
  p15: '#232626',
  p25: '#3A3B3E',
  p35: '#515256',
  p98: '#FAF9FD',
  p100: shadesPalette.white,
};

export const neutralVariant: ColorTones = {
  p0: shadesPalette.black,

  p99: '#FDFCFF',
  p95: '#EEF0FA',
  p90: '#E0E2EC',
  p80: '#C3C6CF',
  p70: '#A8ABB4',
  p60: '#8D9199',
  p50: '#74777F',
  p40: '#5B5E66',
  p30: '#43474E',
  p20: '#2D3138',
  p10: '#181C22',

  p5: '#0D1117',
  p25: '#383B43',
  p35: '#4F525A',
  p98: '#F9F9FF',
  p100: shadesPalette.white,
};

export const colorPalette: ThemeColorPalette = mergePalettes(corePalette.colorPalette, {
  primary,
  secondary,
  tertiary,
});

export const neutralPalette: ThemeNeutralPalette = mergePalettes(corePalette.neutralPalette, {
  neutral,
  neutralVariant,
});
