import { computed, ref } from 'vue';
import { BrandCountry, BusinessProduct, CustomerUnit } from '@console/types';
import { DataTableFilterBase } from '@console/ui/DataTable';
import { getStorageKey, sortAsc } from '@console/utils';
import { useCoffer } from './useCoffer';
import { useUser } from './useUser';

/**
 * init the selected CU with saved value from storage.
 * ConsoleApplication will call `checkCustomerUnit()` and use fallback to default value, if it's not set.
 * @see libraries/ui/ConsoleApplication/ConsoleApplication.vue
 * Every page has responsibility to update the selected CU to match the currently displayed
 */
const storageKey = getStorageKey('customerUnitId');
const savedValue = localStorage.getItem(storageKey);
const customerUnitId = ref<number>(savedValue ? parseInt(savedValue, 10) : 2000001);

const selectedCustomerUnit = computed(() => {
  const coffer = useCoffer();
  return coffer.customerUnitsMap.value.get(customerUnitId.value);
});

const setCustomerUnit = (value?: number | CustomerUnit) => {
  const cuId = typeof value === 'number' ? value : value?.id;

  if (!cuId) {
    return false;
  }

  if (cuId === customerUnitId.value) {
    return true;
  }
  const coffer = useCoffer();
  const found = coffer.customerUnitsMap.value.get(cuId);

  if (found) {
    customerUnitId.value = found.id;
    localStorage.setItem(storageKey, found.id.toString());
    return true;
  }

  return false;
};

const brandCountries = computed(() => {
  return sortAsc(
    selectedCustomerUnit.value?.products
      .flatMap(e => e.definition.map(d => ({ ...d, product_id: e.id })))
      .map(
        e =>
          ({
            brand: e.brand,
            country: e.country,
            key: `${e.brand}-${e.country}`,
            text: `${e.brand} ${e.country}`.toUpperCase(),
            product_id: e.product_id,
          }) as BrandCountry
      ),
    e => e.text
  );
});

/**
 * Get List of products for the current CU with specific permission
 */
function getAvailableProducts(checkFn: (e: BusinessProduct) => boolean = () => true) {
  const coffer = useCoffer();
  const products = coffer.customerUnitsMap.value.get(customerUnitId.value)?.products;

  return products
    ? sortAsc(
        products.filter(e => checkFn(e)),
        e => e.display_name
      )
    : [];
}

export function useGlobalFilters() {
  const availableDataProjects = computed(() => {
    const coffer = useCoffer();
    return coffer.dataProjects.value.filter(item => item.customer_unit_id === customerUnitId.value);
  });

  return {
    customerUnitId,
    selectedCustomerUnit,
    brandCountries,
    setCustomerUnit,
    getAvailableProducts,
    availableDataProjects,
  };
}

/**
 * Check if there's a saved CU and, if not, set default value
 * @returns {number | undefined} The `id` of the CU, if such was set
 */
export function checkCustomerUnit() {
  const { customerUnits } = useCoffer();
  const { userInfo } = useUser();
  if (!selectedCustomerUnit.value && userInfo.value) {
    const id = userInfo.value.user_affiliations?.customer_units?.[0] ?? customerUnits.value[0].id;
    setCustomerUnit(id);
    return id;
  }
  return undefined;
}

export function applyCustomerUnit(filters: DataTableFilterBase | Record<string, unknown> = {}, key = 'customer_unit_id') {
  return {
    ...filters,
    [key]: customerUnitId.value,
  };
}
