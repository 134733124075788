<template>
  <v-dialog
    :id="id"
    :max-width="fullscreen ? '' : width"
    :fullscreen="fullscreen"
    :scrollable="fullscreen"
    :scrim="!fullscreen"
    :persistent="persistent"
    :content-class="contentClass"
    :transition="fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'"
    v-bind="dialogProps"
    class="modal"
    data-testid="modal"
    @update:model-value="onDialogClose"
    v-model="modalValue"
  >
    <v-card variant="flat" :data-modal="id" :loading="loading" class="pa-0 bg-surface3" v-if="modalValue">
      <v-card-title class="text-h4 pa-6 modal--header text-on-surface">
        <div>
          <div>
            <v-row class="flex-column text-center" align="center" v-if="$slots.icon">
              <v-col class="mb-4">
                <slot name="icon" :data="modalData" />
              </v-col>

              <v-col>
                <slot name="header" :data="modalData" />
              </v-col>
            </v-row>

            <slot name="header" :data="modalData" v-else />
          </div>
          <div v-if="!hideCloseIcon">
            <v-btn variant="text" icon="mdi-close" density="comfortable" class="mr-n3" @click="closeModal" />
          </div>
        </div>
      </v-card-title>
      <v-card-text class="fill-height px-6 pb-4 pt-0 text-on-surface-variant modal--body">
        <slot name="body" :data="modalData" />
      </v-card-text>

      <v-card-actions class="px-6 pb-4 pt-2 modal--footer">
        <slot name="actions" :data="modalData" v-if="$slots.actions" />
        <v-spacer></v-spacer>
        <slot name="footer" :data="modalData" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from 'vue';
import { ref } from 'vue';
import { useModal } from '@console/composables';
import { uuid } from '@console/utils';

/**
 * A modal displays content that temporarily blocks interactions with the main view
 */
export default defineComponent({
  name: 'Modal',

  props: {
    /**
     * The modal's id
     */
    id: {
      type: String,
      default: () => uuid(),
      required: false,
    },
    /**
     * Sets the modal's width
     */
    width: {
      type: [String, Number],
      default: 500,
      required: false,
    },
    /**
     * Enables fullscreen mode
     */
    fullscreen: {
      type: Boolean,
      required: false,
    },
    /**
     * Applies a custom class to the detached element
     */
    contentClass: {
      type: String,
      required: false,
    },
    /**
     * Displays linear progress bar
     */
    loading: {
      type: Boolean,
      required: false,
    },
    /**
     * Clicking outside of the element or pressing esc key will not deactivate it.
     */
    persistent: {
      type: Boolean,
      required: false,
    },
    /**
     * Applies custom props
     */
    dialogProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    /**
     * Manages close icon visibility
     */
    hideCloseIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['show', 'hide'],

  setup(props, { emit }) {
    const { currentModal, modalData, closeModal } = useModal();
    const modalValue = ref(false);

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && !props.persistent) {
        closeModal();
      }
    };
    const onDialogClose = (state: boolean) => {
      if (!state && currentModal.value === props.id) {
        closeModal();
      }
    };

    const handleState = (value, prevValue) => {
      document.removeEventListener('keydown', onKeyPress, true);

      if (value === props.id) {
        modalValue.value = true;
        document.addEventListener('keydown', onKeyPress, true);
        emit('show');
      } else if (prevValue === props.id) {
        modalValue.value = false;
        emit('hide');
      }
    };
    watch(currentModal, handleState);

    onMounted(() => handleState(currentModal.value, null));

    return {
      modalValue,
      modalData,
      onDialogClose,
      closeModal,
    };
  },
});
</script>

<style lang="scss">
.v-dialog {
  &.v-dialog--fullscreen {
    $fullscreenOffset: 0px;

    width: calc(100% - #{2 * $fullscreenOffset});
    height: calc(100% - #{2 * $fullscreenOffset});
    margin: $fullscreenOffset;
  }

  .v-dialog__content {
    align-items: baseline;
  }

  .v-card-title {
    white-space: initial;
    hyphens: none;

    > div {
      display: grid;
      grid-template-columns: 1fr 40px;
    }
  }

  .modal--footer {
    .v-btn {
      text-transform: capitalize;
    }
  }
}
</style>
