<template>
  <v-btn variant="text" class="text-on-background" icon="mdi-theme-light-dark" @click="changeVariation(theme)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useTheme } from 'vuetify';
import { useThemes } from '@console/theme/useThemes';

export default defineComponent({
  name: 'ToggleTheme',

  setup() {
    const theme = useTheme();
    const { changeVariation } = useThemes();

    return {
      theme,
      changeVariation,
    };
  },
});
</script>
