import { Channel, ChannelOption, MetricType } from '@console/types';

export const METRIC_SYSTEM_TAGS = {
  SCHEDULED_CLEANUP: 'system: scheduled for cleanup',
};

export const METRIC_TYPE_FILTERS = {
  ALL: 'all',
  PRIMARY: 'primary',

  ...MetricType,
};

export const METRIC_TYPE_TEXT = {
  [MetricType.Health]: 'Business Health',
  [MetricType.Flow]: 'Flow Health',
  [MetricType.Conversion]: 'Supplementary',
  [MetricType.Vitals]: 'Core web Vitals',
  [MetricType.Revenue]: 'Revenue',
};

export const SEGMENT_TYPE = {
  BASE: 'Apply to Base value',
  TARGET: 'Apply to Target value',
  BASE_TARGET: 'Apply to Target and Base values',
};

export const CHANNEL_OPTIONS: ChannelOption[] = [
  { text: 'Web (Desktop)', key: Channel.WebDesktop, icon: 'mdi-monitor' },
  { text: 'Web (Mobile)', key: Channel.WebMobile, icon: 'mdi-cellphone' },
  {
    text: 'Android',
    key: Channel.Android,
    icon: 'mdi-android',
  },
  {
    text: 'iOS',
    key: Channel.iOS,
    icon: 'mdi-apple',
  },
];

export const CHANNEL_OPTIONS_WEB_MERGED: ChannelOption[] = [
  { text: 'Web', key: Channel.Web, icon: 'mdi-monitor' },
  {
    text: 'Android',
    key: Channel.Android,
    icon: 'mdi-android',
  },
  {
    text: 'iOS',
    key: Channel.iOS,
    icon: 'mdi-apple',
  },
];

export const CHANNEL_OPTIONS_FLAGS = [...CHANNEL_OPTIONS, { text: 'Process', key: 'p', icon: 'mdi-cogs' }];

export const CHANNEL_OPTIONS_KEYED = {
  [Channel.Android]: CHANNEL_OPTIONS.find(e => e.key === Channel.Android),
  [Channel.iOS]: CHANNEL_OPTIONS.find(e => e.key === Channel.iOS),
  [Channel.WebDesktop]: CHANNEL_OPTIONS.find(e => e.key === Channel.WebDesktop),
  [Channel.WebMobile]: CHANNEL_OPTIONS.find(e => e.key === Channel.WebMobile),
};

export const METRIC_OPERATOR_TYPES = [
  { value: 'equals', text: '=' },
  { value: 'not_equals', text: '!=' },
  { value: 'like', text: 'LIKE' },
  { value: 'not_like', text: 'NOT LIKE' },
  { value: 'is_null', text: 'IS NULL', single: true },
  { value: 'is_not_null', text: 'IS NOT NULL', single: true },
  { value: 'greater_than', text: '>', type: 'number' },
  { value: 'less_than', text: '<', type: 'number' },
  { value: 'in', text: 'IN' },
  { value: 'not_in', text: 'NOT IN' },
];

export const LABEL_TYPES = {
  started: {
    cssColor: 'var(--v-success)',
    color: 'success--text',
    icon: 'mdi-play',
    text: 'Started',
  },
  archived: {
    cssColor: 'var(--v-grey-lighten1)',
    color: 'grey--text text--lighten-1',
    icon: 'mdi-package-down',
    text: 'Archived',
  },
  paused: {
    cssColor: 'var(--v-warning)',
    color: 'warning--text',
    icon: 'mdi-pause',
    text: 'Paused',
  },
  finished: {
    cssColor: 'var(--v-dark)',
    color: 'dark--text',
    icon: 'mdi-check',
    text: 'Finished',
  },

  draft: {
    cssColor: 'var(--v-purplerain)',
    color: 'purplerain--text',
    icon: 'mdi-file-document-outline',
    text: 'Draft',
  },
  qamode: {
    cssColor: 'var(--v-purplerain)',
    color: 'purplerain--text',
    icon: 'mdi-file-document-outline',
    text: 'Draft',
  },
  created: {
    cssColor: 'var(--v-purplerain)',
    color: 'purplerain--text',
    icon: 'mdi-file-document-outline',
    text: 'Created',
  },
};

export const PAYMENT_STATUSES = [
  'canceled',
  'captured',
  'completed',
  'confirmed',
  'failed',
  'finished',
  'invalid',
  'new',
  'pending',
  'ready',
  'refunded',
  'rejected',
  'wait',
  'waiting_for_ready',
] as const;
