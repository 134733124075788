import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import { getLandingRoute, parseProps } from '@console/router/utils';
import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    name: 'data-projects.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'data-projects.hasVisitedRoot',
        { name: 'data-projects.about' },
        { name: 'data-projects.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'data-projects.about',
    path: '/about',
    meta: {
      title: 'Data Projects',
      ninja: {
        name: 'home_data_projects',
      },
    },
  },
  {
    name: 'data-projects.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Available data projects',
      ninja: {
        name: 'data_projects_list',
      },
    },
  },
  {
    name: 'data-projects.details',
    path: '/details/:id',
    props: parseProps(['id']),
    meta: {
      title: 'Data projects details',
      ninja: {
        name: 'data_projects_details',
        props: to => ({ resource_id: to.params.id }),
      },
    },
    redirect: { name: 'data-projects.details.roles' },
    children: [
      {
        name: 'data-projects.details.roles',
        path: 'roles',
        props: parseProps(['id']),
        meta: {
          title: 'Data projects details | Roles',
          ninja: {
            name: 'data_projects_details_roles',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'data-projects.details.resources',
        path: 'resources',
        props: parseProps(['id']),
        meta: {
          title: 'Data projects details | Resources',
          ninja: {
            name: 'data_projects_details_resources',
            props: to => ({ resource_id: to.params.id }),
          },
        },
        redirect: { name: 'data-projects.details.resources-reservoirs' },
        children: [
          {
            name: 'data-projects.details.resources-sources',
            path: 'sources',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Sources',
              ninja: {
                name: 'data_projects_details_sources',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'data-projects.details.resources-reservoirs',
            path: 'reservoirs',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Reservoirs',
              ninja: {
                name: 'data_projects_details_reservoirs',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'data-projects.details.resources-experiments',
            path: 'experiments',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Experiments',
              ninja: {
                name: 'data_projects_details_experiments',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'data-projects.details.resources-flags',
            path: 'feature-flags',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Feature flags',
              ninja: {
                name: 'data_projects_details_flags',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'data-projects.details.resources-events',
            path: 'events',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Events',
              ninja: {
                name: 'data_projects_details_events',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'data-projects.details.resources-properties',
            path: 'properties',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | Properties',
              ninja: {
                name: 'data_projects_details_properties',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
        ],
      },
      {
        name: 'data-projects.details.warnings',
        path: 'warnings',
        props: parseProps(['id']),
        meta: {
          title: 'Data projects details | Warnings',
          ninja: {
            name: 'data_projects_details_warnings',
            props: to => ({ resource_id: to.params.id }),
          },
        },
        redirect: { name: 'data-projects.details.warnings-gdpr' },
        children: [
          {
            name: 'data-projects.details.warnings-gdpr',
            path: 'gdpr',
            props: parseProps(['id']),
            meta: {
              title: 'Data projects details | GDPR warnings',
              ninja: {
                name: 'data_projects_details_warnings_gdpr',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'data-projects.details.request-access',
    path: '/details/:id/request-access',
    props: parseProps(['id']),
    meta: {
      title: 'Request access to Data project',
      ninja: {
        name: 'data_projects_details_request_access',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },
  {
    name: 'data-projects.create',
    path: '/create/:id',
    props: parseProps(['id']),
    meta: {
      title: 'Data project create',
      ninja: {
        name: 'create_data_project',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },
] as ConsoleRouteDefinition[];
