import { nextTick } from 'vue';
import { ThemeInstance } from 'vuetify';
import { useSettingsManager } from '@console/composables/useSettingsManager';
import { trackEvent } from '@console/core/ninja';
import { setOpacity } from './colorUtils';
import { themes } from './themes';
import { ConsoleTheme, ThemeName, ThemeVariation } from './types';

export function userPreferedTheme() {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }

  return 'light';
}

const opacities = [0.08, 0.12, 0.16];
const currentThemeVariation = useSettingsManager<ThemeVariation>('theme', userPreferedTheme());

const getThemeClass = (name: ThemeName, variation = currentThemeVariation.value) => {
  return `console__${name}--${variation}`;
};

const getVariationClass = (variation = currentThemeVariation.value) => {
  return `console--${variation}`;
};

export function isDark() {
  return currentThemeVariation.value === 'dark';
}

/**
 * Generate CSS variables and BG/text classes based on the theme colors
 */
export function setCustomColors() {
  const getColorName = (c: string) => `--v-${c}`;
  const getColorVarName = (c: string) => `var(${getColorName(c)})`;
  const getClasses = (c: string) => {
    const varName = getColorVarName(c);

    return [
      `#console .v-application .bg-${c} { 
          background-color: ${varName} !important; 
          border-color: ${varName} !important; 
          fill: ${varName} !important; 
      }`,
      `#console .v-application .text-${c} { 
          color: ${varName} !important; 
          caret-color: ${varName} !important; 
      }`,

      ...opacities.flatMap(opacity => {
        const opStr = `${opacity.toString().replace('.', '')}`;
        const suffix = `opacity-${opStr}`;
        const opVarName = getColorVarName(`${c}-${suffix}`);
        return [
          `#console .v-application .${c}-${suffix} { 
            background-color: ${opVarName} !important; 
            border-color: ${opVarName} !important; 
            fill: ${opVarName} !important; 
          }`,
          `#console .v-application .${c}-${suffix}--text { 
              color: ${opVarName} !important; 
              caret-color: ${opVarName} !important; 
          }`,
        ];
      }),
    ].join('');
  };
  const styleId = 'console-custom-styles';
  let styleEl = document.querySelector(`#${styleId}`);
  let html = '';

  if (!styleEl) {
    styleEl = document.createElement('style');
    styleEl.id = styleId;
    styleEl.setAttribute('type', 'text/css');
    document.head.append(styleEl);
  }

  const classes: string[] = [];

  Object.entries(themes).forEach(([name, theme]) => {
    ['dark', 'light'].forEach(variation => {
      const vars: string[] = [];
      const themeClasses = getThemeClass(name as ThemeName, variation as ThemeVariation);
      const themeValue: ConsoleTheme = theme[variation];

      Object.entries(themeValue).forEach(([k, v]) => {
        vars.push(`${getColorName(k)}: ${v};`);

        opacities.forEach(opacity =>
          vars.push(`${getColorName(k)}-opacity-${opacity.toString().replace('.', '')}: ${setOpacity(v, opacity)};`)
        );
      });

      html += `${themeClasses
        .split(' ')
        .map(e => `.${e}`)
        .join('')} {
          ${vars.join('')}
        }
      `;
    });
  });

  // Classes are not linked with the theme - only the underlying variable changes, based on theme
  Object.keys(themes.core.light).forEach(c => classes.push(getClasses(c)));
  html += classes.join('\n');
  // console.log(html);
  styleEl.innerHTML = html.replace(/\s\s+/g, ' ');
}

export function setRootThemeVariation(value: ThemeVariation) {
  document.documentElement.style.setProperty('color-scheme', value);
}

export function useThemes() {
  const changeVariation = (theme: ThemeInstance) => {
    console.log(theme);
    const value = !theme.global.current.value.dark;
    const newVariation = value ? 'dark' : 'light';
    theme.global.name.value = newVariation;
    currentThemeVariation.value = newVariation;
    setRootThemeVariation(currentThemeVariation.value);

    nextTick(() => trackEvent('dark_mode_click'));
  };

  const themeOverride = useSettingsManager<ThemeName>('themeOverride', 'core');

  const changeTheme = (theme: ThemeName) => {
    const consoleRoot = document.querySelector('#console');
    if (consoleRoot) {
      themeOverride.value = theme;

      consoleRoot.setAttribute('class', getThemeClass(theme, currentThemeVariation.value));
    }
  };

  return {
    currentThemeVariation,
    getThemeClass,
    getVariationClass,
    changeVariation,
    changeTheme,
  };
}
