<template>
  <v-row class="datatable__row--expanded gr-4 mx-n3">
    <slot name="loading" v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader type="table-row" />
      </v-col>
    </slot>
    <slot v-else />
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataTableExpandedRow',
  props: {
    loading: {
      type: Boolean,
    },
    paddingRight: {
      type: String,
      // the default is the same as default width of actions column
      default: '150px',
    },
  },

  setup() {},
});
</script>

<style lang="scss">
.datatable__row--expanded {
  padding-right: v-bind(paddingRight);

  & + & {
    margin-top: 16px !important;
  }
}
</style>
