import * as actions from './actions';
import * as data from './data';
import * as trainingsAndCetificates from './trainingsAndCetificates';
import * as userProfile from './userProfile';

export function useUser() {
  return {
    ...data,
    ...trainingsAndCetificates,
    ...actions,
    ...userProfile,
  };
}
