import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'reservoirs.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'reservoirs.hasVisitedRoot',
        { name: 'reservoirs.about' },
        { name: 'reservoirs.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'reservoirs.about',
    path: '/about',
    meta: {
      title: 'About',
      ninja: {
        name: 'home_reservoirs',
      },
    },
  },
  {
    name: 'reservoirs.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    redirect: { name: 'reservoirs.list.all' },
    meta: {
      title: 'Reservoirs',
      ninja: {
        name: 'reservoirs_list',
      },
    },
    children: [
      {
        name: 'reservoirs.list.all',
        path: 'all',
        props: parseProps(['cuid']),
        meta: {
          // title: 'Reservoirs',
          ninja: {
            // name: 'metrics_library',
          },
        },
      },
      {
        name: 'reservoirs.list.deleted',
        path: 'deleted',
        props: parseProps(['cuid']),
        meta: {
          // title: 'Reservoirs',
          ninja: {
            // name: 'metrics_library',
          },
        },
      },
    ],
  },
  {
    name: 'reservoirs.reservoir-details.root',
    path: '/details',
    redirect: { name: 'reservoirs.reservoir-details' },
    props: parseProps(['resId']),
    meta: {
      title: 'Reservoir details',
      ninja: {
        name: 'reservoirs_details',
      },
    },
    children: [
      {
        name: 'reservoirs.reservoir-details',
        path: ':resId',
        redirect: { name: 'reservoirs.reservoir-details.subscriptions' },
        props: parseProps(['resId']),
        meta: {
          title: 'Reservoir details',
          ninja: {
            name: 'reservoirs_details',
          },
        },
        children: [
          {
            name: 'reservoirs.reservoir-details.subscriptions',
            path: 'subscriptions',
            props: parseProps(['resId']),
            meta: {
              title: 'Reservoir details | Subscriptions',
              ninja: {
                name: 'reservoirs_details_subscriptions',
              },
            },
          },
          {
            name: 'reservoirs.reservoir-details.access',
            path: 'access',
            props: parseProps(['resId']),
            meta: {
              title: 'Reservoir details | Access',
              ninja: {
                name: 'reservoirs_details_access',
              },
            },
            redirect: { name: 'reservoirs.reservoir-details.access-users' },
            children: [
              {
                name: 'reservoirs.reservoir-details.access-users',
                path: 'users',
                props: parseProps(['resId']),
                meta: {
                  ninja: {
                    name: 'reservoirs_details_access_users',
                  },
                },
              },
              {
                name: 'reservoirs.reservoir-details.access-systems',
                path: 'systems',
                props: parseProps(['resId']),
                meta: {
                  ninja: {
                    name: 'reservoirs_details_access_systems',
                  },
                },
              },
            ],
          },
          {
            name: 'reservoirs.reservoir-details.history',
            path: 'history',
            props: parseProps(['resId']),
            redirect: { name: 'reservoirs.reservoir-details.history-subscriptions' },

            meta: {
              title: 'Reservoir details | History',
              ninja: {
                name: 'reservoirs_details_history',
                props: to => ({ resId: to.params.id }),
              },
            },
            children: [
              {
                name: 'reservoirs.reservoir-details.history-subscriptions',
                path: 'subscriptions',
                props: parseProps(['resId']),
                meta: {
                  ninja: {
                    name: 'reservoirs_details_history_subscription_list',
                  },
                },
              },
              {
                name: 'reservoirs.reservoir-details.history-access',
                path: 'access',
                props: parseProps(['resId']),
                meta: {
                  ninja: {
                    name: 'reservoirs_details_history_access_list',
                  },
                },
              },
              {
                name: 'reservoirs.reservoir-details.history-reservoir-change',
                path: 'reservoir-change',
                props: parseProps(['resId']),
                meta: {
                  // title: 'Reservoirs',
                  ninja: {
                    name: 'reservoirs_details_history_reservoir_list',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        name: 'reservoirs.subscription-details',
        path: '/details/:resId/subscriptions/:sourceId',
        props: parseProps(['resId', 'sourceId']),
        meta: {
          title: 'Subscription details',
          ninja: {
            name: 'reservoirs_subscription_details',
          },
        },
      },
      {
        name: 'reservoirs.history-details',
        path: '/details/:resId/history-details',
        props: parseProps(['resId', 'historyId', 'sourceId']),

        meta: {
          title: 'Reservoir details | History',
          ninja: {
            name: 'reservoirs_details_history',
          },
        },
        children: [
          {
            name: 'reservoirs.history-details.subscription',
            path: 'subscription/:sourceId',
            props: parseProps(['resId', 'sourceId']),
            meta: {
              ninja: {
                name: 'reservoirs_details_history_subscription_details',
              },
            },
          },
          {
            name: 'reservoirs.history-details.access',
            path: 'access',
            props: parseProps(['resId']),
            meta: {
              ninja: {
                name: 'reservoirs_details_history_access_details',
              },
            },
          },
          {
            name: 'reservoirs.history-details.reservoir-change',
            path: ':historyId/reservoir',
            props: parseProps(['resId', 'historyId']),
            meta: {
              ninja: {
                name: 'reservoirs_details_history_reservoir_details',
              },
            },
          },
          {
            name: 'reservoirs.history-details.custom-request',
            path: ':historyId/custom',
            props: parseProps(['resId', 'historyId']),
            meta: {
              ninja: {
                name: 'reservoirs_details_history_custom_request_details',
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'reservoirs.subscribe-to-sources',
    path: '/subscribe/:resId',
    props: parseProps(['resId']),
    meta: {
      title: 'Subscribe to sources',
      ninja: {
        name: 'reservoirs_subscribe_to_sources',
      },
    },
  },
  {
    name: 'reservoirs.edit-source-subscriptions',
    path: '/subscribe/:resId/:sourceId?',
    props: parseProps(['resId', 'sourceId']),
    meta: {
      title: 'Edit source subscriptions',
      ninja: {
        name: 'reservoirs_edit_source_subscriptions',
      },
    },
  },
  {
    name: 'reservoirs.subscribe-to-sources-confirmation',
    path: '/subscribe/:resId/confirmation',
    props: parseProps(['resId']),
    meta: {
      title: 'Subscribe to sources - confirmation',
      ninja: {
        name: 'reservoirs_subscribe_to_sources_confirmation',
      },
    },
  },
] as ConsoleRouteDefinition[];
