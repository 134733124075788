import { createVuetify } from 'vuetify';
import { md2 as blueprint } from 'vuetify/blueprints';
import * as directives from 'vuetify/directives';
import { aliases } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';
import { themes } from '@console/theme';
import '@console/theme/styles/vuetify.scss';
import { ThemeName } from '@console/theme/types';
import { isDark, setCustomColors } from '@console/theme/useThemes';
import DayJsAdapter from '@date-io/dayjs';
import '@mdi/font/css/materialdesignicons.min.css';

setCustomColors();

export function getVuetify(theme: ThemeName) {
  return createVuetify({
    blueprint,
    directives,

    date: {
      adapter: DayJsAdapter,
    },

    defaults: {
      global: {
        ripple: false,
      },

      VBtn: {
        class: ['border-radius-root', 'text-none'],
        variant: 'flat',
      },
      VCard: {
        class: ['border-radius-root'],
      },
      VChip: {
        class: ['border-radius-root'],
        variant: 'flat',
      },
      VDialog: {
        elevation: 0,
        class: ['border-radius-large'],
      },
      VMenu: {
        elevation: 2,
        // class: ['surface2', 'on-surface--text'],

        VList: {
          class: ['surface2', 'on-surface--text'],
        },
      },
      VRow: {
        // class: [''],
        noGutters: true,
      },
      VSheet: {
        class: ['border-radius-root'],
      },
      VSnackbar: {
        elevation: 3,
        class: ['inverse-surface', 'on-inverse-surface--text'],
      },
      VTabs: {
        sliderColor: 'primary',
      },
      VTooltip: {
        class: ['border-radius-root', 'inverse-surface', 'on-inverse-surface--text'],
        style: 'opacity: 1',
      },
    },

    icons: {
      aliases: {
        ...aliases,
        dropdown: 'mdi-chevron-down',
        error: 'mdi-alert-outline',
        info: 'mdi-information-outline',
        warning: 'mdi-exclamation',
      },
    },
    theme: {
      defaultTheme: isDark() ? 'dark' : 'light',
      themes: {
        light: {
          dark: false,
          colors: themes[theme].light,
        },
        dark: {
          dark: true,
          colors: themes[theme].dark,
        },
      },

      // options: {
      //   customProperties: false,
      // },
    },
    display: {
      thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1440 + 16 + 1, // handle 1440 screens (macbook pro 13inch) - 16 is scrollBarWidth
      },
    },
  });
}
