import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    name: 'root.home',
    path: '/',
    meta: {
      ninja: {
        name: 'home_console',
      },
    },
  },
  {
    name: 'root.user-profile',
    path: '/user-profile',
    meta: {
      ninja: {
        name: 'profile_page',
      },
    },
  },
  {
    name: 'root.global-search',
    path: '/global-search',
  },
] as ConsoleRouteDefinition[];
