import { ConsoleRouteConfig, bindAppRoutes } from '@console/router';

export default bindAppRoutes('sources', [
  {
    name: 'sources.root',
  },
  {
    name: 'sources.about',
    component: () => import('../pages/SourcesAboutPage.vue'),
  },
  {
    name: 'sources.list',
    component: () => import('../pages/SourcesListPage/SourcesListPage.vue'),
  },
  {
    name: 'sources.data-collection.create-hydra-stream',
    component: () => import('../pages/CreateHydraStream/CreateHydraStreamPage.vue'),
  },
  {
    name: 'sources.field-details',
    component: () => import('../pages/FieldDetailsPage/FieldDetailsPage.vue'),
  },
  {
    name: 'sources.field-details.details',
    component: () => import('../pages/FieldDetailsPage/FieldAdditionalDetailsPage.vue'),
  },
  {
    name: 'sources.field-details.subscriptions',
    component: () => import('../pages/FieldDetailsPage/FieldDetailsSubscriptionsView.vue'),
  },
  {
    name: 'sources.field-details.changelog',
    component: () => import('../pages/FieldDetailsPage/FieldDetailsChangelogView.vue'),
  },
  {
    name: 'sources.field-details.referenced-by',
    component: () => import('../pages/FieldDetailsPage/FieldDetailsReferencedByView.vue'),
  },
  {
    name: 'sources.source-details',
    component: () => import('../pages/SourceDetailsPage/SourceDetailsPage.vue'),
  },
  {
    name: 'sources.source-details.fields',
    component: () => import('../pages/SourceDetailsPage/SourceFieldsView.vue'),
  },
  {
    name: 'sources.source-details.details',
    component: () => import('../pages/SourceDetailsPage/SourceDetails/SourceDetails.vue'),
  },
  {
    name: 'sources.source-details.hydra-details',
    component: () => import('../pages/SourceDetailsPage/SourceDetails/SourceDetailsHydraDetailsView.vue'),
  },
  {
    name: 'sources.source-details.lazarus-details',
    component: () => import('../pages/SourceDetailsPage/SourceDetails/SourceDetailsLazarusDetailsView.vue'),
  },
  {
    name: 'sources.source-details.subscriptions',
    component: () => import('../pages/SourceDetailsPage/SourceDetails/SourceDetailsSubscriptionsView.vue'),
  },
  {
    name: 'sources.source-details.changelog',
    component: () => import('../pages/SourceDetailsPage/SourceDetails/SourceDetailsChangelogView.vue'),
  },
  {
    name: 'sources.offline-documentation.management',
    component: () => import('../pages/OfflineDocumentationManagementPage/OfflineDocumentationManagementPage.vue'),
  },
  {
    name: 'user-behaviour.matrices',
    component: () => import('../pages/UserBehaviour/MatricesListPage/MatricesListPage.vue'),
  },
  {
    name: 'user-behaviour.matrix-details',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsPage.vue'),
  },
  {
    name: 'user-behaviour.matrix-details.events',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsEventsView.vue'),
  },
  {
    name: 'user-behaviour.matrix-details.properties',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsPropertiesView.vue'),
  },
  {
    name: 'user-behaviour.matrix-details.releases',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsReleasesView.vue'),
  },
  {
    name: 'user-behaviour.matrix-details.unreleased-changes',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsUnreleasedChanges.vue'),
  },
  {
    name: 'user-behaviour.matrix-details.statistics',
    component: () => import('../pages/UserBehaviour/MatrixDetailsPage/MatrixDetailsStatisticsView.vue'),
  },
  {
    name: 'user-behaviour.event-details',
    component: () => import('../pages/UserBehaviour/EventDetailsPage/EventDetailsPage.vue'),
  },
  {
    name: 'user-behaviour.event-details.trackers',
    component: () => import('../pages/UserBehaviour/EventDetailsPage/EventDetailsTrackersView.vue'),
  },
  {
    name: 'user-behaviour.event-details.properties',
    component: () => import('../pages/UserBehaviour/EventDetailsPage/EventDetailsPropertiesView.vue'),
  },
  {
    name: 'user-behaviour.event-details.history',
    component: () => import('../pages/UserBehaviour/EventDetailsPage/EventDetailsHistoryView.vue'),
  },
  {
    name: 'user-behaviour.event-details.statistics',
    component: () => import('../pages/UserBehaviour/EventDetailsPage/EventDetailsStatisticsView.vue'),
  },
  {
    name: 'user-behaviour.property-details',
    component: () => import('../pages/UserBehaviour/PropertyDetailsPage/PropertyDetailsPage.vue'),
  },
  {
    name: 'user-behaviour.property-details.trackers',
    component: () => import('../pages/UserBehaviour/PropertyDetailsPage/PropertyDetailsTrackersView.vue'),
  },
  {
    name: 'user-behaviour.property-details.events',
    component: () => import('../pages/UserBehaviour/PropertyDetailsPage/PropertyDetailsEventsView.vue'),
  },
  {
    name: 'user-behaviour.release-details',
    component: () => import('../pages/UserBehaviour/ReleaseDetailsPage/ReleaseDetailsPage.vue'),
  },
  {
    name: 'user-behaviour.create',
    component: () => import('../pages/UserBehaviour/CreatePage/CreatePage.vue'),
  },

  {
    name: 'user-behaviour.liveview-list',
    component: () => import('../pages/Liveview/LiveviewList.vue'),
  },
  {
    name: 'user-behaviour.liveview-create',
    component: () => import('../pages/Liveview/LiveviewCreate/LiveviewCreate.vue'),
  },
  {
    name: 'user-behaviour.liveview-details',
    component: () => import('../pages/Liveview/LiveviewDetails.vue'),
  },
] as ConsoleRouteConfig[]);
