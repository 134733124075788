<template>
  <v-row class="px-16 py-15 mx-16 mb-10">
    <v-col>
      <div class="text-body-2 mt-3 mb-4 mr-16" data-testid="service-description">
        <v-alert type="info" icon="mdi-cogs" class="text-on-info">This product is still in development. Come back later! </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ConsoleProduct } from '@console/types';

export default defineComponent({
  name: 'ConsoleInDevelopment',
  props: {
    product: {
      type: Object as PropType<ConsoleProduct>,
    },
  },
});
</script>
