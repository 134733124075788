import { UserRole } from '@console/types';

export const ROLE_DISPLAY_NAMES = {
  [UserRole.ProjectOwner]: 'Project Owner',
  [UserRole.ResourceManager]: 'Resource Manager',
  [UserRole.ReservoirUser]: 'Reservoir User',

  [UserRole.DPO]: 'DPO',
  [UserRole.Ambassador]: 'Ambassador',
  [UserRole.MetricsOwner]: 'Metrics Owner',
  [UserRole.NewsEditor]: 'News Editor',
};

export const ROLE_DESCRIPTIONS = {
  [UserRole.ProjectOwner]: 'Assigns roles within the Data Project. Holds the overall responsibility for the Data Project.',
  [UserRole.ResourceManager]:
    'Manages (create, edit, review) any resource (reservoir, data source, experiment, etc) within the Data Project.',
  [UserRole.ReservoirUser]: 'Has access to the reservoir’s data and publishes using the /out folder.',
};
