/**
 * Common setup shared between all apps. Must be called by all apps
 */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
// import Vue from 'vue';
// import VueMeta from 'vue-meta';
// import { IS_DEV } from '@console/constants/config';
import { setupNinja } from './ninja';

setupNinja();
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// Vue.config.productionTip = false;
// Vue.config.performance = IS_DEV;
// Vue.config.devtools = IS_DEV;

// Vue.use(VueMeta);
