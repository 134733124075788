import Color from 'color';
import { copyObject } from '@console/utils';
import { blendColors, wrapColor } from './colorUtils';
import type {
  BackgroundsTheme,
  ColorConfig,
  ColorsTheme,
  ConsoleTheme,
  NeutralColorTheme,
  NeutralVariantColorTheme,
  SurfacesTheme,
  ThemeColorPalette,
  ThemeCustomPalette,
  ThemeNeutralPalette,
  ThemeVariation,
} from './types';

type ThemeOptions = {
  variation: ThemeVariation;
  colorPalette: ThemeColorPalette;
  neutralPalette: ThemeNeutralPalette;
  customColors?: ThemeCustomPalette;
  overrides?: Record<string, string>;
};

export const colorRoles = {
  light: {
    base: 'p40',
    on: 'p100',
    container: 'p90',
    'on-container': 'p10',
  },
  dark: {
    base: 'p80',
    on: 'p20',
    container: 'p30',
    'on-container': 'p90',
  },
};
const neutralRoles = {
  neutral: {
    light: {
      background: 'p99',
      on: 'p5',
      surface: 'p99',
      'on-surface': 'p10',
    },
    dark: {
      background: 'p5',
      on: 'p90',
      surface: 'p10',
      'on-surface': 'p90',
    },
  },
  neutralVariant: {
    light: {
      'surface-variant': 'p90',
      on: 'p30',
      outline: 'p50',
      'outline-variant': 'p80',
    },
    dark: {
      'surface-variant': 'p30',
      on: 'p80',
      outline: 'p60',
      'outline-variant': 'p30',
    },
  },
};
const surfaceBlendOpacities = [0.05, 0.08, 0.11, 0.12, 0.14];

/**
 * Generate all surfaces, based on the defined list of opacities.
 * surface = base_surface + (% blended-color)
 */
export function generateSurfaces(baseColor: string, blendedColor: string) {
  return surfaceBlendOpacities.reduce((acc, amount, i) => {
    acc[`surface${i + 1}`] = wrapColor(blendColors(baseColor, blendedColor, amount));

    return acc;
  }, {}) as SurfacesTheme;
}

export function generateNeutrals(
  neutralPalette: ThemeNeutralPalette,
  variation: ThemeVariation
): NeutralColorTheme & NeutralVariantColorTheme {
  const neutrals: NeutralColorTheme = {
    background: wrapColor(neutralPalette.neutral[neutralRoles.neutral[variation].background]),
    'on-background': wrapColor(neutralPalette.neutral[neutralRoles.neutral[variation].on]),
    surface: wrapColor(neutralPalette.neutral[neutralRoles.neutral[variation].surface]),
    'on-surface': wrapColor(neutralPalette.neutral[neutralRoles.neutral[variation]['on-surface']]),
  };
  const neutralVariant: NeutralVariantColorTheme = {
    outline: wrapColor(neutralPalette.neutralVariant[neutralRoles.neutralVariant[variation].outline]),
    'outline-variant': wrapColor(neutralPalette.neutralVariant[neutralRoles.neutralVariant[variation]['outline-variant']]),
    'surface-variant': wrapColor(neutralPalette.neutralVariant[neutralRoles.neutralVariant[variation]['surface-variant']]),
    'on-surface-variant': wrapColor(neutralPalette.neutralVariant[neutralRoles.neutralVariant[variation].on]),
  };

  return {
    ...neutrals,
    ...neutralVariant,
  };
}

/**
 * Extracts specific color percentages from the palette
 */
export function generateColorScheme(colorPalette: ThemeColorPalette, color: string, variation: ThemeVariation) {
  return Object.keys(colorRoles[variation]).reduce<ColorConfig>(
    (acc, key) => {
      return {
        ...acc,
        [key]: Color(colorPalette[color][colorRoles[variation][key]])?.hex(),
      };
    },
    {
      base: '',
      on: '',
      container: '',
      'on-container': '',
    }
  );
}

/**
 * Generate base colors
 */
export function generateColors(colorPalette: ThemeColorPalette, variation: ThemeVariation) {
  return Object.keys(colorPalette).reduce((acc, color) => {
    const scheme = generateColorScheme(colorPalette, color, variation);
    const key = String(color);

    acc[color] = wrapColor(scheme.base);
    acc[`on-${key}`] = wrapColor(scheme.on);
    acc[`${key}-container`] = wrapColor(scheme.container);
    acc[`on-${key}-container`] = wrapColor(scheme['on-container']);

    return acc;
  }, {} as ColorsTheme);
}

export function generateCustomColors(customPalette?: ThemeCustomPalette) {
  if (!customPalette) {
    return {};
  }

  return Object.keys(customPalette).reduce((acc, color) => {
    acc[color] = wrapColor(customPalette[color]);

    return acc;
  }, {} as ColorsTheme);
}

export function generateBackgrounds(neutralPalette: ThemeNeutralPalette, variation: ThemeVariation) {
  const keys = Object.keys(neutralPalette.neutral);
  let values = Object.values(neutralPalette.neutral);

  if (variation === 'light') {
    values = values.reverse();
  }

  return keys.reduce((acc, k, i) => {
    return {
      ...acc,
      [k.replace('p', 'background')]: wrapColor(values[i]),
    };
  }, {}) as BackgroundsTheme;
}

export function generateTheme(props: ThemeOptions): ConsoleTheme {
  const opts = copyObject(props);

  const colors = generateColors(opts.colorPalette, opts.variation);
  const customColors = generateCustomColors(opts.customColors);
  const neutrals = generateNeutrals(opts.neutralPalette, opts.variation);
  const surfaces = generateSurfaces(
    opts.overrides?.surface ? opts.overrides.surface : neutrals.surface,
    opts.overrides?.['surface-overlay'] ? opts.overrides['surface-overlay'] : opts.customColors!['surface-overlay']
  );

  const theme: ConsoleTheme = {
    ...colors,
    ...customColors,
    ...neutrals,
    ...surfaces,
  };

  if (props.overrides) {
    Object.entries(props.overrides).forEach(([k, v]) => {
      if (k in theme) {
        theme[k] = wrapColor(v);
      }
    });
  }

  return theme;
}
