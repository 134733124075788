import { getStorageKey } from '@console/utils';
import { MaybeRefOrGetter, UseStorageOptions, useLocalStorage } from '@vueuse/core';

export function useSettingsManager<T extends string | number | boolean | object | null>(
  key: string,
  initialValue: MaybeRefOrGetter<T>,
  options: UseStorageOptions<T> = {}
) {
  return useLocalStorage(getStorageKey(key), initialValue, options);
}
