import { colorRoles, generateTheme } from '../generateTheme';
import { ThemeDefinition, ThemeName } from '../types';
import * as blue from './palettes/blue';
import * as core from './palettes/core';
import * as green from './palettes/green';
import * as pink from './palettes/pink';
import * as purple from './palettes/purple';
import { shadesPalette } from './shades';

export const themes = [core, blue, green, pink, purple].reduce(
  (acc, palette) => {
    return {
      ...acc,
      [palette.name]: {
        light: generateTheme({
          variation: 'light',
          colorPalette: palette.colorPalette,
          neutralPalette: palette.neutralPalette,
          customColors: {
            black: palette.colorPalette.primary.p0,
            'on-black': palette.colorPalette.primary.p100,
            white: palette.colorPalette.primary.p100,
            'on-white': palette.colorPalette.primary.p0,

            'inverse-surface': palette.neutralPalette.neutral.p20,
            'on-inverse-surface': palette.neutralPalette.neutral.p95,
            'inverse-surface-variant': palette.neutralPalette.neutral.p90,
            'on-inverse-surface-variant': palette.neutralPalette.neutral.p10,

            'inverse-primary': palette.colorPalette.primary.p80,
            'surface-overlay': shadesPalette.surfaceOverlay,

            neutral: shadesPalette.white,
            'on-neutral': shadesPalette.black,

            'core-primary': core.colorPalette.primary[colorRoles.light.base],
            'blue-primary': blue.colorPalette.primary[colorRoles.light.base],
            'green-primary': green.colorPalette.primary[colorRoles.light.base],
            'pink-primary': pink.colorPalette.primary[colorRoles.light.base],
            'purple-primary': purple.colorPalette.primary[colorRoles.light.base],
          },
          overrides: {
            background: shadesPalette.background,
            surface: shadesPalette.surface,
          },
        }),

        dark: generateTheme({
          variation: 'dark',
          colorPalette: palette.colorPalette,
          neutralPalette: palette.neutralPalette,
          customColors: {
            black: palette.colorPalette.primary.p0,
            'on-black': palette.colorPalette.primary.p100,
            white: palette.colorPalette.primary.p100,
            'on-white': palette.colorPalette.primary.p0,

            'inverse-surface': palette.neutralPalette.neutral.p90,
            'on-inverse-surface': palette.neutralPalette.neutral.p10,
            'inverse-surface-variant': palette.neutralPalette.neutral.p95,
            'on-inverse-surface-variant': palette.neutralPalette.neutral.p20,

            'inverse-primary': palette.colorPalette.primary.p40,
            'surface-overlay': shadesPalette.white,

            neutral: shadesPalette.black,
            'on-neutral': shadesPalette.white,

            'core-primary': core.colorPalette.primary[colorRoles.dark.base],
            'blue-primary': blue.colorPalette.primary[colorRoles.dark.base],
            'green-primary': green.colorPalette.primary[colorRoles.dark.base],
            'pink-primary': pink.colorPalette.primary[colorRoles.dark.base],
            'purple-primary': purple.colorPalette.primary[colorRoles.dark.base],
          },
          overrides: {},
        }),
      },
    };
  },
  {} as Record<ThemeName, ThemeDefinition>
);
