import { DEBOUNCE_TIMEOUT, EMPTY_PH, SLACK_CHANNEL_URL } from '@console/constants';
import { BrandCountry } from '@console/types';

// Re-export utils so we use single entry-point in the project
export * from './src/array';
export * from './src/async';
export * from './src/country-codes';
export * from './src/date';
export * from './src/form-validators';
export * from './src/number';
export * from './src/object';
export * from './src/query';
export * from './src/string';
export * from './src/tags';
export * from './src/uuid';
export * from './src/reservoir';
export * from './src/storage';
export * from './src/laquesis';
export * from './src/user-behaviour';

export function parseBrandCountries(obj: { [brand: string]: string[] }): BrandCountry[] {
  // convert {olx: ['ba', 'hr']} => [{brand: olx, code: ba, text: 'olx ba'}, {{brand: olx, code: hr, text: 'olx hr'}}]
  // "text" is for text and v-for key. It should be unique
  // can use Object.entries
  if (!obj || !Object.keys(obj).length) {
    return [];
  }

  const allBrandCountries = Object.entries(obj).reduce((acc, [brand, countries]) => {
    const currentbrandcountry = countries.map(code => ({
      brand,
      country: code,
      key: `${brand}-${code}`,
      text: `${brand.toUpperCase()} ${code.toUpperCase()}`,
    }));
    return [...acc, ...currentbrandcountry];
  }, [] as Array<BrandCountry>);

  return allBrandCountries;
}

/**
 * Setups visibility change event handling
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 *
 * @param {Function} handler
 * @returns {Function}
 */
export function setupVisibilityChange(handler) {
  let hidden;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  function handleVisibilityChange() {
    handler(document[hidden]);
  }

  // Handle page visibility change
  document.addEventListener(visibilityChange, handleVisibilityChange, false);

  return () => document.removeEventListener(visibilityChange, handleVisibilityChange, false);
}

/**
 * Formats number using Intl.NumberFormat
 *
 * @export
 * @param {Number} value
 * @param {Object} [options]
 * @param {Boolean} [options.sign] - Is sign always shown
 * @param {Boolean} [options.percent] - Display with percentage sign
 * @param {Number} [options.fractionDigits=3] - Number of fraction digits
 * @param {*} [options.fallbackValue='—'] - If value is not a number, what to return
 * @returns {String}
 */
export function formatNumber(value, options: { sign?: boolean; percent?: boolean; fractionDigits?: number; fallbackValue?: string } = {}) {
  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: options.fractionDigits || 3,
    style: 'decimal',
    signDisplay: options.sign ? 'exceptZero' : 'auto',
  });

  return typeof value === 'number' ? `${formatter.format(value)}${options.percent ? '%' : ''}` : options.fallbackValue || EMPTY_PH;
}

/**
 * Update elements in a subarray which match a certain condition
 * @param {any[]} parent The parent array
 * @param {Function} cond Function to distinguish if element is part of subarray
 * @param {any[]} items The values of the subarray
 * @returns {any[]}
 */
export function updateSubarray(parent, cond, items) {
  let innerIndex = 0;

  const result = parent.reduce((pv, cv) => {
    if (cond(cv)) {
      // if items[innerIndex] is null -> item is deleted
      if (items[innerIndex]) {
        pv.push(items[innerIndex]);
      }
      innerIndex += 1;
    } else {
      pv.push(cv);
    }
    return pv;
  }, []);

  // new items are always the last elements
  if (innerIndex < items.length) {
    return [...result, ...items.slice(innerIndex, items.length)];
  }

  return result;
}
/**
 * Generates HTML for Slack support channel
 * @param {String} text
 * @returns {String}
 */
export function getSlackLink(text = '#gl-laquesis-support', classes = []) {
  return `<a href="${SLACK_CHANNEL_URL}" target="blank" ref="noopener" class="${classes.join(' ')}">${text}</a>`;
}

/**
 * Joins array values with a twist.
 * IF length == 1 => that element.
 * IF length == 2 => el[0] + conj + el[1].
 * ELSE el[0], el[1], ... conj el[N-1].
 *
 * @param   {string[]}  arr          - The values
 * @param   {string}    [conj='or']  - Conjunction between the last and second-to-last element
 * @param   {Function}  [wrap]       - Function to modify each element
 *
 * @return  {string}
 */
export function lexicalJoin(arr, conj = 'or', wrap = e => e) {
  if (!arr?.length) {
    return '';
  }
  if (arr.length === 1) {
    return wrap(arr[0]);
  }
  if (arr.length === 2) {
    return arr.map(wrap).join(` ${conj} `);
  }

  return `${arr
    .slice(0, arr.length - 1)
    .map(wrap)
    .join(', ')} ${conj} ${wrap(arr[arr.length - 1])}`;
}

/**
 * Naive throttle implementation to limit mouse event frequency trigger
 */
export function throttle(fn, milliseconds = 0, context = null) {
  const baseFunction = fn;
  let lastEventTimestamp = 0;
  const limit = milliseconds;

  return function throttled(...args) {
    const now = Date.now();

    if (!lastEventTimestamp || now - lastEventTimestamp >= limit) {
      lastEventTimestamp = now;
      baseFunction.apply(context, args);
    }
  };
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing. The function also has a property 'clear'
 * that is a function which will clear the timer to prevent previously scheduled executions.
 *
 * @source underscore.js
 * @see http://unscriptable.com/2009/03/20/debouncing-javascript-methods/
 * @param {Function} function to wrap
 * @param {Number} timeout in ms (`350`)
 * @param {Boolean} whether to execute at the beginning (`false`)
 * @api public
 */
export function debounce(func: (...args) => any, wait = DEBOUNCE_TIMEOUT, immediate = false) {
  let timeout;
  let args;
  let context;
  let timestamp;
  let result;

  function later() {
    const last = Date.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        context = null;
        args = null;
      }
    }
  }

  const debounced = function debounceWrapper(...innerArgs) {
    context = this;
    args = innerArgs;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
      context = null;
      args = null;
    }

    return result;
  };

  debounced.clear = function debounceClear() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  debounced.flush = function debounceFlush() {
    if (timeout) {
      result = func.apply(context, args);
      context = null;
      args = null;

      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
}

/**
 * Format bytes to kylobytes, megabytes, gigabytes
 *
 * formatBytes(1024);       // 1 KB
 * formatBytes(1234);       // 1.21 KB
 * formatBytes(1234, 3);    // 1.205 KB
 *
 * @param {number} a bytes
 * @param {number} b decimals
 */

export function formatBytes(a, b = 2) {
  if (a === 0 || a === null) {
    return '0 Bytes';
  }
  const c = b < 0 ? 0 : b;
  const d = Math.floor(Math.log(a) / Math.log(1024));

  return `${(a / 1024 ** d).toFixed(c)} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
}

export function getMainAreaElement() {
  const elements = document.getElementsByClassName('v-main__wrap');
  return elements.length > 0 ? (elements[0] as HTMLElement) : window;
}
