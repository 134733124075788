<template>
  <v-app-bar elevation="0" class="top-navigation text-on-black" :class="impersonatedUser ? 'bg-impersonating' : 'bg-black'">
    <v-app-bar-nav-icon class="shrink mr-2">
      <ConsoleMenu base="/" :notifications-count="notificationsCount" />
    </v-app-bar-nav-icon>
    <v-app-bar-title class="flex-0-0">
      <a :href="rootHomeLink" class="text-on-black text-subtitle-2">
        <div>The Console</div>
        <div class="text-caption-3">by Data Services</div>
      </a>
    </v-app-bar-title>
    <GlobalSearchBox />

    <template #append>
      <v-row align="center">
        <v-spacer />
        <v-col class="mr-4">
          <CustomerUnitSelector :disabled="disableCuSelector" @change="$emit('change', $event)" />
        </v-col>
        <v-col class="mr-4"><v-btn @click="onFeedbackClick">Feedback</v-btn></v-col>
        <v-col class="mr-4"><NotificationsMenu :notifications-count="notificationsCount" /></v-col>
        <v-col><UserMenu /></v-col>
      </v-row>
    </template>
    <FeedbackModal ref="feedbackModalRef" />
  </v-app-bar>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useModal, useSnackbar } from '@console/composables';
import { useUser } from '@console/composables/useUser/useUser';
import { trackEvent } from '@console/core/ninja';
import { sendFeedback } from '@console/core/service';
import { getAppRouteHref } from '@console/router';
import { GeneralErrorResponse } from '@console/types';
import { handleAsync } from '@console/utils';
import { ConsoleMenu } from '../ConsoleMenu';
import { CustomerUnitSelector } from '../CustomerUnitSelector';
import { FeedbackModal } from '../FeedbackModal';
import GlobalSearchBox from './GlobalSearchBox.vue';
import NotificationsMenu from './NotificationsMenu.vue';
import UserMenu from './UserMenu.vue';

export default defineComponent({
  name: 'TopNavigation',
  components: {
    ConsoleMenu,
    FeedbackModal,
    GlobalSearchBox,
    UserMenu,
    NotificationsMenu,
    CustomerUnitSelector,
  },

  props: {
    disableCuSelector: {
      type: Boolean,
      required: false,
    },
    notificationsCount: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const { impersonatedUser } = useUser();
    const { closeModal, openModal } = useModal();
    const { openSnack } = useSnackbar();

    const feedbackModalRef = ref(null);

    const rootHomeLink = computed(() => getAppRouteHref('-root-', { name: 'root.home' }));

    const onFeedbackClick = () => {
      openModal(feedbackModalRef.value, {
        onSubmit: async data => {
          const [err] = await handleAsync<GeneralErrorResponse>(sendFeedback(data));

          if (err) {
            openSnack(err.errors[0] || 'Something went wrong');
          } else {
            openSnack('Thank you for your feedback. We will contact you when we review your suggestion.');
            trackEvent('submit_feedback');
          }
          closeModal();
        },
      });
    };

    return {
      feedbackModalRef,
      impersonatedUser,
      onFeedbackClick,
      rootHomeLink,
    };
  },
});
</script>

<style lang="scss">
.top-navigation {
  position: fixed;
  top: 0;
  z-index: 1001 !important;

  padding-left: 16px;
  padding-right: 16px;

  &.bg-impersonating {
    background-color: #93000a !important; // the dark variant of error-container color
  }
}
</style>
