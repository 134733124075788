import type { ColorTones, ThemeName } from '../../types';
import { ThemeColorPalette, ThemeNeutralPalette } from '../../types';
import { mergePalettes } from '../mergePalettes';
import { shadesPalette } from '../shades';
import * as corePalette from './core';

export const name: ThemeName = 'green';

export const primary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5FFF5',
  p95: '#C0FFD7',
  p90: '#70FCB5',
  p80: '#50DF9A',
  p70: '#28C281',
  p60: '#00A56A',
  p50: '#008857',
  p40: '#006D44',
  p30: '#005232',
  p20: '#003921',
  p10: '#002111',
  p5: '#001509',
  p25: '#00452A',
  p35: '#005F3B',
  p98: '#E9FFEE',
};

export const secondary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5FFF5',
  p95: '#DFF7E4',
  p90: '#D0E8D6',
  p80: '#B5CCBB',
  p70: '#9AB1A0',
  p60: '#7F9686',
  p50: '#667C6D',
  p40: '#4E6355',
  p30: '#374B3E',
  p20: '#203529',
  p10: '#0B1F14',
  p5: '#02150A',
  p25: '#2B4033',
  p35: '#42574A',
  p98: '#E9FFEE',
};

export const tertiary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FDFCFF',
  p95: '#EBF1FF',
  p90: '#D4E3FF',
  p80: '#A5C8FF',
  p70: '#72ADFF',
  p60: '#5192E6',
  p50: '#3278CA',
  p40: '#005FAF',
  p30: '#004786',
  p20: '#00315F',
  p10: '#001C3A',
  p5: '#001128',
  p25: '#003C72',
  p35: '#00539A',
  p98: '#F9F9FF',
};

export const neutral: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5F5F5',
  p95: '#EFF1F1',
  p90: '#E1E3E3',
  p80: '#C4C7C7',
  p70: '#A9ACAC',
  p60: '#8E9192',
  p50: '#747878',
  p40: '#5C5F5F',
  p30: '#444748',
  p20: '#2E3132',
  p10: '#191C1D',
  p5: '#0E1212',
  p15: '#232626',
  p25: '#393C39',
  p35: '#505350',
  p98: '#F8FAF5',
};

export const neutralVariant: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,
  p99: '#F6FFF5',
  p95: '#EAF3EA',
  p90: '#DCE5DC',
  p80: '#C0C9C0',
  p70: '#A5ADA5',
  p60: '#8A938B',
  p50: '#707972',
  p40: '#58605A',
  p30: '#404942',
  p20: '#2A322D',
  p10: '#151D18',
  p5: '#0B130E',
  p25: '#353D37',
  p35: '#4C544E',
  p98: '#F3FCF3',
};

export const colorPalette: ThemeColorPalette = mergePalettes(corePalette.colorPalette, {
  primary,
  secondary,
  tertiary,
});

export const neutralPalette: ThemeNeutralPalette = mergePalettes(corePalette.neutralPalette, {
  neutral,
  neutralVariant,
});
