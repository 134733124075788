import { CustomRequestTypes, ReservoirClassificationType } from '@console/types';

export const RESERVOIR_EXPIRATION_DURATIONS = [
  { value: 0, title: 'None' },
  { value: 31, title: '1 month' },
  { value: 93, title: '3 months' },
  { value: 186, title: '6 months' },
  { value: 279, title: '9 months' },
  { value: 372, title: '12 months' },
  { value: 434, title: '14 months' },
  { value: 1116, title: '36 months' },
];

export const CLASSIFICATION_TYPES = [
  {
    value: ReservoirClassificationType.Personal,
    text: 'Personal',
  },
  {
    value: ReservoirClassificationType.PersonalOperational,
    text: 'Personal operational',
  },
  {
    value: ReservoirClassificationType.NonPersonal,
    text: 'Non personal',
  },
];

export const CUSTOM_REQUEST_TYPES = [
  {
    value: CustomRequestTypes.SystemAccess,
    text: 'System access',
  },
  {
    value: CustomRequestTypes.Other,
    text: 'Other',
  },
];
