import type { ColorTones, ThemeName } from '../../types';
import { ThemeColorPalette, ThemeNeutralPalette } from '../../types';
import { mergePalettes } from '../mergePalettes';
import { shadesPalette } from '../shades';
import * as corePalette from './core';

export const name: ThemeName = 'pink';

export const primary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5F5F5',
  p95: '#FFECF4',
  p90: '#FFD8EC',
  p80: '#FFAEDF',
  p70: '#FF7DD4',
  p60: '#E65CBD',
  p50: '#C741A1',
  p40: '#A82487',
  p30: '#87006C',
  p20: '#60004B',
  p10: '#3B002E',

  p5: '#28001E',
  p25: '#73005B',
  p35: '#98117A',
  p98: '#FFF8F9',
};

export const secondary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#FFECF4',
  p90: '#FBD9EA',
  p80: '#DEBECE',
  p70: '#C1A3B3',
  p60: '#A58998',
  p50: '#8A6F7E',
  p40: '#705765',
  p30: '#57404D',
  p20: '#3F2A37',
  p10: '#281521',

  p5: '#1D0B16',
  p25: '#4B3542',
  p35: '#644B59',
  p98: '#FFF8F9',
};

export const tertiary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#F8EDFF',
  p90: '#EDDCFF',
  p80: '#D7BAFF',
  p70: '#C297FF',
  p60: '#AA77F2',
  p50: '#8F5DD5',
  p40: '#7542BA',
  p30: '#5C26A1',
  p20: '#440087',
  p10: '#290055',

  p5: '#1B003C',
  p25: '#501595',
  p35: '#6935AD',
  p98: '#FFF7FF',
};

export const neutral: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5F5F5',
  p95: '#EFF1F1',
  p90: '#E1E3E3',
  p80: '#C4C7C7',
  p70: '#A9ACAC',
  p60: '#8E9192',
  p50: '#747878',
  p40: '#5C5F5F',
  p30: '#444748',
  p20: '#2E3132',
  p15: '#232626',
  p10: '#191C1D',

  p5: '#0E1212',
  p25: '#403A3C',
  p35: '#575153',
  p98: '#FFF8F9',
};

export const neutralVariant: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#FEECF3',
  p90: '#F0DEE5',
  p80: '#D3C2C9',
  p70: '#B7A7AE',
  p60: '#9C8D93',
  p50: '#81737A',
  p40: '#685B61',
  p30: '#4F4449',
  p20: '#382D33',
  p10: '#22191E',

  p5: '#170E13',
  p25: '#43383E',
  p35: '#5B4F55',
  p98: '#FFF8F9',
};

export const colorPalette: ThemeColorPalette = mergePalettes(corePalette.colorPalette, {
  primary,
  secondary,
  tertiary,
});

export const neutralPalette: ThemeNeutralPalette = mergePalettes(corePalette.neutralPalette, {
  neutral,
  neutralVariant,
});
