import { isProxy, toRaw } from 'vue';

/**
 * Use string definition to access nested objects.
 *
 *
 * @param {object} obj
 * @param {string} dotSeparatedKeys
 * @returns {*}
 */
export function getNestedObject<T extends Record<string, any>>(obj: T, dotSeparatedKeys: string): any {
  if (!obj || !dotSeparatedKeys || typeof dotSeparatedKeys !== 'string') {
    return undefined;
  }

  // split on ".", "[", "]", "'", """ and filter out empty elements
  const splitRegex = /[.\[\]'"]/g; // eslint-disable-line no-useless-escape
  const pathArr = dotSeparatedKeys.split(splitRegex).filter(k => k !== '');

  return pathArr.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : undefined), obj);
}

/**
 * Basic copy object implementation - uses to/from JSON (types are lost).
 *
 *
 * @template T
 * @param {T} obj
 * @returns {T}
 */
export function copyObject<T>(obj: T): T {
  let rawData = obj;

  if (isProxy(obj)) {
    rawData = toRaw(obj);
  }

  try {
    if (typeof window.structuredClone === 'function') {
      return structuredClone(rawData);
    }
  } catch {
    //
  }

  return typeof rawData === 'object'
    ? JSON.parse(JSON.stringify(rawData)) // ? { ...obj }
    : rawData;
}

/**
 * Apply object.freeze recursively for each nested object
 * @param {object<any, any>} o
 * @return object
 */
export function deepFreeze<T extends Record<string, any>>(o: T): T {
  Object.freeze(o);

  Object.keys(o).forEach(prop => {
    if (o[prop] !== null && (typeof o[prop] === 'object' || typeof o[prop] === 'function') && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });

  return o;
}

/**
 * Filters null or empty-string elements from object
 *
 *
 * @param {Object} data
 * @returns {Object}
 */
export function filterEmpty(data: Record<string, any>, exclude: string[] = []): Record<string, any> {
  const params: Record<string, any> = {};
  Object.keys(data)
    .filter(key => {
      if (exclude.includes(key)) {
        return !!data[key];
      }

      return ![null, undefined].includes(data[key]);
    })
    .forEach((key: any) => {
      params[key] = data[key];
    });
  return params;
}

/**
 * Picks only subset of properties from the object
 *
 *
 * @template T
 * @template K
 * @param {T} obj
 * @param {...K} keys
 * @returns {Object}
 */
export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = obj[key];
      return acc;
    },
    {} as Pick<T, K>
  );
}
