import { ref } from 'vue';

const active = ref('');

export function useRightPanel() {
  const openRightPanel = (id: string) => {
    active.value = id;
  };
  const closeRightPanel = () => {
    active.value = '';
  };

  return {
    active,
    openRightPanel,
    closeRightPanel,
  };
}
