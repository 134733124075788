export type ConsoleProduct = {
  category?: string;
  children?: Omit<ConsoleProduct, 'children'>[];
  description?: string;
  disabled?: boolean;
  hideInMenu?: boolean;
  homepageDescription?: string;
  icon?: string;
  id: string;
  link?: string;
  owners?: string[];
  showOnHomepage?: boolean;
  text: string;
};

export const CONSOLE_PRODUCTS = {
  // helperItems
  RECENTLY_VISITED: 'recentrly-visited',
  FAVOURITES: 'favourites',
  ALL_SERVICES: 'all-services',
  NEWS_AND_RELEASES: 'news-and-releases',
  // dev items
  NOTIFICATION_CENTER: 'notification-center',

  // products
  ACCESS_MANAGEMENT: 'access-management',
  CATALOG_COSTS_STATS: 'catalog-costs-stats',
  CATALOG_DATA_SOURCES: 'catalog-data-sources',
  CATALOG_DATABASES: 'catalog-databases',
  CATALOG_PUBLISH_DATA: 'catalog-publish-data',
  CATALOG_RESERVOIR_OUT: 'catalog-reservoir-out',
  CATALOG_RESERVOIRS: 'catalog-reservoirs',
  CATALOG_SERVICES: 'catalog-services',
  CATALOG_USER_BEHAVIOUR: 'catalog-user-behaviour',
  CUSTOM_QUERIES: 'custom-queries',
  DAPI: 'dapi',
  DATA_CONSUMPTION: 'data-consumption',
  DATA_INSIGHTS: 'data-insights',
  DATA_PROJECTS: 'data-projects',
  DATA_SOURCES: 'data-sources',
  ETL: 'etl',
  EXPERIMENTS: 'experiments',
  FEATURE_FLAGS: 'feature-flags',
  FREJAML_MACHINE_LEARNING: 'frejaml-machine-learning',
  GLOBAL_PERMISSIONS: 'global-permissions',
  LAQUESIS_SURVEYS: 'laquesis-surveys',
  LAZARUS: 'lazarus',
  METRICS_LIBRARY: 'metrics-library',
  PLAYGROUND: 'playground',
  REALTIME: 'realtime',
  RESERVOIRS: 'reservoirs',
  SURVEYS: 'surveys',
  TABLEAU: 'tableau',

  // additionalItems
  DATA_CULTURE: 'data-culture',
  DOCUMENTATION: 'documentation',
  INTEGRATIONS: 'integrations',
};
