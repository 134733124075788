import * as LD from 'launchdarkly-js-client-sdk';
import { LD_CLIENT_SIDE_ID } from '@console/constants/config';

let ldclient: LD.LDClient = null;

export function loadLaunchDarkly(productId: string) {
  return new Promise((resolve, reject) => {
    if (LD_CLIENT_SIDE_ID) {
      if (ldclient) {
        resolve(ldclient);
      } else {
        ldclient = LD.initialize(LD_CLIENT_SIDE_ID, { kind: 'user', key: `ld-console-${productId}`, name: 'Console' });
        ldclient.on('ready', () => resolve(ldclient));
        ldclient.on('failed', () => reject('LD failed to initialize'));
      }
    } else {
      resolve(null);
    }
  });
}
export function useLDConfig<T = any>(name: string, defaultValue?: T): T {
  if (ldclient) {
    return ldclient.variation(name, defaultValue);
  }

  return defaultValue;
}
