import { PAYMENT_STATUSES } from '@console/constants';
import type { Experiment } from '../experiments/experiments';
import { Channel } from '../general';
import type { ConditionGroup } from './conditions';

export enum MetricType {
  Health = 'health',
  Flow = 'flow',
  Conversion = 'conversion',
  Vitals = 'web_vitals',
  Revenue = 'revenue',
}

export enum MetricState {
  Active = 'active',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
}

export enum MetricSetup {
  UserUser = 'user_user',
  UserEvent = 'user_event',
  UserAll = 'user_all',
  EventAll = 'event_all',
  EventEvent = 'event_event',
  EventUser = 'event_user',
}

export type PaymentStatus = (typeof PAYMENT_STATUSES)[number];

export type MetricMapping = {
  frozen_metric_id: number;
  original_metric_id: number;
  original_metric_state: MetricState;
  original_metric_type: MetricType;
};

type MetricSegmentBase = {
  id: number;
  apply_to_base: boolean;
  apply_to_target: boolean;

  description: string;
  display_name: string;
  name: string;
  customer_unit_id: number;
  product_id: number;
  tags: string[];
  condition_groups: ConditionGroup[];
  experiments?: any[];
  channels?: Channel[];

  force_segment_report_reset?: boolean;
  authinfo?: {
    can_reset: boolean;
    can_save: boolean;
  };
};

type ExperimentMetricBase = {
  description: string;
  display_name: string; // the old metric display name which is being deprecated (new metrics and edited ones from now on, won't have it)
  display_name_1: string; // the new metric display name is based on the metrics naming convention and consists of (up to) two parts
  display_name_2?: string; // the second part of display name is optional because based on metric_setup not all metrics will use both fields
  metric_setup: MetricSetup;
  flow?: { id: number; name: string };
  id: number;
  is_negative: boolean;
  metric_source: string;
  name: string;
  product_id: number;
  customer_unit_id: number;
  tags: string[];
  metric_type: MetricType;
  target_unique?: boolean;
  target_events?: string[];
  target_condition_groups?: ConditionGroup[];
  target_payment_statuses?: PaymentStatus[];
  target_product_names?: string[];
  base?: 'user' | 'event_name';
  base_unique?: boolean;
  base_events?: string[];
  base_condition_groups?: ConditionGroup[];
  base_payment_statuses?: PaymentStatus[];
  base_product_names?: string[];
};

export type MetricSegment = MetricSegmentBase & {
  attached_metrics?: Pick<ExperimentMetricBase, 'id' | 'description' | 'display_name' | 'display_name_1' | 'display_name_2' | 'name'>[];
};

export type AvailableMetricSegment = Partial<MetricSegment> & Required<Pick<MetricSegment, 'id' | 'apply_to_base' | 'apply_to_target'>>;

export type ExperimentMetric = ExperimentMetricBase & {
  experiment_count: number;
  experiments: Array<Pick<Experiment, 'id' | 'display_name' | 'channels' | 'name' | 'status'> & { used_as?: 'primary' | 'conversion' }>;
  // this property is available on metric detail endpoint, and on /data_projects/<data_project_id>/top_primary_metrics
  available_segments?: AvailableMetricSegment[];
  // this property is available on metrics listing endpoint
  attached_segments?: AvailableMetricSegment[];
  author?: string;
  data_source?: string;
  state?: MetricState;
  used_by_experiments?: boolean;
  used_as?: 'primary' | 'conversion';
  original_metric_id?: number;
  is_deprecated?: boolean;

  // postpone dates
  snooze_date?: string;
  creation_date?: string;
  /**
   * Last date the metric was used by experiment or snoozed by user
   * @format YYYY-DD-MM
   */
  last_used?: string | null;

  force_metric_report_reset?: boolean;
  authinfo?: {
    can_save_metric: boolean;
    can_save_health_metric: boolean;
    can_reset: boolean;
    can_snooze: boolean;
  };
};

export type UserFlow = {
  customer_unit_id: number;
  product_id: number;
  id: number;
  name: string;
  description: string;
  metrics: ExperimentMetric[];
  authinfo?: {
    can_delete: boolean;
    can_save: boolean;
  };
};

export type SegmentMapping = {
  frozen_segment_id: number;
  original_segment_id: number;
};

export type MetricsAndSegmentsMapping = {
  metric_mapping: MetricMapping[];
  segment_mapping: SegmentMapping[];
};
