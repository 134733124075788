import { Channel, Survey } from '@console/types';
import { uuid } from '@console/utils';

export const SURVEY_OPERATOR_TYPES = [
  { value: 'equals', text: '=' },
  { value: 'not_equals', text: '!=' },
  { value: 'is_null', text: 'IS NULL', single: true },
  { value: 'is_not_null', text: 'IS NOT NULL', single: true },
  { value: 'greater_than', text: '>', type: 'number' },
  { value: 'less_than', text: '<', type: 'number' },
];

export const SURVEY_PLATFORM = {
  WEB: {
    key: 'web',
    value: [Channel.WebDesktop, Channel.WebMobile],
  },
  APPS: {
    key: 'apps',
    value: [Channel.Android, Channel.iOS],
  },
};

export const SURVEY_FORM_ELEMENTS = {
  SINGLE_INPUT: 'SINGLE_INPUT',
  MULTILINE_INPUT: 'MULTILINE_INPUT',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  RADIO_GROUP: 'RADIO_GROUP',
  RATING: 'RATING',
  STATIC_TEXT: 'STATIC_TEXT',
};

export const SURVEY_FORM_ELEMENTS_DESCRIPTION = {
  [SURVEY_FORM_ELEMENTS.SINGLE_INPUT]: {
    name: 'Single text input',
    description: '',
    showInReport: true,
    // component: SingleInput,
    // previewComponent: PreviewTextInput,
    state: () => ({
      label: '',
      id: uuid(),
      options: [],
      required: false,
    }),
  },
  [SURVEY_FORM_ELEMENTS.MULTILINE_INPUT]: {
    name: 'Textarea',
    description: 'Textarea field for user input',
    showInReport: true,
    // component: MultilineInput,
    // previewComponent: PreviewTextInput,
    state: () => ({
      label: '',
      id: uuid(),
      options: [],
      required: false,
    }),
  },
  [SURVEY_FORM_ELEMENTS.CHECKBOX_GROUP]: {
    name: 'Checkbox group',
    description: 'Multiple choice list',
    showInReport: true,
    // component: CheckboxGroup,
    // previewComponent: PreviewGroup,
    state: (survey: Survey) => ({
      label: '',
      id: uuid(),
      options: [],
      required: false,
      properties: {
        other_id: null,
        random: survey?.random_order,
      },
    }),
  },
  [SURVEY_FORM_ELEMENTS.RADIO_GROUP]: {
    name: 'Radio group',
    description: 'Single choice list',
    showInReport: true,
    // component: RadioGroup,
    // previewComponent: PreviewGroup,
    state: (survey: Survey) => ({
      label: '',
      id: uuid(),
      options: [],
      required: false,
      properties: {
        other_id: null,
        random: survey?.random_order,
      },
    }),
  },

  [SURVEY_FORM_ELEMENTS.RATING]: {
    name: 'Rating/NPS',
    description: '',
    showInReport: true,
    // component: Rating,
    // previewComponent: PreviewGroup,
    state: () => ({
      label: '',
      id: uuid(),
      options: Array.from({ length: 11 }).map((e, i) => ({
        id: uuid(),
        value: i,
        order: i,
      })),
      properties: {
        left_range_border_text: 'Not likely',
        right_range_border_text: 'Very likely',
      },
      required: false,
    }),
  },

  [SURVEY_FORM_ELEMENTS.STATIC_TEXT]: {
    name: 'Static text',
    description: 'Pre-formatted informative text',
    showInReport: false,
    // component: StaticText,
    state: () => ({
      label: '',
      id: uuid(),
      options: [
        {
          id: uuid(),
          order: 0,
          value: '',
        },
      ],
    }),
  },
};

export const SURVEY_RULE_TYPE = {
  NEXT_PAGE: {
    value: 'NEXT_PAGE',
    title: 'Any answer',
  },
  NEXT_PAGE_IF_ONE: {
    value: 'NEXT_PAGE_IF_ONE',
    title: 'One match (OR)',
  },
  NEXT_PAGE_IF_ALL: {
    value: 'NEXT_PAGE_IF_ALL',
    title: 'All match (AND)',
  },
};
