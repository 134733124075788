import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { objectFromQueryString, queryStringFromObject } from '@console/utils';

export function getRouter(routes: RouteRecordRaw[], base: string) {
  return createRouter({
    routes,
    history: createWebHistory(base === '-root-' ? '/' : base),
    parseQuery(queryString) {
      return objectFromQueryString(queryString);
    },
    stringifyQuery(queryObject: any) {
      const string = queryStringFromObject(queryObject);
      return string ? string : '';
    },
    scrollBehavior(to) {
      try {
        if (to.hash && to.name !== 'login' && to.name !== 'sso') {
          let elementToView = document.querySelector(to.hash);
          if (elementToView) {
            return elementToView.scrollIntoView({ behavior: 'smooth' });
          }
          setTimeout(() => {
            elementToView = document.querySelector(to.hash);
            return elementToView && elementToView.scrollIntoView({ behavior: 'smooth' });
          }, 250);
        }
      } catch {
        //
      }
    },
  });
}
