// import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { DATA_TABLE_LIMITS, Order } from '@console/constants';
import { sortAsc, sortDesc } from '@console/utils';
import type {
  DataTableBaseItem,
  DataTableCustomFilterItem,
  DataTableFilterBase,
  DataTablePagination,
  DataTableResponse,
  DataTableState,
} from './types';

export function paginationToQuery(state: DataTableState<DataTableBaseItem>) {
  const filters = {
    page: state.pagination.page,
    limit: state.pagination.limit,
    sort_by: '',
    order_by: '',
  };

  if (state.pagination.sortBy?.[0]) {
    filters.sort_by = state.pagination.sortBy[0];
    filters.order_by = state.pagination.sortDesc?.[0] ? Order.Desc : Order.Asc;
  }

  return filters;
}

export const parseNumber = (value: unknown) => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }

  if (typeof value === 'number') {
    return value;
  }

  return undefined;
};

export function queryToPagination(defaults?: Partial<DataTableFilterBase>) {
  const route = useRoute();
  const limit = DATA_TABLE_LIMITS.find(l => !!l.default) || DATA_TABLE_LIMITS[0];
  const query = route.query;
  const pagination: DataTablePagination = {
    page: parseNumber(query.page) ?? 1,
    limit: parseNumber(query.limit) ?? limit.value,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false,
  };

  if (query.sort_by) {
    pagination.sortBy = [query.sort_by as string];
  } else if (defaults?.sort_by) {
    pagination.sortBy = [defaults.sort_by];
  }

  if (query.order_by) {
    pagination.sortDesc = [query.order_by === Order.Desc];
  } else if (defaults?.order_by) {
    pagination.sortDesc = [defaults.order_by === Order.Desc];
  }

  return pagination;
}

export function initCustomFiltering(filters: DataTableCustomFilterItem[] = []) {
  const route = useRoute();
  const ret: Record<string, unknown> = {};

  if (filters?.length > 0) {
    filters.reduce((acc, el) => {
      let value = el.value ?? route.query[el.id] ?? el.defaultValue ?? undefined;
      if (value && el.initTypeCast) {
        value = Array.isArray(value) ? value.map(x => el.initTypeCast(x)) : el.initTypeCast(value);
      }
      acc[el.id] = value;
      return acc;
    }, ret);
  }

  return ret;
}

/**
 * Forces a normal data response to comply to DataTable's response format
 */
export function formatResponse<T = Record<string, any>>(
  data: T[],
  filters: DataTableFilterBase = {},
  total?: number
): DataTableResponse<T> {
  const defaults: DataTableFilterBase = {
    limit: data.length,
    page: 1,
    order_by: Order.Asc,
    sort_by: '',

    ...filters,
  };
  const resp: DataTableResponse<T> = {
    data,
    meta: {
      // filters: defaults,
      limit: defaults.limit,
      page: defaults.page as number,
      order_by: defaults.order_by,
      sort_by: defaults.sort_by,
      pages: 1,
      total: total ?? data.length,
    },
  };

  if (defaults.sort_by) {
    if (defaults.order_by === Order.Asc) {
      resp.data = sortAsc(resp.data, e => e[defaults.sort_by]);
    } else {
      resp.data = sortDesc(resp.data, e => e[defaults.sort_by]);
    }
  }

  resp.meta.pages = Math.ceil(resp.data.length / defaults.limit);
  resp.data = resp.data.slice(((defaults.page as number) - 1) * defaults.limit, (defaults.page as number) * defaults.limit);

  return resp;
}

export function emptyDataTableResponse<T = unknown>() {
  return formatResponse<T>([]);
}
