import root from './-root-';
import configs from './configs';
import dapi from './dapi';
import data_projects from './data-projects';
import experiments from './experiments';
import flags from './feature-flags';
import globalPermissions from './global-permissions';
import lazarus from './lazarus';
import metrics from './metrics';
import midway from './midway';
import notificationCenter from './notification-center';
import playground from './playground';
import releases from './releases';
import reservoirs from './reservoirs';
import sources from './sources';
import surveys from './surveys';

export default {
  metrics,
  experiments,
  'feature-flags': flags,
  lazarus,
  releases,
  midway,
  reservoirs,
  sources,
  surveys,
  configs,
  playground,
  dapi,
  'data-projects': data_projects,
  '-root-': root,
  'global-permissions': globalPermissions,
  'notification-center': notificationCenter,
};
