import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persistedstate';

// import Vue from 'vue';

// Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPersist);

export default pinia;
export * from './useViolationsStore';
