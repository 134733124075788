import { jwtDecode } from 'jwt-decode';

/**
 * Decode JWT-encoded token. Returns only the Payload-part
 *
 *
 * @param {string} token
 * @returns {JwtToken}
 */
export function decodeJWT(token: string): Record<string, any> {
  return jwtDecode(token);
}

/**
 * Normalizes query from $router.query - string booleans to actual booleans ('true' -> true)
 *
 *
 * @param {object} query
 * @returns {object}
 */
export function parseQuery(query: Record<string, any>): Record<string, any> {
  const parsedQuery: Record<string, any> = {};

  Object.keys(query).forEach(key => {
    if (query[key] === 'true') {
      parsedQuery[key] = true;
    } else if (query[key] === 'false') {
      parsedQuery[key] = false;
    } else {
      parsedQuery[key] = query[key];
    }
  });

  return parsedQuery;
}

/**
 * Truncate string to specific length and add dots
 *
 *
 * @param {string} str
 * @param {number} [limit=30]
 * @param {string} [delim='...']
 * @returns {string}
 */
export function limitString(str: string, limit = 30, suffix = '...'): string {
  if (str.length > limit) {
    return `${str.substr(0, limit)}${suffix}`;
  }
  return str;
}

/**
 * Pretty print object to string
 *
 *
 * @param {object} obj
 * @param {number} [indendation=2]
 * @returns {string}
 */
export function prettyPrint(obj: object, indendation = 2): string {
  return JSON.stringify(obj, null, indendation);
}

/**
 * Replace all non-alphanumeric chars with underscore
 *
 *
 * @param {string} str
 * @param {string} [replacement='_']
 * @returns
 */
export function URLSafeString(str: string, replacement = '_') {
  if (!str) {
    return str;
  }

  return str.replace(/((?!([a-z0-9])).)/gi, replacement);
}
