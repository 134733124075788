import { NavigationGuard } from 'vue-router';
import { ConsoleRouteDefinition, ConsoleRouteNinjaMeta } from '@console/router';
import { trackPage } from '../../../core/ninja';

/**
 * Tracks the page with ninja
 */
const trackPageGuard: NavigationGuard = (to, from, next) => {
  const ninjaMeta: ConsoleRouteNinjaMeta = (to.meta as ConsoleRouteDefinition['meta']).ninja;

  if (to.name !== from.name && !ninjaMeta?.disabled) {
    let eventName: string;
    let eventProps: Record<string, unknown> = {};

    if (typeof ninjaMeta?.name === 'function') {
      eventName = ninjaMeta.name(to, from);
    } else if (ninjaMeta?.name) {
      eventName = ninjaMeta.name;
    } else {
      eventName = to.name as string;
    }

    if (typeof ninjaMeta?.props === 'function') {
      eventProps = ninjaMeta.props(to, from);
    } else if (ninjaMeta?.props) {
      eventProps = ninjaMeta.props;
    }

    trackPage(eventName, eventProps);
  }

  next();
};

export default trackPageGuard;
