import type { ColorTones, ThemeName } from '../../types';
import { ThemeColorPalette, ThemeNeutralPalette } from '../../types';
import { shadesPalette } from '../shades';

export const name: ThemeName = 'core';

export const primary: ColorTones = {
  p0: shadesPalette.black,
  p99: '#F5F5F5',
  p95: '#B1FFF7',
  p90: '#71F7ED',
  p80: '#4FDBD1',
  p70: '#25BEB5',
  p60: '#00A29A',
  p50: '#00857F',
  p40: '#006A64',
  p30: '#00504C',
  p20: '#003734',
  p10: '#00201E',
  p15: '#002B29',
  p5: '#001413',
  p25: '#004340',
  p35: '#005D58',
  p98: '#E4FFFB',
  p100: shadesPalette.white,
};

export const secondary: ColorTones = {
  p0: shadesPalette.black,
  p99: '#F2FFFC',
  p95: '#DAF7F3',
  p90: '#CCE8E4',
  p80: '#B0CCC8',
  p70: '#96B1AD',
  p60: '#7B9693',
  p50: '#627C79',
  p40: '#4A6360',
  p30: '#324B49',
  p20: '#1C3532',
  p10: '#051F1E',
  p15: '#102A28',
  p5: '#001413',
  p25: '#27403D',
  p35: '#3E5754',
  p98: '#E4FFFB',
  p100: shadesPalette.white,
};

export const tertiary: ColorTones = {
  p0: shadesPalette.black,
  p99: '#FFFBFF',
  p95: '#F8EDFF',
  p90: '#EDDCFF',
  p80: '#D8B9FF',
  p70: '#C09AF3',
  p60: '#A57FD6',
  p50: '#8A66BA',
  p40: '#704DA0',
  p30: '#573486',
  p20: '#401B6E',
  p10: '#290055',
  p15: '#0C273F',
  p5: '#1B003B',
  p25: '#4B2879',
  p35: '#644092',
  p98: '#FFF7FF',
  p100: shadesPalette.white,
};

export const success: ColorTones = {
  p0: shadesPalette.black,
  p99: '#F5F5F5',
  p95: '#B1FFF7',
  p90: '#71F7ED',
  p80: '#4FDBD1',
  p70: '#25BEB5',
  p60: '#00A29A',
  p50: '#00857F',
  p40: '#006A64',
  p30: '#00504C',
  p20: '#003734',
  p10: '#00201E',
  p15: '#002B29',
  p5: '#001413',
  p25: '#004340',
  p35: '#005D58',
  p98: '#E4FFFB',
  p100: shadesPalette.white,
};

export const error: ColorTones = {
  p0: shadesPalette.black,
  p99: '#FFFBFF',
  p95: '#FFEDEA',
  p90: '#FFDAD6',
  p80: '#FFB4AB',
  p70: '#FF897D',
  p60: '#FF5449',
  p50: '#DE3730',
  p40: '#BA1A1A',
  p30: '#93000A',
  p20: '#690005',
  p10: '#410002',
  p15: '#540003',
  p5: '#2D0001',
  p25: '#7E0007',
  p35: '#A80710',
  p98: '#FFF8F7',
  p100: shadesPalette.white,
};

export const warning: ColorTones = {
  p0: shadesPalette.black,
  p10: '#221B00',
  p20: '#3A3000',
  p30: '#544600',
  p40: '#705D00',
  p50: '#8D7500',
  p60: '#AA8F0D',
  p70: '#C6A92E',
  p80: '#E4C548',
  p90: '#FFE16F',
  p95: '#FFF0C3',
  p99: '#FFFBFF',
  p100: shadesPalette.white,
};

export const info: ColorTones = {
  p0: shadesPalette.black,
  p10: '#001C38',
  p20: '#00315B',
  p30: '#004880',
  p40: '#0060A8',
  p50: '#0079D2',
  p60: '#2F94F3',
  p70: '#6AAFFF',
  p80: '#A1C9FF',
  p90: '#D3E4FF',
  p95: '#EAF1FF',
  p99: '#FDFCFF',
  p100: shadesPalette.white,
};

export const neutral: ColorTones = {
  p0: shadesPalette.black,
  p99: '#FAFDFD',
  p95: '#EFF1F1',
  p90: '#E1E3E3',
  p80: '#C4C7C7',
  p70: '#A9ACAC',
  p60: '#8E9192',
  p50: '#747878',
  p40: '#5C5F5F',
  p30: '#444748',
  p20: '#2E3132',
  p10: '#191C1D',
  p15: '#232626',
  p5: '#0E1212',
  p25: '#393C3D',
  p35: '#505354',
  p98: '#F8FAFA',
  p100: shadesPalette.white,
};

export const neutralVariant: ColorTones = {
  p0: shadesPalette.black,
  p99: '#F4FEFC',
  p95: '#E9F3F1',
  p90: '#DAE5E2',
  p80: '#BEC9C7',
  p70: '#A3ADAB',
  p60: '#899391',
  p50: '#6F7977',
  p40: '#56605F',
  p30: '#3F4947',
  p20: '#293231',
  p10: '#141D1C',
  p15: '#1E2827',
  p5: '#091312',
  p25: '#343D3C',
  p35: '#4A5453',
  p98: '#F1FCF9',
  p100: shadesPalette.white,
};

export const colorPalette: ThemeColorPalette = {
  primary,
  secondary,
  tertiary,
  success,
  error,
  warning,
  info,
};

export const neutralPalette: ThemeNeutralPalette = {
  neutral,
  neutralVariant,
};
