<template>
  <div class="d-flex align-center">
    <v-menu location="bottom left">
      <template #activator="{ props }">
        <v-badge
          :content="notificationsCount"
          :model-value="notificationsCount > 0"
          bordered
          location="top right"
          color="primary"
          dot
          offset-x="15"
          offset-y="15"
        >
          <v-btn icon class="text-white" v-bind="props">
            <v-icon size="24">mdi-bell</v-icon>
          </v-btn>
        </v-badge>
      </template>

      <div class="pa-5">
        <div v-if="notificationsCount">You have {{ notificationsCount }} notification(s) to review.</div>
        <div v-else>You have no notifications to review.</div>
        <v-btn class="mt-3" color="primary on-primary--text" :href="requestsToReviewLink">
          {{ notificationsCount ? 'Review' : 'Go to Notification centre' }}</v-btn
        >
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { getAppRouteHref } from '@console/router';

export default defineComponent({
  name: 'NotificationsMenu',

  props: {
    notificationsCount: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const requestsToReviewLink = computed(() => {
      return getAppRouteHref('notification-center', {
        name: 'notification-center.requests-to-review',
      });
    });

    return {
      requestsToReviewLink,
    };
  },
});
</script>
