<template>
  <v-app :class="{ 'app--unlogged': !isLogged }">
    <TopNavigation
      :disable-cu-selector="disableCuSelector"
      :notifications-count="notificationsCount"
      @change="onCUChange"
      v-if="isLogged"
    />

    <template v-if="isEnabled">
      <div class="main-grid">
        <LeftNavigation :items="leftNavItems" v-if="isLogged && leftNavItems && isLeftNavShown" />

        <v-main>
          <v-row class="px-16"> <slot name="header" /> </v-row>

          <slot name="main" v-if="$slots.main" />
          <router-view :key="customerUnitId" v-else />
        </v-main>
      </div>
    </template>

    <template v-else>
      <v-main>
        <v-container fluid ma-0 pa-0 class="shp text-on-surface">
          <ConsoleInDevelopment :product="product" />
        </v-container>
      </v-main>
    </template>

    <template v-if="isLogged">
      <RightPanel />
      <ConfirmModal ref="modalRef" />
      <SnackbarContainer />
      <TermsAndConditionsModal ref="termsAndConditionsModalRef" />
      <v-snackbar location="top" :timeout="-1" v-model="newContent">
        <v-row align="center">
          <v-col> A new version is available. </v-col>
          <v-btn color="primary" variant="text" @click="onRefreshClick()">Refresh</v-btn>

          <v-btn icon="mdi-close" variant="text" class="text-on-inverse-surface" @click="newContent = false" />
        </v-row>
      </v-snackbar>
    </template>
  </v-app>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, onBeforeMount, ref, watch, watchEffect } from 'vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from '@console/composables';
import { useConfirmDialog } from '@console/composables/useConfirmDialog';
import { checkCustomerUnit, useGlobalFilters } from '@console/composables/useGlobalFilters';
import { useProducts } from '@console/composables/useProducts';
import { useUser } from '@console/composables/useUser';
import { SERVICE_WORKER_ENABLED } from '@console/constants/config';
import { trackEvent } from '@console/core/ninja';
import { acceptTermsAndConditions } from '@console/core/service';
import { ThemeName } from '@console/theme/types';
import { setRootThemeVariation, useThemes } from '@console/theme/useThemes';
import { LeftNavigationItem } from '@console/types';
import { handleAsync } from '@console/utils';
import { ConfirmModal } from '../ConfirmModal';
import { ConsoleInDevelopment } from '../ConsoleInDevelopment';
import { LeftNavigation } from '../LeftNavigation';
import { RightPanel } from '../RightPanel';
import { SnackbarContainer } from '../SnackbarContainer';
import { TermsAndConditionsModal } from '../TermsAndConditionsModal';
import { TopNavigation } from '../TopNavigation';

export default defineComponent({
  name: 'ConsoleApplication',
  components: {
    TopNavigation,
    LeftNavigation,
    ConfirmModal,
    SnackbarContainer,
    RightPanel,
    ConsoleInDevelopment,
    TermsAndConditionsModal,
  },

  props: {
    leftNavItems: {
      type: Object as PropType<Record<string, LeftNavigationItem[]>>,
      required: false,
    },
    theme: {
      type: String as PropType<ThemeName>,
      required: false,
      default: 'core',
    },
    productId: {
      type: String,
      required: false,
    },
    disableCuSelector: {
      type: Boolean,
      required: false,
    },
  },

  emits: ['login'],

  setup(props, { emit }) {
    const { customerUnitId, setCustomerUnit } = useGlobalFilters();
    const { isProductEnabled, getProduct, loadNotificationsCount } = useProducts();
    const { getThemeClass, getVariationClass, currentThemeVariation } = useThemes();
    const store = useUser();
    const { impersonatedUser } = useUser();
    const { modalRef } = useConfirmDialog();
    const route = useRoute();
    const router = useRouter();
    const { openModal, closeModal } = useModal();

    const newContent = ref(false);
    const notificationsCount = ref(0);
    const termsAndConditionsModalRef = ref(false);

    const isLeftNavShown = computed(() => !route.meta?.hideLeftNav);
    const isLogged = computed(() => store.isTokenValid.value && Boolean(store.userInfo.value?.email));
    const product = computed(() => getProduct(props.productId));
    const isEnabled = computed(() => {
      return isLogged.value ? isProductEnabled(props.productId) : true;
    });

    const onRefreshClick = async () => {
      if (SERVICE_WORKER_ENABLED) {
        const activeSw = await navigator.serviceWorker.getRegistration();

        if (activeSw && activeSw.waiting) {
          await activeSw.waiting.postMessage({ type: 'SKIP_WAITING' });
          // window.localStorage.clear();
          setTimeout(() => window.location.reload(), 500);
        }
      } else {
        console.log('Your browser does not support service workers :(');
      }
    };

    const onCUChange = async (id: number) => {
      if (router.currentRoute.value.name) {
        // push only if the current page needs cuid
        if (router.currentRoute.value.params.cuid !== id.toString()) {
          await router.push({
            params: {
              ...router.currentRoute.value.params,
              cuid: id.toString(),
            },
            query: null,
          });
        }
        setCustomerUnit(id);
      }
    };

    watchEffect(async () => {
      if (isLogged.value) {
        notificationsCount.value = await loadNotificationsCount();
      }
    });

    watch(
      () => [currentThemeVariation.value, isLogged.value],
      () => {
        document.body.setAttribute('id', 'console');
        document.body.setAttribute('class', '');

        document.body.classList.add('console', getThemeClass(isLogged.value ? props.theme : 'core'), getVariationClass());
      },
      { immediate: true }
    );

    onBeforeMount(async () => {
      if (SERVICE_WORKER_ENABLED && window.isUpdateAvailable && !newContent.value) {
        window.isUpdateAvailable.then(() => {
          newContent.value = true;
        });
      }

      const routeCUID = Number(router.currentRoute.value.params.cuid);
      if (!isNaN(routeCUID) && !setCustomerUnit(routeCUID)) {
        const id = checkCustomerUnit() || customerUnitId.value;
        if (id) {
          onCUChange(id);
        }
      }

      setRootThemeVariation(currentThemeVariation.value);
      // if (isNotificationCenterEnabled) {
      //   notificationsCount.value = await loadNotificationsCount();
      // }
    });

    onMounted(() => {
      const hasAcceptedTC = store.userInfo.value?.requirements?.terms_accepted_at !== null;

      if (isLogged.value && !hasAcceptedTC && !impersonatedUser.value) {
        // If T&C are not accepted and is not impersonating, show the modal
        openModal(termsAndConditionsModalRef.value, {
          onAccept: async () => {
            await handleAsync(acceptTermsAndConditions());
            trackEvent('accept_terms_conditions');
            closeModal();
            store.loadUserInfo();
          },
        });
      }
    });

    watch(
      isLogged,
      (value: boolean) => {
        if (value) {
          emit('login');
        }
      },
      { immediate: true }
    );

    return {
      onCUChange,
      customerUnitId,
      modalRef,
      isLogged,
      newContent,
      isEnabled,
      isLeftNavShown,
      product,
      notificationsCount,
      termsAndConditionsModalRef,
      getThemeClass,
      getVariationClass,
      onRefreshClick,
      store,
    };
  },
});
</script>

<style>
.main-grid {
  display: grid;
  grid-template-columns: min-content 1fr;

  > .v-main {
    grid-column: 2 / span 2;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    > .v-container {
      padding: 0;
    }
  }
}
</style>
