<template>
  <v-btn variant="outlined" v-bind="{ ...$attrs, color: 'primary' }">
    <slot />
  </v-btn>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalActionSecondary',
});
</script>
