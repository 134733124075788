import qs from 'qs';

/**
 * Convert object to URL friendly query stringify
 */
export function queryStringFromObject(obj: Record<string, unknown>, params?: { comma?: boolean; repeat?: boolean }) {
  const opts: qs.IStringifyOptions = {
    arrayFormat: 'brackets',
  };

  if (params?.comma) {
    opts.arrayFormat = 'comma';
  } else if (params?.repeat) {
    opts.arrayFormat = 'repeat';
  }

  return qs.stringify(obj, opts);
}

/**
 * Convert object to URL friendly query stringify
 * @param {String} query - The query string
 * @param {Object} params - Additional config
 * @param {Boolean} params.brackets - Use brackets for decoding arrays
 *
 * @returns Object
 */
export function objectFromQueryString(query: string, params?) {
  const opts = {
    comma: false,
  };

  if (params?.comma) {
    opts.comma = true;
  }

  return qs.parse(query, opts);
}
