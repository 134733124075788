// Setup ninja script
import { useProducts } from '@console/composables/useProducts';
import { token } from '@console/composables/useUser/data';
import { NINJA_ENV, NINJA_LOCATION, NINJA_SITE_URL } from '@console/constants/config';
import { useThemes } from '@console/theme/useThemes';
import { decodeJWT } from '@console/utils';

const NINJA_ID = 'ninja-script-tag';

/**
 * Required fields appended to any tracking call
 */
function getParams(customParams: Record<string, unknown> = {}) {
  const { currentProductId } = useProducts();
  const { currentThemeVariation } = useThemes();

  const props: Record<string, unknown> = {
    ...customParams,

    user_id: token.value ? decodeJWT(token.value)['custom:okta_id'] : null,
    module: currentProductId.value,
    dark_mode: currentThemeVariation.value === 'dark' ? 1 : 0,
  };

  return props;
}

export function trackEvent(eventName: string, params: Record<string, unknown> = {}) {
  // new version of ninja should have this function TODO: remove when is released
  if (window.ninja?.trackEvent) {
    window.ninja.trackEvent(eventName, getParams(params));
  } else if (eventName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...getParams(params),

      trackEvent: Array.isArray(eventName) ? eventName : [eventName],
    });
  }
}

export function trackPage(name: string, params: Record<string, any> = {}) {
  // new version of ninja should have this function TODO: remove when is released
  if (window.ninja?.trackPage) {
    window.ninja.trackPage(name, getParams(params));
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...getParams(params),

      trackPage: name,
    });
  }
}

/**
 * Initialises Ninja tracking. Must be called at page start
 */
export function setupNinja() {
  if (NINJA_LOCATION) {
    window.dataLayer = window.dataLayer || [];

    if (!document.getElementById(NINJA_ID)) {
      window.configTracking = {
        platform: 'd',
        siteUrl: NINJA_SITE_URL,
        environment: NINJA_ENV,
        disableDefaultTrackPage: true,
        asyncSurveyStyles: true,
      };

      console.debug('Using Ninja', window.configTracking);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.id = NINJA_ID;
      script.src = NINJA_LOCATION;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }
  } else {
    console.warn('Ninja library not loaded -- NINJA_LOCATION is not defined.');
  }
}
