import { Ref, ref } from 'vue';
import { uuid } from '@console/utils';

type SnackOptions = {
  id?: string;
  content: string;
  timeout?: number;
  icon?: string;
  buttons?: {
    text?: boolean;
    content?: string;
    icon?: string;
    handler: (snack: SnackOptions) => void;
  }[];
};

const snacks: Ref<SnackOptions[]> = ref([]);

export function useSnackbar() {
  const openSnack = (content: SnackOptions | string) => {
    if (typeof content === 'string') {
      snacks.value.push({
        id: uuid(),
        timeout: 4000,
        content,
      });
    } else {
      snacks.value.push({
        id: content.id || uuid(),
        timeout: 4000,
        ...content,
      });
    }
  };

  const closeSnack = snack => {
    snacks.value = snacks.value.filter(s => s.id !== snack.id);
  };

  return {
    snacks,
    closeSnack,
    openSnack,
  };
}
