import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'sources.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'sources.hasVisitedRoot',
        { name: 'sources.about' },
        { name: 'sources.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'sources.about',
    path: '/about',
    meta: {
      title: 'About',
      ninja: {
        name: 'home_sources',
      },
    },
  },
  {
    name: 'sources.list',
    path: '/list/:cuid?',
    props: parseProps(['cuid']),
    meta: {
      title: 'List',
      ninja: {
        name: 'sources_list',
      },
    },
  },
  {
    name: 'sources.data-collection.create-hydra-stream',
    path: '/data-collection/create-hydra-stream',
    meta: {
      title: 'Create Hydra stream',
      ninja: {
        name: 'sources_create_hydra_stream',
      },
    },
  },
  {
    name: 'sources.field-details',
    path: '/-/:sourceId/fields/:fieldId',
    redirect: { name: 'sources.field-details.details' },
    props: parseProps(['sourceId', 'fieldId']),
    meta: {
      title: 'Field details',
    },
    children: [
      {
        path: 'details',
        name: 'sources.field-details.details',
        redirect: { name: 'sources.field-details.subscriptions' },
        meta: {
          title: 'Field details',
          ninja: {
            name: 'sources_field_details',
          },
        },
        children: [
          {
            path: 'subscriptions',
            name: 'sources.field-details.subscriptions',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Subscriptions',
              ninja: {
                name: 'sources_field_details_subscriptions',
              },
            },
          },
          {
            path: 'changelog',
            name: 'sources.field-details.changelog',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Changelog',
              ninja: {
                name: 'sources_field_details_changelog',
              },
            },
          },
          {
            path: 'referenced-by',
            name: 'sources.field-details.referenced-by',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Referenced by',
              ninja: {
                name: 'sources_field_details_referenced_by',
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'sources.source-details',
    path: '/-/:sourceId',
    props: parseProps(['sourceId']),
    meta: {
      title: 'Source details',
      ninja: {
        name: 'sources_details',
      },
    },
    redirect: { name: 'sources.source-details.fields' },
    children: [
      {
        name: 'sources.source-details.fields',
        path: 'fields',
        props: parseProps(['sourceId']),
        meta: {
          title: 'Source details | Fields',
          ninja: {
            name: 'sources_details_fields',
          },
        },
      },
      {
        name: 'sources.source-details.details',
        path: 'details',
        props: parseProps(['sourceId']),
        meta: {
          title: 'Source details',
        },
        redirect: { name: 'sources.source-details.subscriptions' },
        children: [
          {
            name: 'sources.source-details.subscriptions',
            path: 'subscriptions',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Subscriptions',
              ninja: {
                name: 'sources_details_subscriptions',
              },
            },
          },
          {
            name: 'sources.source-details.hydra-details',
            path: 'hydra-details',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Hydra details',
              ninja: {
                name: 'sources_details_hydra',
              },
            },
          },
          {
            name: 'sources.source-details.lazarus-details',
            path: 'lazarus-details',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Lazarus details',
              ninja: {
                name: 'sources_details_lazarus',
              },
            },
          },
          {
            name: 'sources.source-details.changelog',
            path: 'changelog',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Changelog',
              ninja: {
                name: 'sources_details_changelog',
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'sources.offline-documentation.management',
    path: '/bulk-documentation/:cuid?',
    props: parseProps(['cuid']),
    meta: {
      title: 'Sources | Bulk Documentation',
      ninja: {
        name: 'sources_bulk_documentation_management',
      },
    },
  },
  {
    name: 'user-behaviour.matrices',
    path: '/user-behaviour/all-products',
    meta: {
      title: 'User behaviour | All products',
      ninja: {
        name: 'user_behaviour_products',
      },
    },
  },
  {
    name: 'user-behaviour.matrix-details',
    path: '/user-behaviour/all-products/:matrixId',
    props: route => {
      return {
        matrixId: route.params.matrixId,
        ninja: {
          name: 'user_behaviour_product_details',
        },
      };
    },
    meta: {
      title: 'User behaviour | Product details',
    },
    redirect: { name: 'user-behaviour.matrix-details.events' },
    children: [
      {
        name: 'user-behaviour.matrix-details.events',
        path: 'events',
        meta: {
          title: 'Product details | Events',
          ninja: {
            name: 'user_behaviour_product_events',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.properties',
        path: 'properties',
        meta: {
          title: 'Product details | Properties',
          ninja: {
            name: 'user_behaviour_product_properties',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.releases',
        path: 'releases',
        meta: {
          title: 'Product details | Releases',
          ninja: {
            name: 'user_behaviour_product_releases',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.unreleased-changes',
        path: 'unreleased-changes',
        meta: {
          title: 'Product details | Unreleased changes',
          ninja: {
            name: 'user_behaviour_product_unreleased_changes',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.statistics',
        path: 'statistics',
        meta: {
          title: 'Product details | Statistics',
          ninja: {
            name: 'user_behaviour_product_statistics',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },

  {
    name: 'user-behaviour.event-details',
    path: '/user-behaviour/all-products/:matrixId/events/:eventId',
    props: route => {
      return {
        eventId: route.params.eventId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Event details',
      ninja: {
        name: 'user_behaviour_event_details',
      },
    },
    redirect: { name: 'user-behaviour.event-details.trackers' },
    children: [
      {
        name: 'user-behaviour.event-details.trackers',
        path: 'trackers',
        meta: {
          title: 'Event details | Trackers',
          ninja: {
            name: 'user_behaviour_event_details_trackers',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.properties',
        path: 'properties',
        meta: {
          title: 'Event details | Properties',
          ninja: {
            name: 'user_behaviour_event_details_properties',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.history',
        path: 'history',
        meta: {
          title: 'Event details | History',
          ninja: {
            name: 'user_behaviour_event_details_history',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.statistics',
        path: 'statistics',
        meta: {
          title: 'Event details | Statistics',
          ninja: {
            name: 'user_behaviour_event_details_statistics',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },

  {
    name: 'user-behaviour.property-details',
    path: '/user-behaviour/all-products/:matrixId/properties/:propertyId',
    props: route => {
      return {
        propertyId: route.params.propertyId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Properties details',
      ninja: {
        name: 'user_behaviour_property_details',
      },
    },
    redirect: { name: 'user-behaviour.property-details.trackers' },
    children: [
      {
        name: 'user-behaviour.property-details.trackers',
        path: 'trackers',
        meta: {
          title: 'Property details | Trackers',
          ninja: {
            name: 'user_behaviour_property_details_trackers',
          },
        },
        props: route => {
          return {
            propertyId: route.params.propertyId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.property-details.events',
        path: 'events',
        meta: {
          title: 'Property details | Events',
          ninja: {
            name: 'user_behaviour_property_details_events',
          },
        },
        props: route => {
          return {
            propertyId: route.params.propertyId,
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },
  {
    name: 'user-behaviour.release-details',
    path: '/user-behaviour/all-products/:matrixId/releases/:releaseId',
    props: route => {
      return {
        releaseId: route.params.releaseId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Release details',
      ninja: {
        name: 'user_behaviour_release_details',
      },
    },
  },
  {
    name: 'user-behaviour.create',
    path: '/user-behaviour/create/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'User behaviour | Create',
      ninja: {
        name: 'user_behaviour_create',
      },
    },
  },

  // {
  //   name: 'user-behaviour.liveview-configs',
  //   path: '/user-behaviour/create/:cuid',
  //   meta: {
  //     title: 'User behaviour | Create',
  //   },
  // },

  {
    name: 'user-behaviour.liveview-list',
    path: '/user-behaviour/liveview/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Liveview | List',
    },
  },
  {
    name: 'user-behaviour.liveview-create',
    path: '/user-behaviour/liveview/new',
    meta: {
      title: 'Liveview | Create',
    },
  },
  {
    name: 'user-behaviour.liveview-details',
    path: '/user-behaviour/liveview/:id/details',
    props: parseProps(['id']),
    meta: {
      title: 'Liveview | Details',
    },
  },
] as ConsoleRouteDefinition[];
