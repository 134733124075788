import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'experiments.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'experiments.hasVisitedRoot',
        { name: 'experiments.about' },
        { name: 'experiments.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'experiments.about',
    path: '/about',
    meta: {
      title: 'experiments',
      ninja: {
        name: 'home_experiments',
      },
    },
  },
  {
    name: 'experiments.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Experiments list',
      ninja: {
        name: 'experiments_listing',
      },
    },
  },

  {
    path: '/report/:id([a-zA-Z]{1}[a-zA-Z\\d]{0,9}-\\d+)',
    name: 'experiments.report_jira',
    props: true,
  },
  {
    path: '/report/:id(\\d+)',
    name: 'experiments.report',
    props: parseProps(['id']),
    redirect: { name: 'experiments.report.summary' },
    meta: {
      hideLeftNav: true,
    },

    children: [
      {
        name: 'experiments.report.summary',
        path: 'summary',
        props: parseProps(['id']),
        meta: {
          title: 'Report summary',
          ninja: {
            name: 'experimentation_report_summary',
            props: to => ({ id: to.params.id }),
          },
        },
      },
      {
        name: 'experiments.report.reports',
        path: 'reports',
        props: parseProps(['id']),
        redirect: { name: 'experiments.report.reports.advanced' },
        meta: {
          ninja: {
            name: 'experimentation_reports_tab',
            props: to => ({ resource_id: to.params.id }),
          },
        },
        children: [
          {
            name: 'experiments.report.reports.advanced',
            path: 'advanced',
            props: parseProps(['id']),
            meta: {
              title: 'Advanced report',
              ninja: {
                name: 'experimentation_reports_advanced',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
          {
            name: 'experiments.report.reports.segments',
            path: 'segments',
            props: parseProps(['id']),
            meta: {
              title: 'Segments report',
              ninja: {
                name: 'experimentation_reports_segments',
                props: to => ({ resource_id: to.params.id }),
              },
            },
          },
        ],
      },

      {
        name: 'experiments.report.details',
        path: 'details',
        props: parseProps(['id']),
        meta: {
          title: 'Report details',
          ninja: {
            name: 'experimentation_more_details_tab',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'experiments.report.discussion',
        path: 'discussion',
        props: parseProps(['id']),
        meta: {
          title: 'Comments',
          ninja: {
            name: 'experimentation_discussion_tab',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'experiments.report.changelog',
        path: 'changelog',
        props: parseProps(['id']),
        meta: {
          title: 'Changelog',
          ninja: {
            name: 'experimentation_changelog',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
    ],
  },
  {
    path: '/edit/:id',
    name: 'experiments.edit',
    props: parseProps(['id']),
    meta: {
      ninja: {
        name: 'edit_experiment',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },
  {
    path: '/new',
    name: 'experiments.new',
    meta: {
      ninja: {
        name: 'create_experiment',
      },
    },
  },
  {
    path: '/mss-calculator',
    name: 'experiments.mss-calculator',
    meta: {
      title: 'Experiments | MSS calculator',
      ninja: {
        name: 'experiments_mss_calculator',
      },
    },
  },
] as ConsoleRouteDefinition[];
