import PortalVue from 'portal-vue';
import { createApp, defineComponent, ref, watchEffect } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { useCoffer } from '@console/composables/useCoffer';
import { loadLaunchDarkly } from '@console/composables/useConfig';
import { useProducts } from '@console/composables/useProducts';
import { useUser } from '@console/composables/useUser';
import pinia from '@console/core/store';
import { getVuetify } from '@console/core/vuetify';
import { router } from '@console/router';
import { ThemeName } from '@console/theme/types';
import '../setup';
import ConsoleLoader from './ConsoleLoader.vue';
import { initSentry } from './initSentry';

/**
 * Create Console Vue app with all bindings.
 * Requires a root component and set of routes for the app
 */
export function createConsoleApp(
  routes: RouteRecordRaw[],
  App: any,
  theme: ThemeName = 'core',
  productId = '',
  customLoaders: Promise<any>[] = []
) {
  // add app routes
  routes.forEach(route => router.addRoute(route));

  const ConsoleRoot = defineComponent({
    name: 'ConsoleRoot',

    setup() {
      const { currentProductId } = useProducts();
      currentProductId.value = productId;

      return () => <App theme={theme} product-id={productId} />;
    },
  });

  const { isLogged } = useUser();
  const { loadCofferData } = useCoffer();
  const { loadProductsDefinition } = useProducts();

  const app = createApp({
    setup() {
      const isLoading = ref(true);

      watchEffect(async () => {
        if (isLogged.value) {
          isLoading.value = true;

          await loadLaunchDarkly(productId);
          await Promise.allSettled([loadProductsDefinition(true), loadCofferData(), ...customLoaders]);
        }
        isLoading.value = false;
      });

      return () => {
        if (isLoading.value) {
          return <ConsoleLoader />;
        }
        return <ConsoleRoot />;
      };
    },
  });

  initSentry(app);
  // initLaunchDarkly(app);

  app.use(getVuetify(theme));
  app.use(router);
  app.use(pinia);
  app.use(PortalVue);
  app.mount('#app');

  return app;
}
