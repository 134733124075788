import { VueConstructor, ref } from 'vue';
import { useModal } from './useModal';

type ConfirmDialogOptions = {
  title: string;
  message: string;
  component?: VueConstructor;
  props?: Record<string, any>;
  options?: Partial<{
    color: string;
    width: number;
    zIndex: number;
    cancelText: string | null;
    confirmText: string;
  }>;
};

const options = {
  color: 'primary',
  width: 600,
  zIndex: 200,
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};
const modalRef = ref(null);

export function useConfirmDialog() {
  const { openModal } = useModal();

  const showConfirm = (args: ConfirmDialogOptions): Promise<boolean> => {
    if (!modalRef.value) {
      return Promise.reject(new Error('Confirm modalRef is not defined'));
    }

    return new Promise(resolve => {
      openModal(modalRef.value, {
        resolve: () => resolve(true),
        reject: () => resolve(false),
        title: args.title,
        message: args.message,
        component: args.component,
        props: args.props,
        options: {
          ...options,
          ...(args.options ?? {}),
        },
      });
    });
  };

  return {
    modalRef,
    showConfirm,
  };
}
