import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    name: 'dapi.root',
    path: '/',
    redirect: { name: 'dapi.list' },
    // redirect: () => getLandingRoute('dapi.hasVisitedRoot', { name: 'dapi.about' }, { name: 'dapi.list' }),
  },
  {
    name: 'dapi.list',
    path: '/list',
    meta: {
      ninja: {
        name: 'dapi_list',
      },
    },
  },
] as ConsoleRouteDefinition[];
