import { computed, ref } from 'vue';
import { useSettingsManager } from '@console/composables/useSettingsManager';
import { DataTableBaseItem, DataTableColumn, DataTableColumnSettings, DataTableComfortType, DataTableSchema } from '../types';

enum DataTableSettings {
  Columns = 'datatable.columns',
  ComfortDisplay = 'datatable.comfortDisplay',
}

const comfortDisplayRef = useSettingsManager<DataTableComfortType>(DataTableSettings.ComfortDisplay, 'medium');
const columnsRef = useSettingsManager<DataTableColumnSettings>(DataTableSettings.Columns, {});

export function useDataTableSettings(schema: DataTableSchema<DataTableBaseItem>) {
  const columnsRefLocal = ref(null); // for schema.simple we don't store settings in localStorage, keep it in ref

  const comfortDisplay = computed({
    get() {
      return comfortDisplayRef.value;
    },
    set(value) {
      comfortDisplayRef.value = value;
    },
  });
  const columns = computed({
    get(): DataTableColumn[] {
      if (schema.simple) {
        return columnsRefLocal.value || schema.columns;
      }
      return columnsRef.value[schema.key] ?? schema.columns;
    },
    set(value: DataTableColumn[]) {
      if (schema.simple) {
        columnsRefLocal.value = value;
      } else {
        columnsRef.value = {
          ...columnsRef.value,

          [schema.key]: value,
        };
      }
    },
  });

  return {
    comfortDisplay,
    columns,
    columnsRef,
    columnsRefLocal,
  };
}
