import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { CatalogAPI } from '@console/core/http';
import { UserCertifications, UserTraining } from '@console/types';
import { handleAsync } from '@console/utils';
import { userInfo } from './data';

export const userTrainings = ref<UserTraining[]>([]);

export const userRequirements = computed(() => {
  const result = {
    isReservoirOwnerCertified: false,
    isSourceOwnerCertified: false,
    isToCAccepted: false,
    isPrivacyTrained: false,
  };

  if (userInfo.value) {
    const reservoirCert = userInfo.value.requirements?.certificates.find(item => item.key === UserCertifications.ReservoirOwner);
    const sourcesCert = userInfo.value.requirements?.certificates.find(item => item.key === UserCertifications.SourceOwner);

    result.isReservoirOwnerCertified = !!(reservoirCert && dayjs(reservoirCert.expires_at).isAfter(dayjs()));
    result.isSourceOwnerCertified = !!(sourcesCert && dayjs(sourcesCert.expires_at).isAfter(dayjs()));
    result.isPrivacyTrained = !!userInfo.value.requirements?.privacy_training_passed_at;
    result.isToCAccepted = !!userInfo.value.requirements?.terms_accepted_at;
  }

  return result;
});

export const loadUserTrainings = async () => {
  const [err, resp] = await handleAsync(CatalogAPI.GET<{ data: UserTraining[] }>('/users/trainings'));

  if (err) {
    console.error(err);
    userTrainings.value = [];
  } else {
    userTrainings.value = resp.data;
  }

  return userTrainings.value;
};
