<template>
  <v-menu location="bottom right" v-if="customerUnits.length > 1">
    <template #activator="{ props }">
      <v-btn variant="outlined" :disabled="disabled" class="text-white" data-testid="selected-cu" v-bind="props">
        {{ selectedCustomerUnit?.name }}
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <div class="pa-2" v-if="selectedCustomerUnit">
      <v-list density="comfortable" max-height="350">
        <template :key="bu" v-for="(item, bu) in groupedCustomerUnits">
          <v-list-subheader>{{ bu }}</v-list-subheader>
          <v-list-item
            :key="customerUnit.id"
            :class="{ 'v-list-item--active': selectedCustomerUnit.id.toString() == customerUnit.id.toString() }"
            data-testid="cu"
            @click="onSelect(customerUnit.id)"
            v-for="customerUnit in item"
          >
            <v-list-item-title>{{ customerUnit.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useCoffer } from '@console/composables/useCoffer';
import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import { CustomerUnit } from '@console/types';

export default defineComponent({
  name: 'CustomerUnitSelector',

  props: { disabled: { type: Boolean, default: false } },
  emits: ['change'],

  setup(_, { emit }) {
    const { selectedCustomerUnit, customerUnitId } = useGlobalFilters();
    const { customerUnits, businessUnits } = useCoffer();

    const onSelect = (value: number) => {
      if (value !== customerUnitId.value) {
        emit('change', value);
      }
    };

    const groupedCustomerUnits = computed<{ [key: string]: CustomerUnit[] }>(() => {
      return [...customerUnits.value]
        .sort((a, b) => {
          // Put Europe first in the selector
          if ((a.business_unit_key === 'eu') !== (b.business_unit_key === 'eu')) {
            return a.business_unit_key === 'eu' ? -1 : 1;
          }

          // Put OLX Autos last in the selector
          if ((a.business_unit_key === 'autos') !== (b.business_unit_key === 'autos')) {
            return a.business_unit_key === 'autos' ? 1 : -1;
          }
          return a.business_unit_key.localeCompare(b.business_unit_key);
        })
        .reduce((result, current) => {
          const key = businessUnits.value.find(i => i.key === current.business_unit_key)?.name || current.business_unit_key;

          if (!result[key]) {
            result[key] = [];
          }

          result[key].push(current);
          return result;
        }, {});
    });

    return {
      selectedCustomerUnit,
      customerUnits,
      groupedCustomerUnits,
      onSelect,
    };
  },
});
</script>
