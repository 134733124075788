import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import { getLandingRoute, parseProps } from '@console/router/utils';
import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    name: 'lazarus.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'lazarus.hasVisitedRoot',
        { name: 'lazarus.about' },
        { name: 'lazarus.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'lazarus.about',
    path: '/about',
    meta: {
      title: 'About',
      ninja: {
        name: 'home_lazarus',
      },
    },
  },
  {
    name: 'lazarus.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Available Lazarus configs',
      ninja: {
        name: 'lazarus_list',
      },
    },
  },
  {
    name: 'lazarus.details',
    path: '/details/:configName',
    redirect: { name: 'lazarus.details.fulldumps' },
    props: parseProps([]),
    meta: {
      title: 'Lazarus Config Details',
      ninja: {
        name: 'lazarus_details',
      },
    },

    children: [
      {
        name: 'lazarus.details.fulldumps',
        path: '/details/:configName/fulldumps',
        meta: {
          title: 'Lazarus Config Fulldumps',
          ninja: {
            name: 'lazarus_details_fulldumps',
          },
        },
      },
      {
        name: 'lazarus.details.tables',
        path: '/details/:configName/tables',
        meta: {
          title: 'Lazarus Config Tables',
          ninja: {
            name: 'lazarus_details_tables',
          },
        },
      },
    ],
  },
] as ConsoleRouteDefinition[];
