<template>
  <div>
    <div class="text-h2">404</div>
    <div class="text-h4">Uh oh, this content does not exist, yet</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: '404', //eslint-disable-line
  components: {},

  setup() {
    // const { getThemeClass, getVariationClass } = useThemes();
  },
});
</script>
