export const DOCS_URI_NEW = 'https://naspersclassifieds.atlassian.net/wiki/x';

// GENERAL
export const MAINPAGE_DOCS_URI = `${DOCS_URI_NEW}/xgHaaA0`;
export const ABOUT_US_URI = `${DOCS_URI_NEW}/lQYEYQ0`;
export const MY_PROFILE_DOCS_URI = `${DOCS_URI_NEW}/voCZXA0`;
export const TERMS_AND_CONDITIONS_DOCS_URI = `${DOCS_URI_NEW}/wwBUbA0`;
export const GLOBAL_PERMISSION_DOCS_URI = `${DOCS_URI_NEW}/44GYXA0`;
export const DATA_CERTIFICATION_DOCS_URI = `${DOCS_URI_NEW}/LACFYg0`;
export const ROLES_AND_PERMISSIONS_DOCS_URI = `${DOCS_URI_NEW}/fIbGZQ0`;

// DATA PROJECTS
export const DATA_PROJECTS_DOCS_URI = `${DOCS_URI_NEW}/M4AsWg0`;
export const DATA_PROJECTS_PERMISSIONS_DOCS_URI = `${DOCS_URI_NEW}/M4AsWg0#Roles-and-permissions`;

// EXPERIMENTS
export const EXPERIMENT_TYPES_DOCS_URI = `${DOCS_URI_NEW}/IILIZQ0`;
export const EXPERIMENT_DEPENDENCIES_DOCS_URI = `${DOCS_URI_NEW}/84AhXg0#Define-dependencies-between-experiments`;
export const EXPERIMENT_METRICS_LIMIT_DOCS_URI = `${DOCS_URI_NEW}/1YC_Yw0#Limitations`;
export const EXPERIMENTS_FAQ_DOCS_URI = `${DOCS_URI_NEW}/oIXIZQ0`;
export const EXPERIMENTS_GOOD_PRACTICES_DOCS_URI = `${DOCS_URI_NEW}/yIHIZQ0`;
export const EXPERIMENTS_EXPERIMENT_REPORT_DOCS_URI = `${DOCS_URI_NEW}/XYPIZQ0`;
export const EXPERIMENTS_SETUP_DOCS_URI = `${DOCS_URI_NEW}/OYLIZQ0`;
export const EXPERIMENTS_TRAININGS_DOCS_URI = `${DOCS_URI_NEW}/SoHIZQ0`;
export const EXPERIMENTS_ERRORS_DOCS_URI = `${DOCS_URI_NEW}/MIPIZQ0`;
export const CORE_WEB_VITALS_DOCS_URI = `${DOCS_URI_NEW}/JIG3Yw0`;
export const FEATURE_FLAGS_DOCS_URI = `${DOCS_URI_NEW}/DQFdXg0`;
export const ADDING_REVENUE_METRICS_DOCS_URI = `${DOCS_URI_NEW}/oIXIZQ0#Why-can’t-I-add-Revenue-Metrics-to-my-experiment%3F`;
export const QUALITY_SCORE_DOCS_URI = `${DOCS_URI_NEW}/8YHIZQ0`;
export const SRM_DOCS_URI = `${DOCS_URI_NEW}/dIBiXg0#Sample-ratio-mismatch-(SRM)`;

// LAZARUS
export const LAZARUS_DOCS_URI = `${DOCS_URI_NEW}/YYJOXg0`;

// METRICS
export const REVENUE_METRICS_DOCS_URI = `${DOCS_URI_NEW}/LYHrdg0`;

// PLAYGROUND
export const PLAYGROUND_FAQ = `${DOCS_URI_NEW}/oIXIZQ0#The-Playground`;
export const PLAYGROUND_INFO_URI = `${DOCS_URI_NEW}/9gCdXg0`;

// RESERVOIRS
export const RESERVOIRS_DOCS_URI = `${DOCS_URI_NEW}/V4GwXA0`;
export const DATA_CONSUMPTION_DOCS_URI = `${DOCS_URI_NEW}/fIKXXA0`;

// SOURCES
export const DATA_SOURCES_TERMS_AND_CONDITIONS_DOCS_URI = `${DOCS_URI_NEW}/eIGCYg0`;
export const DATA_SOURCES_DOCS_URI = `${DOCS_URI_NEW}/gwV6Yg0`;
export const DATA_SOURCES_ABOUT_DOCS_URI = `${DOCS_URI_NEW}/T4lsYg0`;
export const PII_CLASSIFICATION_URI = `${DOCS_URI_NEW}/igGAYg0`;
export const HYDRA_DOCS_URI = `${DOCS_URI_NEW}/1wBTXg0`;
export const GDPR_FIELDS = `${DOCS_URI_NEW}/HALogA0`;

// SURVEYS
export const SURVEY_HOW_TO_CREATE_DOCS_URI = `${DOCS_URI_NEW}/rYCYXg0`;
export const SURVEY_RESTRICTIONS_DOCS_URI = `${DOCS_URI_NEW}/6wCcXg0`;
export const SURVEY_TRACKING_DOCS_URI = `${DOCS_URI_NEW}/ioCgXg0`;
export const SURVEY_RAW_DATA_ACCESS_DOCS_URI = `${DOCS_URI_NEW}/7wGjXg0`;
