<template>
  <v-btn variant="flat" class="bg-primary text-on-primary" v-bind="{ ...$attrs }">
    <slot />
  </v-btn>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalActionPrimary',
});
</script>
