<template>
  <div class="console-loader">
    <div class="console-loader__anim">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.console-loader {
  $r: 16px;

  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  @media (prefers-color-scheme: dark) {
    background: #353738;
  }

  @media (prefers-color-scheme: light) {
    background: #ebeff1;
  }

  &__anim {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      width: $r;
      height: $r;
      border-radius: 50%;
      animation: console-loader 1.2s linear infinite;

      @media (prefers-color-scheme: dark) {
        background: #4fdbd1;
      }

      @media (prefers-color-scheme: light) {
        background: #006a64;
      }

      &:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
      }

      &:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
      }

      &:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
      }

      &:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
      }
      &:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
      }
      &:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
      }
      &:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
      }
      &:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
      }
    }
  }
}

@keyframes console-loader {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.35;
  }
}
</style>
