import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'feature-flags.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'feature-flags.hasVisitedRoot',
        { name: 'feature-flags.about' },
        { name: 'feature-flags.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'feature-flags.about',
    path: '/about',
    meta: {
      title: 'feature-flags',
      ninja: {
        name: 'home_feature_flags',
      },
    },
  },
  {
    name: 'feature-flags.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Feature flags list',
      ninja: {
        name: 'feature_flags_listing',
      },
    },
  },

  {
    path: '/report/:id([a-zA-Z]{1}[a-zA-Z\\d]{0,9}-\\d+)',
    name: 'feature-flags.report_jira',
    props: true,
    meta: {
      ninja: {
        name: 'feature_flags_report_jira',
        props: to => ({ id: to.params.id }),
      },
    },
  },
  {
    path: '/report/:id(\\d+)',
    name: 'feature-flags.report',
    props: parseProps(['id']),
    redirect: { name: 'feature-flags.report.summary' },

    children: [
      {
        name: 'feature-flags.report.summary',
        path: 'summary',
        props: parseProps(['id']),
        meta: {
          title: 'Report summary',
          ninja: {
            name: 'feature_flag_summary_tab',
            props: to => ({ id: to.params.id }),
          },
        },
      },
      {
        name: 'feature-flags.report.discussion',
        path: 'discussion',
        props: parseProps(['id']),
        meta: {
          title: 'Comments',
          ninja: {
            name: 'feature_flag_discussion_tab',
            props: to => ({ id: to.params.id }),
          },
        },
      },
      {
        name: 'feature-flags.report.changelog',
        path: 'changelog',
        props: parseProps(['id']),
        meta: {
          title: 'Changelog',
          ninja: {
            name: 'feature_flag_changelog_tab',
            props: to => ({ id: to.params.id }),
          },
        },
      },
    ],
  },
  {
    name: 'feature-flags.edit',
    path: '/edit/:id',
    props: parseProps(['id']),
    meta: {
      ninja: {
        name: 'edit_feature_flag',
        props: to => ({ id: to.params.id }),
      },
    },
  },
  {
    name: 'feature-flags.new',
    path: '/new',
    meta: {
      ninja: {
        name: 'create_feature_flag',
      },
    },
  },
] as ConsoleRouteDefinition[];
