import { CofferAPI } from '@console/core/http';
import {
  GlobalPermissionAssignmentItem,
  GlobalPermissionAssignmentPayload,
  GlobalPermissionAssignments,
  GlobalPermissionAuthInfo,
  GlobalPermissionType,
} from '@console/types';
import { sortAsc } from '@console/utils';

function preparePayload(assignments: GlobalPermissionAssignments): GlobalPermissionAssignmentPayload[] {
  return assignments.users.map(u => {
    // DPO creation process needs business unit
    if (assignments.policy_key === GlobalPermissionType.DPO) {
      return {
        identity: u,
        policy_key: assignments.policy_key,
        business_unit_key: assignments.business_unit_key,
      };
    }
    return {
      identity: u,
      policy_key: assignments.policy_key,
    };
  });
}

export async function loadAllGlobalPermissions(filters) {
  return CofferAPI.GET<GlobalPermissionAssignmentItem[]>('/global_roles/assignments', { ...filters });
}

export async function saveGlobalPermission(assignments: GlobalPermissionAssignments) {
  return CofferAPI.POST(`/global_roles/assignments`, preparePayload(assignments));
}

export async function deleteGlobalPermission(id: number) {
  return CofferAPI.DELETE(`/global_roles/assignments/${id}`);
}

export async function getAuthInfo() {
  return CofferAPI.GET<GlobalPermissionAuthInfo>('/authinfo');
}

export function getSelectedCUIdentities(assignments: GlobalPermissionAssignmentItem[], selectedCUId: number) {
  const identities = assignments.reduce((acc, currentAssignment) => {
    if (currentAssignment.customer_unit.id === selectedCUId) {
      acc.push(currentAssignment.identity);
    }
    return acc;
  }, []);

  return sortAsc(identities);
}
