import { Ref, ref } from 'vue';

const getId = (obj: any) => {
  if (obj) {
    if (typeof obj === 'string') {
      return obj;
    }

    // try to access the Modal component behind the ref()
    if (obj.$) {
      return obj.$.subTree.component.props.id || obj.$.props.id;
    }

    return obj.id;
  }
  return '';
};

const currentModal = ref('');
const modalData = ref<any>({});

export function useModal<T = Record<string, any>>() {
  function openModal<P = T>(id: any, data?: P) {
    // console.log(123, id);
    currentModal.value = getId(id);
    modalData.value = data ?? {};
  }

  function closeModal() {
    currentModal.value = '';
  }

  return {
    currentModal,
    modalData: modalData as Ref<T>,

    openModal,
    closeModal,
  };
}
