<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUser } from '@console/composables/useUser';
import { STORAGE_KEYS } from '@console/constants';
import { PUBLIC_PATH } from '@console/constants/config';
import { getStorageKey, handleAsync } from '@console/utils';

export default defineComponent({
  name: 'SsoPage',

  async beforeMount() {
    const route = useRoute();
    const router = useRouter();
    const { login } = useUser();
    const params = new URLSearchParams(route.hash.substr(1));

    if (params.get('id_token')) {
      const [err] = await handleAsync(login(params.get('id_token') as string));

      if (err) {
        router.push({ name: 'login' });
      } else {
        const key = getStorageKey(STORAGE_KEYS.LOGIN_RETURN_TO);
        const returnTo = localStorage.getItem(key);
        localStorage.removeItem(key);

        // Fully Reload the page -> load all required data (orgs, user data)
        window.location.assign(returnTo || `${window.location.origin}${PUBLIC_PATH}`);
      }
    } else {
      router.push({ name: 'login' });
    }
  },
});
</script>
