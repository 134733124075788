import { ConsoleRouteDefinition } from '@console/router';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'notification-center.root',
    path: '/',
    redirect: () => {
      return getLandingRoute(
        'notification-center.hasVisitedRoot',
        { name: 'notification-center.about' },
        { name: 'notification-center.requests-to-review' }
      );
    },
  },
  {
    name: 'notification-center.about',
    path: '/about',
    meta: {
      title: 'Notification centre | About',
      ninja: {
        name: 'notification-center',
      },
    },
  },
  {
    name: 'notification-center.requests-to-review',
    path: '/requests-to-review',
    meta: {
      title: 'Notification centre | Requests to review',
    },
    redirect: { name: 'notification-center.requests-to-review.new' },
    children: [
      {
        name: 'notification-center.requests-to-review.new',
        path: 'new',
        meta: {
          ninja: {
            name: 'requests_to_review_new',
          },
        },
      },
      {
        name: 'notification-center.requests-to-review.completed',
        path: 'completed',
        meta: {
          ninja: {
            name: 'requests_to_review_completed',
          },
        },
      },
    ],
  },
  {
    name: 'notification-center.track-my-requests',
    path: '/track-my-requests',
    redirect: { name: 'notification-center.track-my-requests.in-progress' },
    children: [
      {
        name: 'notification-center.track-my-requests.in-progress',
        path: 'in-progress',
        meta: {
          title: 'Notification centre | Track my requests',
          ninja: {
            name: 'track_my_requests_new',
          },
        },
      },
      {
        name: 'notification-center.track-my-requests.finished',
        path: 'finished',
        meta: {
          title: 'Notification centre | Track my requests',
          ninja: {
            name: 'track_my_requests_completed',
          },
        },
      },
    ],
  },
  {
    name: 'notification-center.request-details',
    path: '/request-details/:requestType/:requestId/:resourceType/:resource/:resourceId',
    props: parseProps(['requestId', 'resourceId']),
    meta: {
      title: 'Notification centre | Request details',
      ninja: {
        name: 'request_details',
      },
    },
  },
] as ConsoleRouteDefinition[];
