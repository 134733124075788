import { PropType } from 'vue';
import { ThemeName } from '@console/theme/types';

export { default as ConsoleApplication } from './ConsoleApplication.vue';

export const consoleApplicationProps = {
  theme: {
    type: String as PropType<ThemeName>,
    required: false,
    default: 'core',
  },
  productId: {
    type: String,
    required: false,
  },
};
