import { Order } from '@console/constants';
import * as globalPermissions from './globalPermissions';
import * as manageDataProjects from './manageDataProjects';
import * as persistedData from './persistedData';

// used in app startup
export async function loadCofferData() {
  const filters = { limit: 9999, order_by: Order.Asc, sort_by: 'name' };
  const [buResp, dpResp, cuResp, dpoResp] = await Promise.allSettled([
    persistedData.loadBusinessUnits(filters),
    persistedData.loadDataProjects(filters),
    persistedData.loadCustomerUnits(filters),
    persistedData.loadDPOs({
      ...filters,
      sort_by: 'identity',
    }),
  ]);

  if (buResp.status === 'fulfilled') {
    persistedData.businessUnits.value = buResp.value.data;
  }
  if (dpResp.status === 'fulfilled') {
    persistedData.dataProjects.value = dpResp.value.data;
  }
  if (cuResp.status === 'fulfilled') {
    persistedData.customerUnits.value = cuResp.value.data;
  }
  if (dpoResp.status === 'fulfilled') {
    persistedData.DPOs.value = dpoResp.value;
  }
}

export function useCoffer() {
  return {
    ...persistedData,
    ...globalPermissions,
    ...manageDataProjects,

    loadCofferData,
  };
}
