<template>
  <v-menu
    location="bottom right"
    attach="#console"
    :close-on-content-click="false"
    :content-class="`console-menu__menu ${removeBorderRightRadius ? 'console-menu__menu--ignore-right-radius' : ''}`"
    class="console-menu"
    data-testid="console-menu"
    scroll-strategy="block"
    v-model="menuLevel1"
  >
    <template #activator="{ props, isActive }">
      <v-btn
        data-testid="activator"
        icon
        class="console-menu__activator text-on-black"
        :class="{
          'v-btn--active': isActive,
        }"
        v-bind="props"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <div data-testid="content" class="console-menu__content bg-inverse-surface elevation-2 text-neutral">
      <div ref="menuLevel1El" class="content__wrapper layout">
        <v-list rounded class="menu--level1 v-list--inversed">
          <div class="menu__item-group">
            <v-list-item
              :key="item.text"
              :href="item.link"
              data-testid="menu-helper-item"
              @mouseenter="onHover(item)"
              v-for="item in availableHelperItems"
            >
              <v-list-item-title class="text-body-3">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </div>

          <div class="menu__item-group">
            <v-list-item prepend-icon="mdi-bell-outline" :href="requestsToReviewLink" data-testid="menu-item" @mouseenter="onHover()">
              <v-list-item-title class="text-body-2">Notification centre</v-list-item-title>

              <template #append v-if="notificationsCount">
                <div class="text-caption-1 mr-1">{{ notificationsCountText }}</div>
              </template>
            </v-list-item>
          </div>

          <div class="menu__item-group">
            <ConsoleMenuItem
              :key="item.text"
              :item="item"
              :active="selectedItem?.text === item.text"
              @mouseenter="onHover(item, $event)"
              v-for="item in availableProducts"
            />
          </div>

          <v-menu
            attach="#console"
            :content-class="`console-menu__menu ${removeBorderLeftRadius ? 'console-menu__menu--ignore-left-radius' : ''}`"
            class="console-menu console-menu__level2"
            data-testid="console-menu"
            :style="`top: ${offsetTop}px; left: ${offsetLeft}px`"
            persistent
            v-model="menuLevel2"
          >
            <div class="console-menu__content elevation-2 text-neutral bg-inverse-surface">
              <div ref="menuLevel2El" data-testid="content-level2" class="content__wrapper layout" v-if="menuLevel2">
                <v-list rounded class="menu--level2 v-list--inversed">
                  <ConsoleMenuItem :key="item.text" :item="item" v-for="item in selectedItem?.children" />
                </v-list>
              </div>
            </div>
          </v-menu>

          <div :key="i" class="menu__item-group" v-for="(group, i) in availableSecondaryItems">
            <ConsoleMenuItem :key="item.text" :item="item" @mouseenter="onHover(item, $event)" v-for="item in group" />
          </div>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useProducts } from '@console/composables/useProducts';
import { getAppRouteHref } from '@console/router';
import { CONSOLE_PRODUCTS, ConsoleProduct } from '@console/types';
import { useElementBounding, useElementVisibility, useMouseInElement } from '@vueuse/core';
import ConsoleMenuItem from './ConsoleMenuItem.vue';

/**
 * Create a polygon shape, which adds extra 5 pixels to the rectangle defined by `width` and `maxHeight` but this extra space
 * is skipped on the top left side up to `offset` pixels down
 */
export const getPolygonShape = (width: number, offset: number, maxHeight = 1000) => {
  const points = [
    '0px -5px',
    `${width + 5}px -5px`,
    `${width + 5}px ${maxHeight}px`,
    `-5px ${maxHeight}px`,
    `-5px ${offset}px`,
    `0px ${offset}px`,
  ];
  return `polygon(${points.join(', ')})`;
};

export default defineComponent({
  name: 'ConsoleMenu',
  components: {
    ConsoleMenuItem,
  },

  props: {
    notificationsCount: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { availableHelperItems, availableProducts, availableSecondaryItems } = useProducts();
    const selectedItem = ref<ConsoleProduct>(null);

    const menuLevel1 = ref(false);
    const menuLevel2 = ref(false);
    const menuLevel1El = ref<HTMLElement>();
    const menuLevel2El = ref<HTMLElement>();
    const menuLevel1ElBounding = useElementBounding(menuLevel1El);
    const isMenuLevel2Rendered = useElementVisibility(menuLevel2El);

    const offsetLeft = computed(() => {
      return menuLevel1ElBounding.right.value;
    });

    const removeBorderLeftRadius = ref(false);
    const removeBorderRightRadius = computed(() => {
      const isLastItem = selectedItem.value?.id === CONSOLE_PRODUCTS.DATA_CULTURE;
      return menuLevel2.value && (!removeBorderLeftRadius.value || isLastItem);
    });

    const offsetTop = ref(0);
    const menuLevel2Neighbourhood = ref('');
    const onHover = async (item?: ConsoleProduct, event?: Event) => {
      const children = item?.children;
      if (children) {
        if (event?.target) {
          const values = useElementBounding(event?.target as HTMLElement);
          const listItemHeight = values.height.value;

          const paddingTop = window.getComputedStyle(menuLevel1El.value.children[0]).getPropertyValue('padding-top');
          const paddingNumber = Number(paddingTop.replace('px', '')) || 8;
          offsetTop.value = values.top.value - paddingNumber;
          const bottomEdge = offsetTop.value + children.length * listItemHeight + 2 * paddingNumber;

          // if the bottom edge (plus 16 pixels to have some margin for better look) is below the visible window move it a bit higher
          if (bottomEdge + 16 > window.innerHeight) {
            const diff = bottomEdge + 16 - window.innerHeight;
            offsetTop.value -= Math.ceil(diff / listItemHeight) * listItemHeight;
          }

          // checks if the menu2 bottom edge is above bottom edge of menu1
          removeBorderLeftRadius.value = bottomEdge <= menuLevel1ElBounding.bottom.value;

          // we are defining the shape where we allow the box-shadow of menu2 to be displayed
          // `menuLevel1ElBounding.bottom.value - offsetTop.value` is the length of the common edge between menu1
          // and menu2 and this segment shouldn't have the elevation
          menuLevel2Neighbourhood.value = getPolygonShape(
            menuLevel1ElBounding.width.value,
            menuLevel1ElBounding.bottom.value - offsetTop.value - menuLevel1ElBounding.top.value
          );
        }
        selectedItem.value = item;
        menuLevel2.value = true;
      } else {
        menuLevel2.value = false;
        selectedItem.value = null;
      }
    };

    const onClose = () => {
      menuLevel1.value = false;
    };

    const notificationsCountText = computed(() => {
      return props.notificationsCount > 100 ? '100+' : props.notificationsCount;
    });

    const requestsToReviewLink = computed(() => {
      return getAppRouteHref('notification-center', {
        name: 'notification-center.requests-to-review',
      });
    });

    watch(menuLevel1, () => {
      selectedItem.value = null;
    });

    const { isOutside: isOutsideMenuLevel1 } = useMouseInElement(menuLevel1El);
    const { isOutside: isOutsideMenuLevel2 } = useMouseInElement(menuLevel2El);

    const isOutside = computed(() => {
      return isOutsideMenuLevel1.value && isOutsideMenuLevel2.value;
    });

    watch(isOutside, () => {
      setTimeout(() => {
        if (isOutside.value) {
          menuLevel2.value = false;
        }
      }, 100);
    });

    // There is default box-shadow of menu2 which is overlapping the menu1,
    // we are using the clipPath and the polygon shape where we "cut off" the shadow on the left edge where menu2 touches menu1
    watch(isMenuLevel2Rendered, value => {
      if (value) {
        // menuLevel2El.value.parentElement.parentElement refers the direct element created by `v-menu`, however we
        // cannot use `ref` on `v-menu`, since it's attaches to the root app element and ignores the `ref`
        menuLevel2El.value.parentElement.parentElement.style.clipPath = menuLevel2Neighbourhood.value;
      }
    });
    // TODO: after migration to vuetify3 this throws error, not sure if it is still needed ?
    // watch(menuLevel2Neighbourhood, () => {
    //   console.log(isMenuLevel2Rendered.value);
    //   if (isMenuLevel2Rendered.value) {
    //     menuLevel2El.value.parentElement.parentElement.style.clipPath = menuLevel2Neighbourhood.value;
    //   }
    // });

    return {
      menuLevel1,
      menuLevel2,
      menuLevel1El,
      menuLevel2El,
      selectedItem,
      availableHelperItems,
      availableProducts,
      availableSecondaryItems,
      notificationsCountText,
      requestsToReviewLink,
      onClose,
      offsetLeft,
      offsetTop,
      onHover,
      removeBorderLeftRadius,
      removeBorderRightRadius,
    };
  },
});
</script>

<style lang="scss">
@import '@console/theme/styles/variables';

#console .console-menu {
  $w: 280px;
  $p: 12px;

  &.console-menu__level2 {
    position: fixed !important;
  }

  &__content {
    overflow: hidden;
    width: $w;
    border-radius: $border-radius-large !important;
    background: var(--v-inverse-surface) !important;

    .content__wrapper {
      transform: translateX(0);
      transition: $basic-transition !important;

      .menu--level1,
      .menu--level2 {
        min-width: $w;
      }
    }
  }

  @at-root {
    & + .console-menu {
      .console-menu__content {
        border-top-left-radius: 0 !important;
        border-left: 1px solid var(--v-on-surface-variant);
      }
    }

    &__menu {
      border-radius: 8px !important;

      &--ignore-left-radius {
        .console-menu__content {
          border-bottom-left-radius: 0 !important;
        }
      }

      &--ignore-right-radius {
        .console-menu__content {
          border-bottom-right-radius: 0 !important;
        }
      }

      .v-list-item:not(:last-child):not(:only-child) {
        margin-bottom: 0 !important;
      }

      .v-list.v-list--inversed {
        .v-list-item.v-list-item--active {
          background: var(--v-surface2-opacity-012) !important;
          color: var(--v-on-inverse-surface) !important;
        }
      }

      .menu__item-group {
        position: relative;

        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            left: 16px;
            bottom: 0;
            width: calc(100% - 32px);
            height: 1px;
            border-bottom: 1px solid var(--v-outline);
          }
        }
      }
    }
  }
}
</style>
