<template>
  <v-text-field
    class="ml-12 mr-4 search-box"
    variant="outlined"
    single-line
    hide-details
    clearable
    color="white"
    placeholder="Search across Customer Units"
    density="comfortable"
    theme="dark"
    data-testid="search-box"
    @keyup.enter="onSearch"
    v-model="keyword"
  >
    <template #append-inner>
      <v-btn variant="text" icon="mdi-magnify" class="search-button" data-testid="search-button" @click="onSearch" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useGlobalSearch } from '@console/composables/useGlobalSearch';

export default defineComponent({
  name: 'GlobalSearchBox',
  setup() {
    const globalSearch = useGlobalSearch();

    const keyword = ref('');

    const onSearch = () => {
      if (!keyword.value) {
        return;
      }
      globalSearch.search(keyword.value);
    };

    watch(globalSearch.filters, () => {
      if (globalSearch.filters.value.search_query) {
        keyword.value = globalSearch.filters.value.search_query;
      }
    });

    return {
      globalSearch,
      onSearch,
      keyword,
    };
  },
});
</script>

<style lang="scss">
.console {
  .search-box {
    max-width: 400px;
  }

  .search-button {
    min-width: 32px !important;
    margin-right: -8px;
    margin-top: -4px;
  }
}
</style>
