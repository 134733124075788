import dayjs from 'dayjs';
import { computed } from 'vue';
import { UserCertifications, UserRole } from '@console/types';
import { uniqueUserRoles, userInfo } from './data';

export const BLUE_PRIMARY_TEXT = 'text-blue-primary';
export const GREEN_PRIMARY_TEXT = 'text-green-primary';
export const PINK_PRIMARY_TEXT = 'text-pink-primary';
export const CORE_PRIMARY_TEXT = 'text-core-primary';

export const ERROR_CHIP_CLASSES = 'bg-error-container text-on-error-container';
export const WARNING_CHIP_CLASSES = 'bg-warning-container text-on-warning-container';

export const getColorForRole = (role: UserRole) => {
  switch (role) {
    case UserRole.ResourceManager:
    case UserRole.ReservoirUser:
      return GREEN_PRIMARY_TEXT;
    case UserRole.Ambassador:
    case UserRole.MetricsOwner:
      return PINK_PRIMARY_TEXT;
    case UserRole.DPO:
    case UserRole.ProjectOwner:
    case UserRole.NewsEditor:
    default:
      return CORE_PRIMARY_TEXT;
  }
};

export const termsAcceptedAt = computed(() => {
  return userInfo.value.requirements?.terms_accepted_at;
});

export const privacyTrainingPassedAt = computed(() => {
  return userInfo.value.requirements?.privacy_training_passed_at;
});

export const userCertificates = computed(() => {
  if (!Array.isArray(userInfo.value.requirements?.certificates)) {
    return [];
  }

  const userCerts = userInfo.value.requirements.certificates.map(certification => {
    const certIsExpired = dayjs(certification.expires_at).isBefore(dayjs());
    const certExpiresSoon = !certIsExpired && dayjs(certification.expires_at).diff(dayjs(), 'days') < 30;

    if (certIsExpired) {
      return {
        ...certification,
        colorClass: ERROR_CHIP_CLASSES,
        tooltipText: `Your ${certification.key} Certification has expired`,
      };
    }
    if (certExpiresSoon) {
      return {
        ...certification,
        colorClass: WARNING_CHIP_CLASSES,
        tooltipText: 'Your certification is about to expire, renew it to maintain all your permissions',
      };
    }
    return {
      ...certification,
      colorClass: '',
      tooltipText: '',
    };
  });

  // Source owner certification is mandatory for Resource Manager role
  if (
    uniqueUserRoles.value.find(role => role.key === UserRole.ResourceManager) &&
    !userCerts.find(cert => cert.key === UserCertifications.SourceOwner)
  ) {
    userCerts.push({
      key: UserCertifications.SourceOwner,
      expires_at: '',
      colorClass: ERROR_CHIP_CLASSES,
      tooltipText: `You own the Data Owner role, but you are missing the ${UserCertifications.SourceOwner} Certification`,
    });
  }

  return userCerts;
});

export const hasPendingAction = computed(() => {
  return (
    userCertificates.value.some(cert => [ERROR_CHIP_CLASSES, WARNING_CHIP_CLASSES].includes(cert.colorClass)) ||
    !termsAcceptedAt.value ||
    !privacyTrainingPassedAt.value
  );
});
