import { CofferAPI } from '@console/core/http';
import { DataProject, DataProjectAssignments, DataProjectAssignmentsDefinition, UserRole } from '@console/types';
import { filterEmpty, handleAsync, pick } from '@console/utils';
import { useUser } from '../useUser';

function prepareProjectAssignmentsPayload(assignments?: DataProjectAssignmentsDefinition) {
  const store = useUser();
  const payload: Array<{
    policy_key: string;
    identity: string;
  }> = [];

  if (assignments) {
    Object.entries(assignments).forEach(([policyKey, emails]) => {
      emails.forEach(identity => {
        payload.push({ policy_key: policyKey, identity });
      });
    });
  }
  return payload.filter(item => item.policy_key !== UserRole.ProjectOwner || item.identity !== store.userInfo.value.email);
}

export async function createDataProjectAssignments(projectId: number, assignments: DataProjectAssignmentsDefinition) {
  const payload = prepareProjectAssignmentsPayload(assignments);
  return CofferAPI.POST(`/data_projects/${projectId}/assignments`, payload);
}

export async function deleteDataProjectAssignments(projectId: number, assignmentIds: number[]) {
  // no need to make a call if no assignments have to be deleted
  if (assignmentIds.length === 0) {
    return Promise.resolve();
  }

  return CofferAPI.DELETE(`/data_projects/${projectId}/assignments`, { assignment_ids: assignmentIds });
}

export async function loadDataProjectAssignments(id: number) {
  const [err, resp] = await handleAsync(CofferAPI.GET<DataProjectAssignments[]>(`/data_projects/${id}/assignments`));
  if (err) {
    return [];
  }

  return resp;
}

export async function loadDataProject(id: number) {
  return CofferAPI.GET<DataProject>(`/data_projects/${id}`);
}

export async function saveDataProject(dataProject: Partial<DataProject>) {
  const payload = pick(dataProject, 'description', 'name', 'customer_unit_id', 'opslevel_team_id');
  if (dataProject.id) {
    return CofferAPI.PUT<DataProject>(`/data_projects/${dataProject.id}`, payload);
  }
  return CofferAPI.POST<DataProject>('/data_projects', filterEmpty(payload));
}

export async function deleteDataProject(dataProjectId: number) {
  return CofferAPI.DELETE(`/data_projects/${dataProjectId}`);
}
