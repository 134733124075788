<template>
  <ConsoleApplication :left-nav-items="leftNavItems" :hide-region-selector="false" v-bind="$props">
    <template #header>
      <PrivacyTrainingAlert />
    </template>
  </ConsoleApplication>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useLDConfig } from '@console/composables';
import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import { useProducts } from '@console/composables/useProducts';
import { isBetaUser, isConsoleTeamMember } from '@console/composables/useUser/data';
import { getAppRoute } from '@console/router';
import { CONSOLE_PRODUCTS, type LeftNavigationItem } from '@console/types';
import { ConsoleApplication, consoleApplicationProps } from '@console/ui/ConsoleApplication';
import { PrivacyTrainingAlert } from '@console/ui/PrivacyTrainingAlert';
import { useTitle } from '@vueuse/core';

export default defineComponent({
  name: 'App',
  components: {
    PrivacyTrainingAlert,
    ConsoleApplication,
  },

  props: consoleApplicationProps,

  setup() {
    const title = useTitle();
    const { customerUnitId } = useGlobalFilters();
    const { getProduct } = useProducts();
    const isLiveviewEnabled = computed(() => useLDConfig<boolean>('sources-is-liveview-enabled') || isBetaUser() || isConsoleTeamMember());

    const leftNavItems = computed<Record<string, LeftNavigationItem[]>>(() => {
      const appName = getProduct(CONSOLE_PRODUCTS.DATA_SOURCES)?.text || '';

      return {
        [appName]: [
          {
            enabled: () => true,
            title: 'About',
            icon: 'mdi-file-document',
            to: getAppRoute('sources', {
              name: 'sources.about',
            }),
          },
          {
            enabled: () => true,
            title: 'All sources',
            icon: 'mdi-cube-outline',
            to: getAppRoute('sources', {
              name: 'sources.list',
              params: {
                cuid: customerUnitId.value.toString(),
              },
            }),
          },
          {
            enabled: () => true,
            title: 'User behaviour',
            icon: 'mdi-cursor-default-gesture-outline',
            to: getAppRoute('sources', {
              name: 'user-behaviour.matrices',
            }),
          },
          {
            enabled: () => isLiveviewEnabled.value,
            title: 'Ninja liveview',
            icon: 'mdi-matrix',
            to: getAppRoute('sources', {
              name: 'user-behaviour.liveview-list',
              params: {
                cuid: customerUnitId.value.toString(),
              },
            }),
          },
        ],
      };
    });
    title.value = 'Sources';

    return {
      leftNavItems,
    };
  },
});
</script>
