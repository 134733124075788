import { GeneralErrorResponse, ValidationErrorResponse, ViolationErrorResponse } from '@console/types';

/**
 * Let's make error handling with async/await great again!
 *
 * @template T
 * @param {Promise<T>} promise - The promise you want to handle
 * @param {Object} errorExt - Additional Information you can pass to the err object
 */
export async function handleAsync<E = GeneralErrorResponse | ValidationErrorResponse | ViolationErrorResponse, T = unknown>(
  promise: Promise<T>,
  errorExt?: Record<string, unknown>
): Promise<[E?, T?]> {
  try {
    const data: T = await promise;

    return [undefined, data];
  } catch (err: any) {
    if (errorExt) {
      Object.assign(err, errorExt);
    }
    return [err, undefined];
  }
}
