import { computed, ref } from 'vue';
import { useLDConfig } from '@console/composables/useConfig';
import { STORAGE_KEYS } from '@console/constants';
import { UserInfo, UserRole } from '@console/types';
import { decodeJWT } from '@console/utils';
import { useSettingsManager } from '../useSettingsManager';

export const userInfo = ref<UserInfo | null>(null);
export const uniqueUserRoles = ref<Array<{ key: UserRole; name: string; color: string }>>([]);

export const token = useSettingsManager<string>(STORAGE_KEYS.TOKEN, '');
export const impersonatedUser = useSettingsManager<string>(STORAGE_KEYS.IMPERSONATED_USER, '');
export const isLogged = ref(false);

export const isTokenValid = computed(() => {
  if (token.value) {
    const { exp } = decodeJWT(token.value);

    if (exp * 1000 > Date.now()) {
      return true;
    }
  }
  return false;
});

export const isConsoleTeamMember = () => {
  const users = useLDConfig<string>('console-team');
  const email = impersonatedUser.value || userInfo.value?.email;

  if (!users || !email) {
    return false;
  }

  return users.split(',').some(e => e.trim() === email.trim());
};

export const isBetaUser = () => {
  const users = useLDConfig<string>('console-beta-testers');
  const email = impersonatedUser.value || userInfo.value?.email;

  if (!users || !email) {
    return false;
  }

  return users.split(',').some(e => e.trim() === email.trim());
};

export const isExperimentationAmbassador = () => {
  const users = useLDConfig<string>('experimentation-ambassadors');
  const email = impersonatedUser.value || userInfo.value?.email;

  if (!users || !email) {
    return false;
  }

  return users.split(',').some(e => e.trim() === email.trim());
};
