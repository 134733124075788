<template>
  <Modal data-testid="confirmModal" :width="modalData.options ? modalData.options.width : 600">
    <template #header>
      <div data-testid="header">
        {{ modalData.title }}
      </div>
    </template>

    <template #body>
      <div data-testid="body">
        <component :is="modalData.component" v-bind="modalData.props || {}" v-if="modalData.component" />
        <div v-else v-html="modalData.message" />
      </div>
    </template>

    <template #footer="{ data: { options } }">
      <ModalActionSecondary data-testid="cancelBtn" @click="onReject" v-if="options.cancelText">
        {{ options.cancelText }}
      </ModalActionSecondary>
      <ModalActionPrimary data-testid="confirmBtn" @click="onResolve">{{ options.confirmText }}</ModalActionPrimary>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModal } from '@console/composables';
import { Modal, ModalActionPrimary, ModalActionSecondary } from '../Modal';

export default defineComponent({
  name: 'ConfirmModal',
  components: {
    Modal,
    ModalActionPrimary,
    ModalActionSecondary,
  },

  setup() {
    const { modalData, closeModal } = useModal();
    const onResolve = () => {
      modalData.value.resolve(true);
      closeModal();
    };
    const onReject = () => {
      modalData.value.reject(false);
      closeModal();
    };

    return {
      modalData,
      onResolve,
      onReject,
    };
  },
});
</script>
