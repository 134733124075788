<template>
  <!-- <div class="navigation navigation--right" :style="`width: ${active ? navWidth : 0}px`"> -->
  <!-- <div class="drag-handle" @mousedown="onMouseDown" @mouseup="onMouseUp" /> -->

  <v-navigation-drawer
    location="right"
    temporary
    disable-route-watcher
    :scrim="true"
    class="bg-surface3 elevation-3"
    :width="navWidth"
    :model-value="!!active"
    @update:model-value="onInput"
  >
    <!-- <slot v-if="$slots.default" /> -->
    <div data-testid="content" class="fill-height">
      <PortalTarget :name="active" class="fill-height" />
    </div>
  </v-navigation-drawer>
  <!-- </div> -->
</template>

<script lang="ts">
import { PortalTarget } from 'portal-vue';
import { defineComponent, watch } from 'vue';
import { useMenuResize, useRightPanel } from '@console/composables';

export default defineComponent({
  name: 'RightPanel',
  components: {
    PortalTarget,
  },

  emits: ['resize'],

  setup(props, { emit }) {
    const { navWidth, onMouseDown, onMouseUp } = useMenuResize({
      width: 350,
      cacheKey: 'console.rightPanel.navWidth',
      right: true,
    });
    const { active, closeRightPanel } = useRightPanel();

    const onInput = (value: boolean) => {
      if (!value) {
        closeRightPanel();
      }
    };

    watch(navWidth, value => emit('resize', value));

    return {
      active,
      navWidth,
      onMouseDown,
      onMouseUp,
      onInput,
    };
  },
});
</script>

<style lang="scss">
@import '@console/theme/styles/variables';

.console {
  .navigation.navigation--right {
    right: 0;
    top: 0;
    z-index: 1000;

    .v-navigation-drawer {
      padding-top: 64px;
    }
  }
}
</style>
