import type { ColorTones, ThemeName } from '../../types';
import { ThemeColorPalette, ThemeNeutralPalette } from '../../types';
import { mergePalettes } from '../mergePalettes';
import { shadesPalette } from '../shades';
import * as corePalette from './core';

export const name: ThemeName = 'purple';

export const primary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#F8EDFF',
  p90: '#EDDCFF',
  p80: '#D7BAFF',
  p70: '#C297FF',
  p60: '#AA77F1',
  p50: '#8F5DD5',
  p40: '#7542BA',
  p30: '#5C26A0',
  p20: '#440087',
  p10: '#290055',
  p5: '#1B003C',
  p25: '#501694',
  p35: '#6835AD',
  p98: '#FFF7FF',
};

export const secondary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#F8EDFF',
  p90: '#EBDDF7',
  p80: '#CEC2DA',
  p70: '#B3A7BE',
  p60: '#988CA3',
  p50: '#7D7389',
  p40: '#645A70',
  p30: '#4C4357',
  p20: '#352D40',
  p10: '#20182A',
  p5: '#150D1F',
  p25: '#40384B',
  p35: '#584F63',
  p98: '#FFF7FF',
};

export const tertiary: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#FFECF4',
  p90: '#FFD8EC',
  p80: '#FFAEDF',
  p70: '#FF7DD4',
  p60: '#E65CBD',
  p50: '#C741A1',
  p40: '#A82487',
  p30: '#87006C',
  p20: '#60004B',
  p10: '#3B002E',
  p5: '#28001E',
  p25: '#73005B',
  p35: '#98117A',
  p98: '#FFF8F9',
};

export const neutral: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#F5F5F5',
  p95: '#EFF1F1',
  p90: '#E1E3E3',
  p80: '#C4C7C7',
  p70: '#A9ACAC',
  p60: '#8E9192',
  p50: '#747878',
  p40: '#5C5F5F',
  p30: '#444748',
  p20: '#2E3132',
  p15: '#232626',
  p10: '#191C1D',
  p5: '#0E1212',
  p25: '#3D3A3E',
  p35: '#545155',
  p98: '#FEF7FC',
};

export const neutralVariant: ColorTones = {
  p0: shadesPalette.black,
  p100: shadesPalette.white,

  p99: '#FFFBFF',
  p95: '#F7EEF9',
  p90: '#E8E0EB',
  p80: '#CCC4CF',
  p70: '#B0A9B3',
  p60: '#958E99',
  p50: '#7B757F',
  p40: '#625C66',
  p30: '#4A454E',
  p20: '#332F37',
  p10: '#1E1A22',
  p5: '#130F17',
  p25: '#3E3A42',
  p35: '#56505A',
  p98: '#FFF7FF',
};

export const colorPalette: ThemeColorPalette = mergePalettes(corePalette.colorPalette, {
  primary,
  secondary,
  tertiary,
});

export const neutralPalette: ThemeNeutralPalette = mergePalettes(corePalette.neutralPalette, {
  neutral,
  neutralVariant,
});
