import Color from 'color';

/**
 * convert a Number to a two character hex string
 * must round, or we will end up with more digits than expected (2)
 * note: can also result in single digit, which will need to be padded with a 0 to the left
 */
export function int2hex(num) {
  let hex = Math.round(num).toString(16);
  if (hex.length === 1) {
    hex = `0${hex}`;
  }
  return hex;
}

export function blendColors(base: string, mask: string, amount: number) {
  const color1 = Color(base);
  const color2 = Color(mask);

  if (!color1 || !color2) {
    return base;
  }

  if (amount > 1 || amount < 0) {
    throw new Error('percentage must be between 0 and 1');
  }

  return color1.mix(color2, amount).hex();
}

export function setOpacity(color: string, opacity: number) {
  const c = Color(color);

  if (c) {
    return (c.alpha(opacity) as Color).hexa();
  }
  return color;
}

// wrap color so vuetify does not generate shades
export function wrapColor(color: string) {
  return Color(color)?.hex();
}
