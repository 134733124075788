<template>
  <Modal @show="onShow">
    <template #header>Submit feedback</template>

    <template #body>
      <v-form @submit.prevent v-model="isValid">
        <p>
          Report a bug, share a suggestion or anything else you want to let us know? Please submit it here and we will get back to you ASAP!
        </p>

        <v-select label="Category" :rules="[isRequired]" :items="FEEDBACK_CATEGORIES" v-model="feedback.reason" />
        <v-textarea auto-grow clearable rows="4" label="Message" :rules="[isRequired]" v-model="feedback.description" />
      </v-form>
    </template>

    <template #footer>
      <ModalActionSecondary @click="closeModal">Cancel</ModalActionSecondary>
      <ModalActionPrimary :disabled="!isValid" @click="modalData.onSubmit(feedback)">Submit</ModalActionPrimary>
    </template>
  </Modal>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useModal } from '@console/composables';
import { isRequired } from '@console/utils';
import { Modal, ModalActionPrimary, ModalActionSecondary } from '../Modal';

const FEEDBACK_CATEGORIES = ['Suggestion', 'Bug', 'Other'];

export default {
  name: 'FeedbackModal',

  components: {
    Modal,
    ModalActionPrimary,
    ModalActionSecondary,
  },

  setup() {
    const { closeModal, modalData } = useModal();
    const isValid = ref(false);

    const feedback = ref({ description: undefined, reason: undefined });

    const onShow = () => {
      feedback.value = { description: undefined, reason: undefined };
    };

    return {
      closeModal,
      FEEDBACK_CATEGORIES,
      feedback,
      isRequired,
      isValid,
      modalData,
      onShow,
    };
  },
};
</script>
