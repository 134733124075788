<template>
  <div class="login-wrapper text-on-surface">
    <ToggleTheme style="position: absolute; top: 8px; right: 8px; z-index: 999" />
    <div class="login-left">
      <h1>Access to all your projects in one place</h1>
      <LoginImg class="mt-5" />
    </div>
    <div class="login-right">
      <svg
        class="login-right--image"
        viewBox="0 0 500 500"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="100%"
      >
        <path
          id="blob"
          d="M438,324Q398,398,324,444.5Q250,491,187,433.5Q124,376,66,313Q8,250,72,193Q136,136,
          193,88.5Q250,41,334.5,61Q419,81,448.5,165.5Q478,250,438,324Z"
        ></path>
      </svg>
      <h2 class="login-right__header mb-2">Welcome to Console</h2>

      <div class="login-right-actions">
        <v-btn block size="large" class="bg-primary text-on-primary login-right-actions__login" :href="loginURL" @click="onLoginClicked()">
          Login with Okta
        </v-btn>
        <span class="login-right-actions__divider text-center">OR</span>
        <v-btn
          variant="outlined"
          block
          size="large"
          class="text-primary login-right-actions__contact mb-8"
          href="https://app.slack.com/client/T08AELKL7/CAT6DD59D"
          target="_blank"
          rel="noreferrer noopener"
        >
          <v-icon start>mdi-slack</v-icon>
          Contact us on Slack
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { STORAGE_KEYS } from '@console/constants';
import { LOGIN_URL, PUBLIC_PATH } from '@console/constants/config';
import { ToggleTheme } from '@console/ui/ToggleTheme';
import { getStorageKey } from '@console/utils';
import LoginImg from './LoginImg.vue';

export default defineComponent({
  name: 'LoginPage',
  components: {
    ToggleTheme,
    LoginImg,
  },

  setup() {
    const route = useRoute();
    const loginURL = computed(() => {
      const redirectURI = encodeURIComponent(`${window.location.origin}/sso`);
      return `${LOGIN_URL}&redirect_uri=${redirectURI}`;
    });

    const onLoginClicked = () => {
      const key = getStorageKey(STORAGE_KEYS.LOGIN_RETURN_TO);

      if (route.query.returnTo) {
        localStorage.setItem(key, route.query.returnTo as string);
      } else {
        localStorage.setItem(key, PUBLIC_PATH);
      }
    };

    return {
      loginURL,
      onLoginClicked,
    };
  },
});
</script>

<style lang="scss">
.login {
  &-wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: var(--v-background);
  }
  &-left {
    padding-left: 100px;
    width: 30%;
    display: flex;
    align-content: center;
    background: var(--v-surface4);
    color: var(--v-on-surface);
    height: 100%;
    position: relative;

    h1 {
      position: absolute;
      top: 20px;
      left: 40px;
      text-align: left;
      font-size: 40px;
      font-weight: 900;
      width: 80%;
    }
    img {
      width: 100%;
    }
  }
  &-right {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: var(--v-on-surface);

    &__header {
      position: relative;
      z-index: 2;
    }
    &-actions {
      display: flex;
      flex-direction: column;
      width: 300px;
      position: relative;
      z-index: 2;
      &__login,
      &__contact {
        height: 52px !important;
      }

      &__divider {
        width: 100%;
        margin: 20px auto;
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 40%;
          height: 1px;
          background: var(--v-neutralVariant);
          top: 10px;
        }
        &:after {
          right: 0;
        }
        &:before {
          left: 0;
        }
      }
    }
  }
}
.login-right--image {
  position: absolute;
  width: 50%;
  z-index: 1;

  path {
    fill: var(--v-surface4);
  }
}
</style>
