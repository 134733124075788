import { computed, ref } from 'vue';
import { CofferAPI } from '@console/core/http';
import { BusinessProduct, BusinessUnit, CustomerUnit, DataProject, GlobalPermissionAssignmentItem } from '@console/types';
import { DataTableFilters, DataTableResponse } from '@console/ui/DataTable';
import { queryStringFromObject } from '@console/utils';

export const businessUnits = ref<BusinessUnit[]>([]);
export const customerUnits = ref<CustomerUnit[]>([]);
export const dataProjects = ref<DataProject[]>([]);
export const DPOs = ref<GlobalPermissionAssignmentItem[]>([]);

export const businessUnitsMap = computed(() => {
  return new Map<string, BusinessUnit>(businessUnits.value.map(e => [e.key, e]));
});
export const customerUnitsMap = computed(() => {
  return new Map<number, CustomerUnit>(customerUnits.value.map(e => [e.id, e]));
});
export const businessProductsMap = computed(() => {
  return new Map<number, BusinessProduct>(customerUnits.value.flatMap(e => e.products).map(e => [e.id, e]));
});
export const dataProjectsMap = computed(() => {
  return new Map<number, DataProject>(dataProjects.value.map(e => [e.id, e]));
});

export const products = computed<BusinessProduct[]>(() => {
  return customerUnits.value.flatMap(e => e.products);
});
export const productsMap = computed(() => {
  return new Map<number, BusinessProduct>(products.value.map(e => [e.id, e]));
});

export function loadBusinessUnits(filters: DataTableFilters = { limit: 9999 }) {
  const query = queryStringFromObject(filters);
  return CofferAPI.GET<DataTableResponse<BusinessUnit>>(`/business_units?${query}`);
}

export function loadDataProjects(filters: DataTableFilters = { limit: 9999 }) {
  const query = queryStringFromObject(filters);
  return CofferAPI.GET<DataTableResponse<DataProject>>(`/data_projects?${query}`);
}

export function loadCustomerUnits(filters: DataTableFilters = { limit: 9999 }) {
  const query = queryStringFromObject(filters);
  return CofferAPI.GET<DataTableResponse<CustomerUnit>>(`/customer_units?${query}`);
}

export function loadDPOs(filters: DataTableFilters = { limit: 9999 }) {
  const query = queryStringFromObject({ ...filters, policy: 'dpo' });
  return CofferAPI.GET<GlobalPermissionAssignmentItem[]>(`/global_roles/assignments?${query}`);
}
