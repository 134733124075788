<template>
  <Modal ref="impersonateModal">
    <template #header>Impersonate a user</template>
    <template #body>
      <UsersAutocomplete label="Email of the user" :max-selected="1" v-model:model-value="modalData.email" />
      <!-- <v-text-field
        variant="outlined"
        label="Email of the user"
        @keydown.enter="modalData.onSave(modalData.email)"
        v-model="modalData.email"
      /> -->
    </template>
    <template #footer>
      <ModalActionPrimary :disabled="!modalData.email" @click="modalData.onSave(modalData.email)"> Impersonate </ModalActionPrimary>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useModal } from '@console/composables';
import { UsersAutocomplete } from '@console/ui/UsersAutocomplete';
import { Modal, ModalActionPrimary } from '../Modal';

export default defineComponent({
  name: 'ImpersonateModal',

  components: {
    Modal,
    ModalActionPrimary,
    UsersAutocomplete,
  },

  setup() {
    const { modalData } = useModal();
    const impersonateModal = ref(null);

    return {
      modalData,
      impersonateModal,
    };
  },
});
</script>
