import { NavigationGuard } from 'vue-router';
import { useUser } from '@console/composables/useUser';
import { token } from '@console/composables/useUser/data';

const isUserAuthenticatedGuard: NavigationGuard = async (to, from, next) => {
  if (to.matched.some(record => record.meta.anonymous)) {
    next(); // route is anonymous - no token and no userData required
  } else {
    const { logout, checkLogin } = useUser();
    const go2login = () => {
      logout();

      next({
        name: 'login',
        query: to.name
          ? {
              returnTo: `${window.location.pathname}${window.location.search}`,
            }
          : {},
      });
    };
    if (token.value) {
      if (await checkLogin()) {
        next();
      } else {
        go2login();
      }
    } else {
      go2login();
    }
  }
};

export default isUserAuthenticatedGuard;
