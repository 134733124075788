<template>
  <v-alert type="warning" class="my-3" v-if="isLogged && !userRequirements.isPrivacyTrained">
    You are missing the <span class="font-weight-bold">Mandatory Security Awareness & Privacy Training</span>. Complete the training to be
    able to access data in reservoirs. <a :href="LITMOS_URL" target="_blank">Go to training </a>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUser } from '@console/composables/useUser';
import { LITMOS_URL } from '@console/constants';

export default defineComponent({
  name: 'PrivacyTrainingAlert',

  setup() {
    const { userRequirements, isLogged } = useUser();

    return {
      LITMOS_URL,
      userRequirements,
      isLogged,
    };
  },
});
</script>
