// import { ThemeColorPalette } from '../types';
import { copyObject } from '@console/utils';

/**
 * Merges N partial palettes with a base one.
 */
export function mergePalettes<T extends Record<string, any>>(base: T, ...rest: Array<T | Partial<T>>) {
  return rest.reduce((acc, p) => {
    Object.entries(p).forEach(([color, values]) => {
      Object.entries(values).forEach(([percent, value]) => {
        acc[color][percent] = value;
      });
    });

    return acc;
  }, copyObject(base)) as T;
}
