import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'playground.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'playground.hasVisitedRoot',
        { name: 'playground.about' },
        { name: 'playground.config', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'playground.about',
    path: '/about',
    meta: {
      ninja: {
        name: 'home_playground',
      },
    },
  },
  {
    name: 'playground.config',
    path: '/config/:cuid',
    redirect: { name: 'playground.config.experiments' },
    props: parseProps(['cuid']),
    meta: {
      ninja: {
        name: 'search_playground',
      },
    },

    children: [
      {
        name: 'playground.config.experiments',
        path: 'experiments',
      },
      {
        name: 'playground.config.flags',
        path: 'flags',
      },
      {
        name: 'playground.config.surveys',
        path: 'surveys',
      },
    ],
  },
] as ConsoleRouteDefinition[];
