// to avoid circular deps
export const SLACK_CHANNEL_URL = 'https://naspersclassifieds.slack.com/messages/CEBKLHAMA/';

export const JIRA_REGEX = /^[A-Z]{1}[A-Z\d]{0,9}-\d+$/;

export const STAR = '*';

export const EMPTY_PH = '—';

export const REALTIME_POOLING_INTERVAL = 10000;

export const DATE_FORMAT = 'DD MMM YYYY';

export const DATE_FORMAT_SHORT = 'DD MMM';

export const DATE_FORMAT_MINIMAL = 'D MMM';

export const DATE_FORMAT_ISO = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm';

export const TIME_FORMAT_LONG = 'HH:mm:ss';

export const TIME_FORMAT_EXTENDED = 'HH:mm:ss.SSS';

export const DATETIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

export const DATETIME_FORMAT_SHORT = `${DATE_FORMAT_SHORT}, ${TIME_FORMAT}`;

export const DATETIME_FORMAT_LONG = `${DATE_FORMAT}, ${TIME_FORMAT_LONG}`;

export const DATETIME_FORMAT_EXTENDED = `${DATE_FORMAT}, ${TIME_FORMAT_EXTENDED}`;

export const STORAGE_KEYS = {
  // used directly by `useSettingsManager`
  TOKEN: 'id_token',
  IMPERSONATED_USER: 'impersonatedUser',
  SELECTED_BU: 'laquesis.store.selectedBU',
  LOGIN_RETURN_TO: 'login_return_to',
  METRICS_REPORT_STORE: 'laquesis.MetricsReportStore',
  EXPERIMENT_QUALITY_SIDEBAR_WIDTH: 'experiments.ExperimentQualitySidebarWidth',
};

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}

export const DATA_TABLE_LIMITS: Array<{ value: number; text: string; default?: boolean }> = [
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
  { value: 500, text: '500' },
];

export const CONFLUENCE_URL = 'https://naspersclassifieds.atlassian.net/wiki/spaces/DATA';

export const CERTIFICATION_URL = 'https://docs.data.olx.org/certifications/';

export const CERTIFICATION_FORM_URL = 'https://forms.gle/LBDitsojnb4BTC1V7';

export const DEBOUNCE_TIMEOUT = 350;

export const SERVICEDESK_JIRA_URL = 'https://naspersclassifieds.atlassian.net/servicedesk/customer/portal/59';

export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export const LITMOS_URL = 'https://olx.litmoseu.com/home/dashboard';

export const CUSTOMER_UNIT_IDS = {
  'Core Data': 2000000,
  OLX: 2000001,
  Motors: 2000002,
  RE: 2000003,
  Panamera: 2000009,
  Roadster: 2000010,
  'OLX Autos': 2000011,
  BIT: 2000012,
  'Data Science': 2000013,
  Finance: 2000014,
  HR: 2000015,
  'Core Tech': 2000016,
  Data: 2000017,
  Ecosystem: 2000018,
  Security: 2000019,
  TPM: 2000020,
  'EU Unclaimed': 2000021,
  'Core Data Unclaimed': 2000022,
};
